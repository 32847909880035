import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {BusinessesResponse, FeedsResponse} from '../../_models/datatable-response';
import {environment} from '../../../environments/environment';
import {catchError, tap} from 'rxjs/operators';
import {Errors} from '../../_helpers/errors';

@Injectable({
  providedIn: 'root'
})
export class FeedService {
  private feedsUrl = 'dashboard/feeds';
  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };
  constructor(private http: HttpClient) { }

  getFeeds(max: number = 20, offset: number = 0, searchVal = ''): Observable<FeedsResponse> {
    return this.http.get<FeedsResponse>(
      `${environment.apiUrl}` + this.feedsUrl
    ).pipe(
      tap(_ => console.log('fetched Feeds')),
      catchError(Errors.handleError<FeedsResponse>('getFeeds'))
    );
  }
}
