import {Component, OnInit, Renderer2, ViewEncapsulation} from '@angular/core';
import {Role, User} from './_models';
import {Router} from '@angular/router';
import {AuthenticationService} from './_services';
import { DynamicScriptLoaderService } from './_services/dynamic-script-loader.service';
import * as moment from 'moment/moment';
declare var $: any;
declare var jQuery: any;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppComponent implements OnInit {
  title = 'LocalSeo';
  currentUser: User;
  showLogin = true;
  currentRole: Role;
  openendMenu = 'closed';
  openendFeed = 'closed';

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private dynamicScriptLoader: DynamicScriptLoaderService,
    private renderer: Renderer2
  ) {
    this.authenticationService.currentUser.subscribe(x => {
      this.currentUser = x;
      if (x !== null) {
        this.currentRole = x.role;
      }
    });
    if (this.authenticationService.currentUserValue) {
      this.showLogin = false;
    } else {
      this.showLogin = true;
    }
  }

  ngOnInit() {
    this.loadScripts();
  }

  get year() {
    return moment().format('YYYY');
  }

  openCloseMenu() {
    if (this.openendMenu === 'opened') {
      this.openendMenu = 'closed';
      this.renderer.addClass(document.body, 'mini-sidebar');
    } else {
      this.openendMenu = 'opened';
      this.renderer.removeClass(document.body, 'mini-sidebar');
    }
  }

  openCloseFeed() {
    if (this.openendFeed === 'opened') {
      this.openendFeed = 'closed';
      this.renderer.removeClass(document.body.getElementsByClassName('right-sidebar')[0], 'mini-feed');
    } else {
      this.openendFeed = 'opened';
      this.renderer.addClass(document.body.getElementsByClassName('right-sidebar')[0], 'mini-feed');
    }
  }

  private loadScripts() {
    // You can load multiple scripts by just providing the key as argument into load method of the service
    this.dynamicScriptLoader.load('sidebarmenu').then(data => {
      // Script Loaded Successfully
    }).catch(error => console.log(error));
  }

  logout() {
    this.authenticationService.logout();
    this.showLogin = true;
    this.router.navigate(['/login']);
  }
}
