import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthenticationService} from '../_services';
import {AlertService} from '../_services/alert.service';
import * as $ from 'jquery';
import {first} from 'rxjs/operators';

@Component({
  selector: 'app-login',
  templateUrl: './password.component.html',
  styleUrls: ['./password.component.css']
})

export class PasswordComponent implements OnInit {

  resetForm: FormGroup;
  loading =  false;
  submitted =  false;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private alertService: AlertService,
  ) { }

  ngOnInit() {
    this.resetForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]]
    });
  }

  get f() { return this.resetForm.controls; }

  onSubmit() {
    this.submitted = true;
    if (this.resetForm.invalid) {
      return;
    }
    console.log('login comp in');
    this.loading = true;
    this.authenticationService.resetPassword(this.f.email.value)
      .pipe(first())
      .subscribe(
        data => {
          console.log(data);
          this.alertService.alert('success', data.response);
          this.loading = false;
        },
        error => {
          console.log('login comp data out');
          this.alertService.error(error);
          this.loading = false;
        }
      );
  }
}
