import {Component, ComponentFactoryResolver, OnInit, ViewChild, ViewContainerRef} from '@angular/core';
import {Role, User} from '../_models';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {WhitelabelService} from '../_services/whitelabel-service/whitelabel.service';
import {BusinessService} from '../_services/business/business.service';
import {Select2OptionData} from 'ng-select2';
import {Observable} from 'rxjs';
import {ManageAccountComponent} from '../manage-account/manage-account.component';
import {ManageAccountDirective} from '../_directives/manage-account.directive';
import {AuthenticationService, UserService} from '../_services';
import {ActivatedRoute} from '@angular/router';
import {BreadcrumbsService} from 'ng6-breadcrumbs';
import {AlertService} from '../_services/alert.service';

@Component({
  selector: 'app-create-user',
  templateUrl: './create-user.component.html',
  styleUrls: ['./create-user.component.scss']
})
export class CreateUserComponent implements OnInit {
  roles: Role[] = [];
  newUserForm: FormGroup;
  featuresCheck: any = {};
  featuresDetailedCheck: any = {};
  whiteLabels: string[];
  businesses: Observable<Array<Select2OptionData>>;
  public options: any;
  public accountsValue: string[];
  @ViewChild(ManageAccountDirective, {static: true}) accMan: ManageAccountDirective;
  existingAccounts: any;
  featuresSelected: string[];
  featuresDetailedSelected: string[];
  loading = false;
  user: User;
  createOrEdit = 'Create';
  businessesTexts: Observable<Array<string>>;
  expandedLocationProf = 'false';
  expandedAdsRead = 'false';
  ngLoading = false;
  hideEditFeatures = false;
  selectedRole = 'ADMIN';

  constructor(
    private whitelabelService: WhitelabelService,
    private authService: AuthenticationService,
    private businessService: BusinessService,
    private componentFactoryResolver: ComponentFactoryResolver,
    private fb: FormBuilder,
    private userService: UserService,
    private route: ActivatedRoute,
    private breadcrumbsService: BreadcrumbsService,
    private alertService: AlertService
  ) {}

  ngOnInit() {
    if (this.authService.currentUserValue.role === 'ADMIN') {
      this.roles.push(Role.Admin, Role.AccountManager, Role.BusinessManager, Role.LocationManager);
    } else if (this.authService.currentUserValue.role === 'ACCOUNT_MANAGER') {
      this.roles.push(Role.AccountManager, Role.BusinessManager, Role.LocationManager);
    } else if (this.authService.currentUserValue.role === 'BUSINESS_MANAGER') {
      this.roles.push(Role.LocationManager);
    }
    this.featuresCheck.adsReadSwitch = {value: false, text: 'ADS_READ'};
    this.featuresCheck.adsWriteSwitch = {value: false, text: 'ADS_WRITE'};
    this.featuresCheck.locProfDirSwitch = {value: false, text: 'LOCATION_WRITE'};
    this.featuresCheck.editSync = {value: true, text: 'LOCATION_WRITE'};
    this.featuresCheck.insights = {value: false, text: 'TRACKING'};
    this.featuresCheck.upgradeDowngradeProdPlan = {value: false, text: 'UPGRADE'};
    this.featuresCheck.editUser = {value: false, text: 'USER_SELF_EDIT'};
    this.featuresCheck.locDuplicateDel = {value: true, text: 'SUPPRESSION'};
    this.featuresCheck.locStatusChange = {value: true, text: 'LOCATION_STATUS_CHANGE'};
    this.setFeaturesDetailedDefaults();

    this.newUserForm = this.fb.group({
      salutation: [''],
      status: [''],
      firstname: ['', Validators.required],
      lastname: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      phone: [''],
      password: [''],
      confirmPassword: [''],
      whitelabelInformationIdentifier: ['', Validators.required],
      role: ['', Validators.required],
      managedBusinesses: null,
      managedLocations: null,
      features: [''],
      featuresDetailed: [''],
      realLocations: null
    });
    this.route.paramMap.subscribe(params => {
      if (params.get('id') !== null) {
        this.ngLoading = true;
        this.userService.getById(params.get('id')).subscribe(response => {
          this.user = response.response.user;
          if (typeof this.user !== 'undefined' && typeof this.user.id !== 'undefined') {
            this.createOrEdit = 'Edit';
            if (this.authService.currentUserValue.identifier === this.user.identifier) {
              this.hideEditFeatures = true;
            }
            this.formControls.salutation.setValue(this.user.salutation);
            this.formControls.firstname.setValue(this.user.firstname);
            this.formControls.lastname.setValue(this.user.lastname);
            this.formControls.email.setValue(this.user.email);
            this.formControls.phone.setValue(this.user.phone);
            const whitelabelInformation = JSON.parse(this.user.whitelabelInformation);
            this.formControls.whitelabelInformationIdentifier.setValue(whitelabelInformation.identifier);
            this.formControls.role.setValue(this.user.role);
            if (this.user.role !== 'ADMIN') {
              this.selectedRole = this.user.role;
              this.newUserForm.controls.managedBusinesses.clearValidators()
              this.newUserForm.controls.managedBusinesses.setValidators([Validators.required]);
              this.newUserForm.controls.managedBusinesses.updateValueAndValidity();
            }

            // @ts-ignore
            if (this.user.features.indexOf('ADS_READ') !== -1) {
              this.featuresCheck.adsReadSwitch = {value: true, text: 'ADS_READ'};
              this.expandedAdsRead = 'true';
            } else {
              this.featuresCheck.adsReadSwitch = {value: false, text: 'ADS_READ'};
            }
            // @ts-ignore
            if (this.user.features.indexOf('ADS_WRITE') !== -1) {
              this.featuresCheck.adsWriteSwitch = {value: true, text: 'ADS_WRITE'};
            } else {
              this.featuresCheck.adsWriteSwitch = {value: false, text: 'ADS_WRITE'};
            }
            // @ts-ignore
            if (this.user.features.indexOf('LOCATION_WRITE') !== -1) {
              this.expandedLocationProf = 'true';
              this.featuresCheck.locProfDirSwitch = {value: true, text: 'LOCATION_WRITE'};
            } else {
              this.featuresCheck.locProfDirSwitch = {value: false, text: 'LOCATION_WRITE'};
            }
            // @ts-ignore
            if (this.user.features.indexOf('LOCATION_WRITE') !== -1) {
              this.featuresCheck.editSync = {value: true, text: 'LOCATION_WRITE'};
            } else {
              this.featuresCheck.editSync = {value: false, text: 'LOCATION_WRITE'};
            }
            // @ts-ignore
            if (this.user.features.indexOf('TRACKING') !== -1) {
              this.featuresCheck.insights = {value: true, text: 'TRACKING'};
            } else {
              this.featuresCheck.insights = {value: false, text: 'TRACKING'};
            }
            // @ts-ignore
            if (this.user.features.indexOf('UPGRADE') !== -1) {
              this.featuresCheck.upgradeDowngradeProdPlan = {value: true, text: 'UPGRADE'};
            } else {
              this.featuresCheck.upgradeDowngradeProdPlan = {value: false, text: 'UPGRADE'};
            }
            // @ts-ignore
            if (this.user.features.indexOf('USER_SELF_EDIT') !== -1) {
              this.featuresCheck.editUser = {value: true, text: 'USER_SELF_EDIT'};
            } else {
              this.featuresCheck.editUser = {value: false, text: 'USER_SELF_EDIT'};
            }
            // @ts-ignore
            if (this.user.features.indexOf('SUPPRESSION') !== -1) {
              this.featuresCheck.locDuplicateDel = {value: true, text: 'SUPPRESSION'};
            } else {
              this.featuresCheck.locDuplicateDel = {value: false, text: 'SUPPRESSION'};
            }
            // @ts-ignore
            if (this.user.features.indexOf('LOCATION_STATUS_CHANGE') !== -1) {
              this.featuresCheck.locStatusChange = {value: true, text: 'LOCATION_STATUS_CHANGE'};
            } else {
              this.featuresCheck.locStatusChange = {value: false, text: 'LOCATION_STATUS_CHANGE'};
            }
            if (!this.hideEditFeatures) {
              this.onFeaturesValueChange();
            }

            // @ts-ignore
            if (typeof this.user.featuresDetailed.LOCATION_WRITE !== 'undefined') {
              // @ts-ignore
              const featuresDetailed = this.user.featuresDetailed.LOCATION_WRITE;
              if (featuresDetailed.indexOf('NAME') !== -1) {
                this.featuresDetailedCheck.locName = {value: true, text: 'name'};
              } else {
                this.featuresDetailedCheck.locName = {value: false, text: 'NAME'};
              }
              if (featuresDetailed.indexOf('identifier') !== -1) {
                this.featuresDetailedCheck.locId = {value: true, text: 'IDENTIFIER'};
              }
              if (featuresDetailed.indexOf('addressDisplay') !== -1) {
                this.featuresDetailedCheck.locAdd1 = {value: true, text: 'ADDRESS_DISPLAY'};
              }
              if (featuresDetailed.indexOf('addressExtra') !== -1) {
                this.featuresDetailedCheck.locAdd2 = {value: true, text: 'ADDRESS_EXTRA'};
              }
              if (featuresDetailed.indexOf('zip') !== -1) {
                this.featuresDetailedCheck.locZip = {value: true, text: 'ZIP'};
              }
              if (featuresDetailed.indexOf('city') !== -1) {
                this.featuresDetailedCheck.locCity = {value: true, text: 'CITY'};
              }
              if (featuresDetailed.indexOf('categories') !== -1) {
                this.featuresDetailedCheck.locCateg = {value: true, text: 'CATEGORIES'};
              }
              if (featuresDetailed.indexOf('phone') !== -1) {
                this.featuresDetailedCheck.locPhone = {value: true, text: 'PHONE'};
              }
              if (featuresDetailed.indexOf('cellphone') !== -1) {
                this.featuresDetailedCheck.locMobile = {value: true, text: 'CELLPHONE'};
              }
              if (featuresDetailed.indexOf('fax') !== -1) {
                this.featuresDetailedCheck.locFax = {value: true, text: 'FAX'};
              }
              if (featuresDetailed.indexOf('website') !== -1) {
                this.featuresDetailedCheck.locWebsite = {value: true, text: 'WEBSITE'};
              }
              if (featuresDetailed.indexOf('email') !== -1) {
                this.featuresDetailedCheck.locEmail = {value: true, text: 'EMAIL'};
              }
              if (featuresDetailed.indexOf('openingHours') !== -1) {
                this.featuresDetailedCheck.locOpenHours = {value: true, text: 'OPENINGHOURS'};
              }
              if (featuresDetailed.indexOf('openingHoursNotes') !== -1) {
                this.featuresDetailedCheck.locOpenHoursNotes = {value: true, text: 'OPENINGHOURS_NOTES'};
              }
              if (featuresDetailed.indexOf('specialOpeningHours') !== -1) {
                this.featuresDetailedCheck.locSpecOpenHours = {value: true, text: 'SPECIAL_OPENINGHOURS'};
              }
              if (featuresDetailed.indexOf('keywords') !== -1) {
                this.featuresDetailedCheck.locKeywords = {value: true, text: 'KEYWORDS'};
              }
              if (featuresDetailed.indexOf('descriptionShort') !== -1) {
                this.featuresDetailedCheck.locShortDesc = {value: true, text: 'DESCRIPTION_SHORT'};
              }
              if (featuresDetailed.indexOf('descriptionLong') !== -1) {
                this.featuresDetailedCheck.locLongDesc = {value: true, text: 'DESCRIPTION_LONG'};
              }
              if (featuresDetailed.indexOf('imprint') !== -1) {
                this.featuresDetailedCheck.locImprint = {value: true, text: 'IMPRINT'};
              }
              if (featuresDetailed.indexOf('socialProfiles') !== -1) {
                this.featuresDetailedCheck.locSocialProf = {value: true, text: 'SOCIAL_PROFILES'};
              }
              if (featuresDetailed.indexOf('attributes') !== -1) {
                this.featuresDetailedCheck.locAttributes = {value: true, text: 'ATTRIBUTES'};
              }
              if (featuresDetailed.indexOf('paymentOptions') !== -1) {
                this.featuresDetailedCheck.locPayOpt = {value: true, text: 'PAYMENT_OPTIONS'};
              }
              if (featuresDetailed.indexOf('brands') !== -1) {
                this.featuresDetailedCheck.locBrands = {value: true, text: 'BRANDS'};
              }
              if (featuresDetailed.indexOf('languages') !== -1) {
                this.featuresDetailedCheck.locLanguages = {value: true, text: 'LANGUAGES'};
              }
              if (featuresDetailed.indexOf('services') !== -1) {
                this.featuresDetailedCheck.locServices = {value: true, text: 'SERVICES'};
              }
              if (featuresDetailed.indexOf('contentLists') !== -1) {
                this.featuresDetailedCheck.locContentList = {value: true, text: 'CONTENT_LISTS'};
              }
              if (featuresDetailed.indexOf('photos') !== -1) {
                this.featuresDetailedCheck.locPhotos = {value: true, text: 'PHOTOS'};
              }
              if (featuresDetailed.indexOf('videos') !== -1) {
                this.featuresDetailedCheck.locVideos = {value: true, text: 'VIDEOS'};
              }
              if (!this.hideEditFeatures) {
                this.onFeaturesDetailedValueChange();
              }
            } else {
              this.setFeaturesDetailedDefaults();
            }

            // tslint:disable-next-line:forin
            this.getBusinesses(this.user.businesses);
          } else {
              this.getBusinesses();
          }
          this.ngLoading = false;
        });
        this.breadcrumbsService.store([
          {label: 'Dashboard' , url: '/dashboard', params: []},
          {label: 'Users' , url: '/users', params: []},
          {label: 'Edit User' , url: '/edit-user/:id', params: []}
        ]);
      } else {
        this.formControls.password.setValidators([Validators.required]);
        this.formControls.confirmPassword.setValidators([Validators.required]);
        this.getBusinesses();
        this.breadcrumbsService.store([
          {label: 'Dashboard' , url: '/dashboard', params: []},
          {label: 'Users' , url: '/users', params: []},
          {label: 'Create User' , url: '/create-user', params: []}
        ]);
      }
    });
    this.existingAccounts = {};
    this.getWhiteLabels();
    this.options = {
      multiple: true,
      closeOnSelect: false,
      width: '100%',
    };
    // this.getBusinesses();
  }

  removeUnderscore(text: string) {
    return text.replace('_', ' ');
  }

  setFeaturesDetailedDefaults() {
    this.featuresDetailedCheck.locName = {value: true, text: 'NAME'};
    this.featuresDetailedCheck.locId = {value: true, text: 'IDENTIFIER'};
    this.featuresDetailedCheck.locAdd1 = {value: true, text: 'ADDRESS_DISPLAY'};
    this.featuresDetailedCheck.locAdd2 = {value: true, text: 'ADDRESS_EXTRA'};
    this.featuresDetailedCheck.locZip = {value: true, text: 'ZIP'};
    this.featuresDetailedCheck.locCity = {value: true, text: 'CITY'};
    this.featuresDetailedCheck.locCateg = {value: true, text: 'CATEGORIES'};
    this.featuresDetailedCheck.locPhone = {value: true, text: 'PHONE'};
    this.featuresDetailedCheck.locMobile = {value: true, text: 'CELLPHONE'};
    this.featuresDetailedCheck.locFax = {value: true, text: 'FAX'};
    this.featuresDetailedCheck.locWebsite = {value: true, text: 'WEBSITE'};
    this.featuresDetailedCheck.locEmail = {value: true, text: 'EMAIL'};
    this.featuresDetailedCheck.locOpenHours = {value: true, text: 'OPENINGHOURS'};
    this.featuresDetailedCheck.locOpenHoursNotes = {value: true, text: 'OPENINGHOURS_NOTES'};
    this.featuresDetailedCheck.locSpecOpenHours = {value: true, text: 'SPECIAL_OPENINGHOURS'};
    this.featuresDetailedCheck.locKeywords = {value: true, text: 'KEYWORDS'};
    this.featuresDetailedCheck.locShortDesc = {value: true, text: 'DESCRIPTION_SHORT'};
    this.featuresDetailedCheck.locLongDesc = {value: true, text: 'DESCRIPTION_LONG'};
    this.featuresDetailedCheck.locImprint = {value: true, text: 'IMPRINT'};
    this.featuresDetailedCheck.locSocialProf = {value: true, text: 'SOCIAL_PROFILES'};
    this.featuresDetailedCheck.locAttributes = {value: true, text: 'ATTRIBUTES'};
    this.featuresDetailedCheck.locPayOpt = {value: true, text: 'PAYMENT_OPTIONS'};
    this.featuresDetailedCheck.locBrands = {value: true, text: 'BRANDS'};
    this.featuresDetailedCheck.locLanguages = {value: true, text: 'LANGUAGES'};
    this.featuresDetailedCheck.locServices = {value: true, text: 'SERVICES'};
    this.featuresDetailedCheck.locContentList = {value: true, text: 'CONTENT_LISTS'};
    this.featuresDetailedCheck.locPhotos = {value: true, text: 'PHOTOS'};
    this.featuresDetailedCheck.locVideos = {value: true, text: 'VIDEOS'};
  }

  getWhiteLabels(): void {
    // this.whitelabelService.getWhiteLabels().subscribe(whiteLabels => this.whiteLabels = whiteLabels.response.labels);
    const currentUser: User = this.authService.currentUserValue;
    this.whiteLabels = JSON.parse(currentUser.salesPartner).whitelabelInformation;
  }

  getBusinesses(businessesParam = null): void {
    this.businessService.getBusiness().subscribe(businesses => {
      const allBusinesses = businesses.response.businesses;
      const list: Array<Select2OptionData> = [];
      const acountsSelText = [];
      for (let bus of allBusinesses) {
        list.push({id: String(bus.identifier), text: bus.name});
      }
      this.businesses =  Observable.create((obs) => {
        obs.next(list);
        obs.complete();
      });
      if (businessesParam !== null) {
        const acountsSelText = [];
        for (const eachAccount in businessesParam) {
          acountsSelText.push(String(businessesParam[eachAccount].identifier));
        }
        this.businessesTexts = Observable.create((obs) => {
          obs.next(acountsSelText);
          obs.complete();
        });
      }
    });
  }

  valueChanged(event: any) {
    if (typeof event.data !== 'undefined' && event.data.length > 0) {
      for (let account of event.data) {
        if (!Object.keys(this.existingAccounts).includes(account.id)) {
          const componentFactory = this.componentFactoryResolver.resolveComponentFactory(ManageAccountComponent);
          const viewContainerRef = this.accMan.viewContainerRef;
          // viewContainerRef.clear();
          const componentRef = viewContainerRef.createComponent(componentFactory);
          componentRef.instance.accountId = account.id;
          componentRef.instance.accountName = account.text;
          componentRef.instance.role = this.newUserForm.get('role').value;
          componentRef.instance.newUserForm = this.newUserForm;
          if (typeof this.user !== 'undefined') {
            if (this.user.locations !== null) {
              componentRef.instance.currentLocations = this.user.locations;
            }
          }
          this.existingAccounts[account.id] = componentRef;
        }
      }
    }
    for (let accId of Object.keys(this.existingAccounts)) {
      if (typeof event.value !== 'undefined' && !event.value.includes(accId)) {
        const locsToDelete = this.existingAccounts[accId].instance.currentLocations;
        const realLocationsValues = this.newUserForm.get('realLocations').value;
        // tslint:disable-next-line:forin
        for (const loc in locsToDelete) {
          realLocationsValues.splice(locsToDelete[loc].identifier, 1);
        }
        this.newUserForm.get('realLocations').setValue(realLocationsValues);
        this.existingAccounts[accId].destroy();
        delete this.existingAccounts[accId];
      }
    }
  }

  roleChanged(event: any) {
    this.selectedRole = event.target.value;
    for (let accId of Object.keys(this.existingAccounts)) {
      this.existingAccounts[accId].destroy();
      delete this.existingAccounts[accId];
    }
    this.newUserForm.get('managedBusinesses').setValue('');
    if (event.target.value !== 'ADMIN') {
      this.newUserForm.controls.managedBusinesses.clearValidators()
      this.newUserForm.controls.managedBusinesses.setValidators([Validators.required]);
      this.newUserForm.controls.managedBusinesses.updateValueAndValidity();
    } else {
      this.newUserForm.controls.managedBusinesses.clearValidators()
      this.newUserForm.controls.managedBusinesses.updateValueAndValidity();
    }
    this.newUserForm.get('realLocations').setValue('');
  }

  onSubmit(event) {
    // TODO: Use EventEmitter with form value
    this.loading = true;
    this.ngLoading = true;
    console.warn(this.newUserForm, event);
    if (this.createOrEdit === 'Create') {
      this.userService.createUser(this.newUserForm.value as User)
        .subscribe(response => {
            console.log('RESPONSE: ', response);
            this.ngLoading = false;
            this.loading = false;
            this.newUserForm.reset();
            this.roles = [];
            this.ngOnInit();
            if ( String(response.status) === 'SUCCESS' ) {
               this.alertService.alert('success', 'User created successfully.');
            }
        },
          error => {
            this.ngLoading = false;
            this.loading = false;
          }
        );
    } else {
      this.userService.updateUser(this.newUserForm.value as User, this.user.identifier)
        .subscribe(response => {
            console.log('RESPONSE: ', response);
            this.loading = false;
            this.ngLoading = false;
            if ( String(response.status) === 'SUCCESS' ) {
              this.alertService.alert('success', 'User updated successfully.');
            }
          },
          error => {
            this.ngLoading = false;
            this.loading = false;
          }
        );
    }
  }

  get formControls() {
    return this.newUserForm.controls;
  }

  onFeaturesDetailedValueChange(event = null, featurePassed = '') {
    this.featuresDetailedSelected = [];
    for (let feature in this.featuresDetailedCheck) {
      if (this.featuresDetailedCheck[feature].text === featurePassed && event === true) {
        this.featuresDetailedSelected.push(this.featuresDetailedCheck[feature].text);
      } else if (this.featuresDetailedCheck[feature].value) {
        this.featuresDetailedSelected.push(this.featuresDetailedCheck[feature].text);
      }
    }
  }

  onFeaturesValueChange( event = null, featurePassed = '' ) {
    this.featuresSelected = [];
    for (let feature in this.featuresCheck) {
      if (this.featuresCheck[feature].text === featurePassed && event === true) {
        this.featuresSelected.push(this.featuresCheck[feature].text);
      } else if (this.featuresCheck[feature].value) {
        this.featuresSelected.push(this.featuresCheck[feature].text);
      }
    }
  }
}
