import { Role } from './role';
import {Salutation} from './salutation';
import {UserStatus} from './user-status';
import {Business} from './business';
import {Location} from './location';

export class User {
    id: number;
    identifier: string;
    password: string;
    firstname: string;
    lastname: string;
    role: Role;
    features: [];
    featuresDetailed: {};
    token?: string;
    email: string;
    emailSettings: [];
    managedBusinesses: [];
    managedLocations: [];
    phone: string;
    preferredLanguage: string;
    salesPartner: string;
    salutation: Salutation;
    status: UserStatus;
    whitelabelInformationIdentifier: string;
    whitelabelInformation: string; // { identifier: string };
    businesses: Array<Business> ;
    locations: Array<Location> ;
    impersonate?: string;
  // tslint:disable-next-line:variable-name
    created_at: string;
    constructor(
      email: string,
      password: string,
      token: string
    ) {
      this.email = email;
      this.password = password;
      this.token = token;
    }
}
