import {AfterViewInit, Component, OnInit} from '@angular/core';
import {ReportsComponent} from '../reports/reports.component';
// import pdfMake from 'pdfmake/build/pdfmake';
// import pdfFonts from 'pdfmake/build/vfs_fonts';
// pdfMake.vfs = pdfFonts.pdfMake.vfs;

@Component({
  selector: 'app-management-report',
  templateUrl: './management-report.component.html',
  styleUrls: ['./management-report.component.scss']
})
export class ManagementReportComponent extends ReportsComponent implements OnInit {

  ngOnInit() {

  }
}
