import { Component, OnInit, Input, Output, EventEmitter, AfterViewInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';

@Component({
  selector: 'app-manage-location-videos',
  templateUrl: './manage-location-videos.component.html',
  styleUrls: ['./manage-location-videos.component.scss']
})
export class ManageLocationVideosComponent implements OnInit {

  @Input() position: number;
  @Input() locForm: FormGroup;
  @Output() newVideoClick: EventEmitter<{type: string, position: number}> = new EventEmitter<{type: string, position: number}>();
  descAmount: number;

  constructor( private fb: FormBuilder ) { this.descAmount = 0; }

  ngOnInit() {
    this.descAmount = ((this.locForm.get('videos') as FormArray).controls[this.position] as FormArray).controls['videoDescription'].value.length;
  }

  descKeyUp(event) {
    this.descAmount = event.target.value.length;
  }

  videosEvent(type: string, position: number) {
    this.newVideoClick.emit({type: type, position: position});
  }

  get video() {
    // console.log((this.locForm.get('videos') as FormArray).controls[this.position]);
    return ((this.locForm.get('videos') as FormArray).controls[this.position] as FormArray).controls['videoUrl'];
  }

}
