import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {Observable} from 'rxjs';
import {UsersResponse, WhiteLabelsResponse} from '../../_models/datatable-response';
import {catchError, tap} from 'rxjs/operators';
import {Errors} from '../../_helpers/errors';

@Injectable({
  providedIn: 'root'
})
export class WhitelabelService {

  private whiteLabelsUrl = 'labels';
  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      // privateKey: environment.privateKey
    })
  };
  constructor(private http: HttpClient) { }

  getWhiteLabels(max: number = 20, offset: number = 0, searchVal = ''): Observable<WhiteLabelsResponse> {
    return this.http.get<WhiteLabelsResponse>(
      `${environment.apiUrl}` + this.whiteLabelsUrl + '?max=' + max + '&offset=' + offset + '&query=' + searchVal
    ).pipe(
      tap(_ => console.log('fetched whitelabels')),
      catchError(Errors.handleError<WhiteLabelsResponse>('getWthiteLabels'))
    );
  }

  getWhiteLabelsLocal(){

  }
}
