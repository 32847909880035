import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-manage-special-operation-hours',
  templateUrl: './manage-special-operation-hours.component.html',
  styleUrls: ['./manage-special-operation-hours.component.scss']
})
export class ManageSpecialOperationHoursComponent implements OnInit {

  @Input() position: number;
  @Input() locForm: FormGroup;
  @Output() newSpecOperHoursClick: EventEmitter<{type: string, position: number}> = new EventEmitter<{type: string, position: number}>();
  @Input() initValues: any;
  openClosed: string;
  date: NgbDateStruct;
  constructor() {}

  ngOnInit() {
    if (typeof this.initValues !== 'undefined'){
      this.openClosed = (typeof this.initValues.closed !== 'undefined' && !this.initValues.closed) ? 'open' : 'closed';
      if (typeof this.initValues.date !== 'undefined') {
        let dateValues = this.initValues.date.split('-');
        this.date = { year: parseInt(dateValues[0]), month: parseInt(dateValues[1]), day: parseInt(dateValues[2]) };
      } else {
        this.date = { year: 0, month: 0, day: 0 };
      }
    } else {
      this.openClosed = 'closed';
      this.date = { year: 0, month: 0, day: 0 };
    }
  }

  operHoursEvent(type: string, position = -1) {
    this.newSpecOperHoursClick.emit({type: type, position: position});
  }

  changeOpenClosed(event) {
    this.openClosed = event.target.value;
  }

}
