import {Directive, ViewContainerRef} from '@angular/core';

@Directive({
  selector: '[appManageAccount]'
})
export class ManageAccountDirective {

  constructor(public viewContainerRef: ViewContainerRef) { }

}
