import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Select2OptionData } from 'ng-select2';
import { BusinessService } from '../_services/business/business.service';
import { LocationService } from '../_services/location/location.service';
import { environment } from 'src/environments/environment';
import * as moment from 'moment';
import { DashboardService } from '../_services/dashboard/dashboard.service';
import {ChartDataSets, ChartType} from 'chart.js';
import {Label, MultiDataSet} from 'ng2-charts';
import {User} from '../_models';
import {AuthenticationService} from '../_services';
declare var $: any;

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  title = 'Dashboard';
  public optionsBusinesses: any;
  public optionsLocations: any;
  businesses: Observable<Array<Select2OptionData>>;
  businessesIdList: Array<string> = [];
  locations: Observable<Array<Select2OptionData>>;
  locationsId: Array<string> = [];
  public daterange: any = {start: moment().subtract(1, 'year'), end: moment()};
  public optionsDateRange: any = {
    locale: { format: 'MM/DD/YYYY' },
    alwaysShowCalendars: false,
    startDate: moment().subtract(1, 'year'),
    endDate: moment(),
    ranges: {
      'This Year': [moment().startOf('year'), moment().endOf('year')],
      // 'Last Year': [moment().subtract(1, 'year').startOf('year'), moment().subtract(1, 'year').endOf('year')],
      'Last Year': [moment().subtract(1, 'year'), moment()],
      'This Quarter': [moment().startOf('quarter'), moment().endOf('quarter')],
      'Last Quarter': [moment().subtract(1, 'quarter').startOf('quarter'), moment().subtract(1, 'quarter').endOf('quarter')],
      'This Month': [moment().startOf('month'), moment().endOf('month')],
      'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
      'This Week': [moment().startOf('week'), moment().endOf('week')],
      'Last Week': [moment().subtract(1, 'week').startOf('week'), moment().subtract(1, 'week').endOf('week')],
      Today: [moment(), moment()],
    }
  };
  countFieldsInSync = 0;
  countListingsBeingUpdated = 0;
  countListingsInSync = 0;
  countLocationsRequireSync = 0;
  ngListingHealth = false;
  knOptionsPC: {};
  knOptionsGF: {};
  profileCompleteness: MultiDataSet = [[0, 0]];
  countLocationsMissingData = 0;
  directoriesMissingConnect = 0;
  ngProfileCompleteness = false;
  connectedLocsChartLabels: Label[] = ['Download Sales', 'In-Store Sales', 'Mail-Order Sales'];
  connectedLocsChartData: MultiDataSet = [[0, 0, 0]];
  connectedLocsChartType: ChartType = 'doughnut';
  ngInsightsDataWarnings: boolean;
  notConnectedLocsChartLabels: Label[] = ['Download Sales', 'In-Store Sales', 'Mail-Order Sales'];
  notConnectedLocsChartData: MultiDataSet = [[0, 0, 0]];
  notConnectedLocsChartType: ChartType = 'doughnut';
  ngSuppressedDuplicates: boolean;
  suppressedDuplicatesChartLabels: Label[] = ['Download Sales', 'In-Store Sales', 'Mail-Order Sales'];
  suppressedDuplicatesChartData: MultiDataSet = [[0, 0, 0]];
  suppressedDuplicatesChartType: ChartType = 'doughnut';
  colors: Array<any>;
  profileCompletenessLabels: Label[] = ['Not missing data', 'Missing data'];
  ngInsightsDataGoogleImpressions: boolean;
  insightsDataGoogleImpressionsChartData: ChartDataSets[] = [];
  insightsDataGoogleImpressionsChartLabels: Label[] = [];
  ngInsightsDataGoogleClicks: boolean;
  insightsDataGoogleClicksChartLabels: Label[] = [];
  insightsDataGoogleClicksChartData: ChartDataSets[] = [];
  ngInsightsDataGooglePhotos: boolean;
  insightsDataGooglePhotosChartLabels: Label[] = [];
  insightsDataGooglePhotosChartData: ChartDataSets[] = [];
  ngInsightsDataGoogleQueries: boolean;
  insightsDataGoogleQueriesChartLabels: Label[] = ['Direct Queries', 'Indirect Queries', 'Branded queries'];
  insightsDataGoogleQueriesChartData: MultiDataSet = [];
  user: User;
  colorsBlank = [{backgroundColor: ['#eeeeee']}];
  optionsBlank = {tooltips: {enabled: false}, legend: {display: true, align: 'center', position: 'bottom'}};
  optionsData = {
    onResize: (chart, size) => { chart.options.legend.display = (size.width >= 250); },
    legend: {display: true, align: 'center', position: 'right', labels: {boxWidth: 10, usePointStyle: true, fontSize: 11, }},
    tooltips: {
      callbacks: {
        label: (tooltipItem, data) => {
          const label = data.labels[tooltipItem.index] + ': ' + data.datasets[0].data.reduce((a, b) => a + b) || '';
          return label;
        }
      }
    }
  };
  optionsDataProfile = {
    onResize: (chart, size) => { chart.options.legend.display = (size.width >= 250); },
    legend: {display: true, align: 'center', position: 'right', labels: {boxWidth: 10, usePointStyle: true, fontSize: 11, }}
  };

  constructor(
    private businessService: BusinessService,
    private locationService: LocationService,
    private dashboardService: DashboardService,
    private authService: AuthenticationService
  ) { }

  ngOnInit() {
    this.user = this.authService.currentUserValue;
    this.colors = [{backgroundColor: [
        '#fb9678',
        '#03a9f3',
        '#01c0c8',
        '#ab8ce4',
        '#e46a76',
        '#fec107'
      ]}];
    this.optionsBusinesses = {
      multiple: true,
      closeOnSelect: false,
      width: '100%',
    };
    this.optionsLocations = {
      multiple: true,
      closeOnSelect: true,
      width: '100%',
      maximumSelectionSize: 5,
      minimumInputLength: 4,
      ajax: {
        headers: {
          Authorization : 'Bearer ' + this.user.token,
          'Content-Type' : 'application/json',
        },
        url: (params) => {
          let businessString = '';
          if (this.businessesIdList.length > 0) {
            businessString = 'businessIds[]=' + this.businessesIdList.join('&businessIds[]=') + '&';
          }
          return `${environment.apiUrl}` + 'locations' + (
            ((businessString !== '') ? ('?' + businessString) : ('?'))
            + 'max=' + 10 + '&offset=' + params.page + '&query=' + params.term + '&status=ACTIVE');
          // return `${environment.apiUrl}` + 'locations?language=en&max=' + 10 + '&offset=' + params.page + '&q=' + params.term;
        },
        processResults: (data, params) => {
          params.page = params.page || 1;
          const response = data.response.locations;
          const modResults = [];
          for (let loc of response) {
            // tslint:disable-next-line:max-line-length
            modResults.push({id: String(loc.identifier), text: loc.name + ' - ' + loc.streetNo + ' ' + loc.street + ', ' + loc.city + ', ' + loc.country});
          }
          return {
            results: modResults,
            pagination: {
                more: (params.page * 10) < data.count
            }
        };
        }
      }
    };
    this.knOptionsPC = {
      readOnly: true,
      size: 98,
      unit: '%',
      textColor: '#000000',
      fontSize: '16',
      valueformat: 'percent',
      max: 100,
      trackWidth: 24,
      barWidth: 24,
      trackColor: '#edf1f5',
      barColor: '#fb9678',
      barCap: 0,
      animate: {
        enabled: true,
        duration: 1000,
        ease: 'bounce'
      },
      dynamicOptions: true
    };
    this.knOptionsGF = {
      readOnly: true,
      size: 100,
      unit: '%',
      textColor: '#000000',
      fontSize: '16',
      valueformat: 'percent',
      max: 100,
      trackWidth: 14,
      barWidth: 15,
      trackColor: '#edf1f5',
      barColor: '#03a9f3',
      barCap: 10,
      animate: {
        enabled: true,
        duration: 1000,
        ease: 'bounce'
      },
      dynamicOptions: true
    };
    this.getBusinesses();
    this.getListingHealth();
    this.getProfileCompleteness();
    this.getInsightsDataWarnings();
    this.getSuppressedDuplicates();
    this.getInsightsDataGoogleImpressions();
    this.getInsightsDataGoogleClicks();
    this.getInsightsDataGooglePhotos();
    this.getInsightsDataGoogleQueries();
  }

  getBusinesses(): void {
    this.businessService.getBusiness().subscribe(
      businesses => {
        const allBusinesses = businesses.response.businesses;
        const list: Array<Select2OptionData> = [];
        for (let bus of allBusinesses) {
          list.push({id: String(bus.identifier), text: bus.name});
          this.businessesIdList.push(String(bus.identifier));
        }
        this.businesses =  Observable.create((obs) => {
          obs.next(list);
          obs.complete();
        });
      }
    );
  }

  changeBusiness(event) {
    console.log(event);
    if (event.value.length > 0) {
      this.businessesIdList = event.value;
    }
    // this.getLocations(event.value);
  }

  getLocations(busnisses: []): void {
    this.locationService.getLocations(busnisses).subscribe(
      locations => {
        const alllocations = locations.response.locations;
        const list: Array<Select2OptionData> = [];
        for (let bus of alllocations) {
          list.push({id: String(bus.identifier), text: bus.name});
        }
        this.businesses =  Observable.create((obs) => {
          obs.next(list);
          obs.complete();
        });
      }
    );
  }

  changeLocations(event) {
    console.log(event);
    if (event.value.length > 0) {
      this.locationsId = event.value;
    }
  }

  selectedDate(value: any, datepicker?: any) {
    // this is the date the iser selected
    console.log(value, datepicker);

    // any object can be passed to the selected event and it will be passed back here
    datepicker.start = value.start;
    datepicker.end = value.end;

    // or manupulat your own internal property
    this.daterange.start = value.start;
    this.daterange.end = value.end;
    this.daterange.label = value.label;
  }

  getListingHealth() {
    console.log(this.locationsId);
    this.ngListingHealth = true;
    this.dashboardService.getListingHealth(this.businessesIdList, this.locationsId).subscribe(
      response => {
        this.countFieldsInSync = response.response.countFieldsInSync;
        this.countListingsBeingUpdated = response.response.countListingsBeingUpdated;
        this.countListingsInSync = response.response.countListingsInSync;
        this.countLocationsRequireSync = response.response.countLocationsRequireSync;
        this.ngListingHealth = false;
      },
      error => {

      }
    );
  }

  getProfileCompleteness() {
    this.ngProfileCompleteness = true;
    this.dashboardService.getProfileCompleteness(this.businessesIdList, this.locationsId).subscribe(
      response => {
        this.profileCompleteness[0][0] = response.response.countLocationsMissingData;
        // @ts-ignore
        // tslint:disable-next-line:max-line-length
        this.profileCompleteness[0][1] = (((100 * response.response.countLocationsMissingData) / response.response.averageProfileCompleteness) - response.response.countLocationsMissingData).toFixed(0);
        this.directoriesMissingConnect = response.response.averageProfileCompleteness;
        this.countLocationsMissingData = response.response.countLocationsMissingData;
        this.profileCompletenessLabels = ['Not missing data: ' + this.directoriesMissingConnect + '%', 'Missing data: ' + (100 - this.directoriesMissingConnect) + '%'];
        this.ngProfileCompleteness = false;
      },
      error => {

      }
    );
  }

  getLocationStatistics() {
    this.dashboardService.getProfileCompleteness(this.businessesIdList, this.locationsId).subscribe(
      response => {
        this.profileCompleteness = response.response.averageProfileCompleteness;
        this.ngProfileCompleteness = false;
      },
      error => {

      }
    );
  }

  getInsightsDataWarnings() {
    this.ngInsightsDataWarnings = true;
    this.dashboardService.getInsightsDataWarnings(this.businessesIdList, this.locationsId).subscribe(
      response => {
        const connectedLocations = response.response.connectedLocations;
        const notConnectedLocations = response.response.notConnectedLocations;
        const warnings = response.response.warnings;

        const connectedLocsData = [[], []];
        let connectedLocsTotalCount = 0;
        const notConnectedLocsData = [[], []];
        let notConnectedLocsTotalCount = 0;
        connectedLocations.sort((a, b) => (a.count <= b.count) ? 1 : -1);
        notConnectedLocations.sort((a, b) => (a.count <= b.count) ? 1 : -1);
        // tslint:disable-next-line:forin
        for (const connLoc in connectedLocations) {
          connectedLocsData[1].push(connectedLocations[connLoc].count);
          connectedLocsTotalCount += connectedLocations[connLoc].count;
          if ( connectedLocations[connLoc].count > 0 ) {
            connectedLocsData[0].push(connectedLocations[connLoc].type + ': ' + connectedLocations[connLoc].count);
          }
        }

        // tslint:disable-next-line:forin
        for (const notConnLoc in notConnectedLocations) {
          notConnectedLocsData[1].push(notConnectedLocations[notConnLoc].count);
          notConnectedLocsTotalCount += notConnectedLocations[notConnLoc].count;
          if (notConnectedLocations[notConnLoc].count > 0) {
            notConnectedLocsData[0].push(notConnectedLocations[notConnLoc].type + ': ' + notConnectedLocations[notConnLoc].count);
          }
        }

        this.connectedLocsChartData = [connectedLocsData[1]];
        this.connectedLocsChartLabels = connectedLocsData[0];
        this.notConnectedLocsChartData = [notConnectedLocsData[1]];
        this.notConnectedLocsChartLabels = notConnectedLocsData[0];

        this.ngInsightsDataWarnings = false;
      },
      error => {

      }
    );
  }

  getSuppressedDuplicates() {
    this.ngSuppressedDuplicates = true;
    this.dashboardService.getSuppressedDuplicates(this.businessesIdList, this.locationsId).subscribe(
      response => {
        const suppressesDuplicates = response.response.countByDirectory;
        const suppressesDuplicatesData = [[], []];
        let suppressesDuplicatesTotalCount = 0;
        suppressesDuplicates.sort((a, b) => (a.count <= b.count) ? 1 : -1);
        // tslint:disable-next-line:forin
        for (const connLoc in suppressesDuplicates) {
          console.log(connLoc)
          // @ts-ignore
          if (connLoc < 5) {
            suppressesDuplicatesData[0].push(suppressesDuplicates[connLoc].directoryType + ': ' + suppressesDuplicates[connLoc].count);
            suppressesDuplicatesData[1].push(suppressesDuplicates[connLoc].count);
          } else {
            suppressesDuplicatesTotalCount += suppressesDuplicates[connLoc].count;
          }
        }
        suppressesDuplicatesData[0].push('OTHERS: ' + suppressesDuplicatesTotalCount);
        suppressesDuplicatesData[1].push(suppressesDuplicatesTotalCount);
        this.suppressedDuplicatesChartData = [suppressesDuplicatesData[1]];
        this.suppressedDuplicatesChartLabels = suppressesDuplicatesData[0];
        this.ngSuppressedDuplicates = false;
      },
      error => {

      }
    );
  }

  getInsightsDataGoogleImpressions() {
     // startDate=2019-12-17&endDate=2020-01-15&group=DAY&type=google&metrics=VIEWS_MAPS&metrics=VIEWS_SEARCH&textFilter=&v=20191203
    this.ngInsightsDataGoogleImpressions = true;
    const group = (this.daterange.end.diff(this.daterange.start, 'months') > 1) ? 'MONTH' : 'DAY';
    this.dashboardService.getInsightsData(
      this.daterange.start.format('YYYY-MM-DD'),
      this.daterange.end.format('YYYY-MM-DD'),
      group,
      'google',
      ['VIEWS_MAPS', 'VIEWS_SEARCH'],
      this.businessesIdList,
      this.locationsId
    ).subscribe(
      response => {
        const metrics = response.response.metrics;
        const timeValues = [];
        const monthsTotals = [{data: [], label: 'MAPS'}, {data: [], label: 'SEARCH'}];
        for (const m in metrics) {
          if (metrics[m].name === 'VIEWS_MAPS' && metrics[m].data.length > 0) {
            for (const data in metrics[m].data) {
              if (timeValues.indexOf(metrics[m].data[data].period) === -1) {
                timeValues.push(metrics[m].data[data].period);
              }
              monthsTotals[0].data.push(metrics[m].data[data].count);
            }
          } else if (metrics[m].name === 'VIEWS_SEARCH' && metrics[m].data.length > 0) {
            for (const data in metrics[m].data) {
              if (timeValues.indexOf(metrics[m].data[data].period) === -1) {
                timeValues.push(metrics[m].data[data].period);
              }
              monthsTotals[1].data.push(metrics[m].data[data].count);
            }
          }
        }
        this.insightsDataGoogleImpressionsChartLabels = timeValues;
        this.insightsDataGoogleImpressionsChartData = monthsTotals;
        this.ngInsightsDataGoogleImpressions = false;
      },
      error => {

      }
    );
  }

  getInsightsDataGoogleClicks() {
    // startDate=2019-12-17&endDate=2020-01-15&group=DAY&type=google&metrics=VIEWS_MAPS&metrics=VIEWS_SEARCH&textFilter=&v=20191203
    this.ngInsightsDataGoogleClicks = true;
    const group = (this.daterange.end.diff(this.daterange.start, 'months') > 1) ? 'MONTH' : 'DAY';
    this.dashboardService.getInsightsData(
      this.daterange.start.format('YYYY-MM-DD'),
      this.daterange.end.format('YYYY-MM-DD'),
      group,
      'google',
      ['ACTIONS_DRIVING_DIRECTIONS', 'ACTIONS_WEBSITE', 'ACTIONS_PHONE'],
      this.businessesIdList,
      this.locationsId
    ).subscribe(
      response => {
        const metrics = response.response.metrics;
        const timeValues = [];
        const monthsTotals = [{data: [], label: 'DRIVING DIRECTIONS'}, {data: [], label: 'WEBSITE'}, {data: [], label: 'PHONE'}];
        for (const m in metrics) {
          if (metrics[m].name === 'ACTIONS_DRIVING_DIRECTIONS' && metrics[m].data.length > 0) {
            for (const data in metrics[m].data) {
              if (timeValues.indexOf(metrics[m].data[data].period) === -1) {
                timeValues.push(metrics[m].data[data].period);
              }
              monthsTotals[0].data.push(metrics[m].data[data].count);
            }
          } else if (metrics[m].name === 'ACTIONS_WEBSITE' && metrics[m].data.length > 0) {
            for (const data in metrics[m].data) {
              if (timeValues.indexOf(metrics[m].data[data].period) === -1) {
                timeValues.push(metrics[m].data[data].period);
              }
              monthsTotals[1].data.push(metrics[m].data[data].count);
            }
          } else if (metrics[m].name === 'ACTIONS_PHONE' && metrics[m].data.length > 0) {
            for (const data in metrics[m].data) {
              if (timeValues.indexOf(metrics[m].data[data].period) === -1) {
                timeValues.push(metrics[m].data[data].period);
              }
              monthsTotals[2].data.push(metrics[m].data[data].count);
            }
          }
        }
        this.insightsDataGoogleClicksChartLabels = timeValues;
        this.insightsDataGoogleClicksChartData = monthsTotals;
        this.ngInsightsDataGoogleClicks = false;
      },
      error => {

      }
    );
  }

  getInsightsDataGooglePhotos() {
    this.ngInsightsDataGooglePhotos = true;
    // startDate=2019-12-17&endDate=2020-01-15&group=DAY&type=google&metrics=VIEWS_MAPS&metrics=VIEWS_SEARCH&textFilter=&v=20191203
    const group = (this.daterange.end.diff(this.daterange.start, 'months') > 1) ? 'MONTH' : 'DAY';
    this.dashboardService.getInsightsData(
      this.daterange.start.format('YYYY-MM-DD'),
      this.daterange.end.format('YYYY-MM-DD'),
      group,
      'google',
      ['PHOTOS_VIEWS_MERCHANT'],
      this.businessesIdList,
      this.locationsId
    ).subscribe(
      response => {
        const metrics = response.response.metrics;
        const timeValues = [];
        const monthsTotals = [{data: [], label: 'PHOTOS'}];
        for (const m in metrics) {
          if (metrics[m].name === 'PHOTOS_VIEWS_MERCHANT' && metrics[m].data.length > 0) {
            for (const data in metrics[m].data) {
              if (timeValues.indexOf(metrics[m].data[data].period) === -1) {
                timeValues.push(metrics[m].data[data].period);
              }
              monthsTotals[0].data.push(metrics[m].data[data].count);
            }
          }
        }
        this.insightsDataGooglePhotosChartLabels = timeValues;
        this.insightsDataGooglePhotosChartData = monthsTotals;
        this.ngInsightsDataGooglePhotos = false;
      },
      error => {

      }
    );
  }

  getInsightsDataGoogleQueries() {
    this.ngInsightsDataGoogleQueries = true;
    // startDate=2019-12-17&endDate=2020-01-15&group=DAY&type=google&metrics=VIEWS_MAPS&metrics=VIEWS_SEARCH&textFilter=&v=20191203
    const group = (this.daterange.end.diff(this.daterange.start, 'months') > 1) ? 'MONTH' : 'DAY';
    this.dashboardService.getInsightsData(
      this.daterange.start.format('YYYY-MM-DD'),
      this.daterange.end.format('YYYY-MM-DD'),
      group,
      'google',
      ['QUERIES_DIRECT', 'QUERIES_INDIRECT', 'QUERIES_CHAIN'],
      this.businessesIdList,
      this.locationsId
    ).subscribe(
      response => {
        const metrics = response.response.metrics;
        const monthsTotals = [0, 0, 0];
        for (const m in metrics) {
          if (metrics[m].name === 'QUERIES_DIRECT' && metrics[m].data.length > 0) {
            for (const data in metrics[m].data) {
              monthsTotals[0] += metrics[m].data[data].count;
            }
          } else if (metrics[m].name === 'QUERIES_INDIRECT' && metrics[m].data.length > 0) {
            for (const data in metrics[m].data) {
              monthsTotals[1] += metrics[m].data[data].count;
            }
          } else if (metrics[m].name === 'QUERIES_CHAIN' && metrics[m].data.length > 0) {
            for (const data in metrics[m].data) {
              monthsTotals[2] += metrics[m].data[data].count;
            }
          }
        }
        this.insightsDataGoogleQueriesChartData = [monthsTotals];
        this.insightsDataGoogleQueriesChartLabels = ['Direct Queries: ' + monthsTotals[0], 'Indirect Queries: ' + monthsTotals[1], 'Branded queries: ' + monthsTotals[2]];
        this.ngInsightsDataGoogleQueries = false;
      },
      error => {

      }
    );
  }

  refreshDashboard() {
    this.getListingHealth();
    this.getProfileCompleteness();
    this.getInsightsDataWarnings();
    this.getSuppressedDuplicates();
    this.getInsightsDataGoogleImpressions();
    this.getInsightsDataGoogleClicks();
    this.getInsightsDataGooglePhotos();
    this.getInsightsDataGoogleQueries();
  }

}
