import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { User } from '../_models';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    private currentUserSubject: BehaviorSubject<User>;
    public currentUser: Observable<User>;

    constructor(private http: HttpClient) {
        this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
        this.currentUser = this.currentUserSubject.asObservable();
    }

    public get currentUserValue(): User {
        return this.currentUserSubject.value;
    }

    // login(username: string, password: string) {
    //     return this.http.post<any>(`${environment.apiUrl}/users/authenticate`, { username, password })
    //         .pipe(map(user => {
    //             // login successful if there's a jwt token in the response
    //             if (user && user.token) {
    //                 // store user details and jwt token in local storage to keep user logged in between page refreshes
    //                 localStorage.setItem('currentUser', JSON.stringify(user));
    //                 this.currentUserSubject.next(user);
    //             }
    //
    //             return user;
    //         }));
    // }

  login(email: string, password: string) {
    return this.http.post<any>(`${environment.apiUrl}users/login`, { email: email, password: password })
      .pipe(map(user => {
        // login successful if there's a jwt token in the response
        console.log(user)
        // if (user.response && user.response.access_token) {
        if (user.response && user.response.user && user.response.user.token) {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          // newUser = new User(username, password, user.response.access_token)
          // localStorage.setItem('currentUser', JSON.stringify(new User(email, password, user.response.access_token)));
          // this.currentUserSubject.next(new User(email, password, user.response.access_token));
          localStorage.setItem('currentUser', JSON.stringify(user.response.user as User));
          localStorage.setItem('impersonateValue', user.response.user.role);
          this.currentUserSubject.next(user.response.user as User);
        }
        return user;
      }));
  }

  logout() {
        // remove user from local storage to log user out
        localStorage.removeItem('currentUser');
        localStorage.removeItem('impersonateValue');
        this.currentUserSubject.next(null);
    }

  impersonate(id: number) {
    return this.http.get<any>(`${environment.apiUrl}users/impersonate/` + id)
      .pipe(map(user => {
        // login successful if there's a jwt token in the response
        console.log(user)
        // if (user.response && user.response.access_token) {
        if (user.response && user.response.user && user.response.user.token) {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          // newUser = new User(username, password, user.response.access_token)
          // localStorage.setItem('currentUser', JSON.stringify(new User(email, password, user.response.access_token)));
          // this.currentUserSubject.next(new User(email, password, user.response.access_token));
          localStorage.removeItem('currentUser');
          this.currentUserSubject.next(null);
          localStorage.setItem('currentUser', JSON.stringify(user.response.user as User));
          localStorage.setItem('impersonateValue', 'ADMIN');
          this.currentUserSubject.next(user.response.user as User);
        }
        return user;
      }));
  }

  resetPassword(email: string) {
    return this.http.post<any>(`${environment.apiUrl}password/forgot`, { email: email})
      .pipe(map(response => {
        // login successful if there's a jwt token in the response
        console.log(response);
        return response;
      }));
  }

  // tslint:disable-next-line:variable-name
  newPassword(password: string, confirmPassword: string, token: string) {
    // tslint:disable-next-line:max-line-length
      return this.http.post<any>(`${environment.apiUrl}password/reset`, { password: password, confirmPassword: confirmPassword, token: token })
      .pipe(map(response => {
        // login successful if there's a jwt token in the response
        console.log(response);
        return response;
      }));
  }
}
