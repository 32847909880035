import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {AuthenticationService, UserService} from '../_services';
import {User} from '../_models';
import {DataTableDirective} from 'angular-datatables';
import {UsersResponse} from '../_models/datatable-response';
import {Subject} from 'rxjs';
import {first} from 'rxjs/operators';
import {ActivatedRoute, Router} from '@angular/router';
import {AlertService} from '../_services/alert.service';
import { ngxCsv } from 'ngx-csv/ngx-csv';
import {BreadcrumbsService} from 'ng6-breadcrumbs';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements AfterViewInit, OnDestroy, OnInit {

  users: User[];
  dtOptions: DataTables.Settings = {};
  response: UsersResponse;
  @ViewChild(DataTableDirective, {static: false})
  dtElement: DataTableDirective;
  // @ts-ignore
  dtTrigger: Subject = new Subject();
  private returnUrl: string;
  currentUser: User;
  ngLoading: boolean;
  usersSelected: Array<any> = [];
  checkList: Array<any> = [];
  allFlag = false;
  private breadcrumbs: any;

  constructor(
    private userService: UserService,
    private authenticationService: AuthenticationService,
    private route: ActivatedRoute,
    private router: Router,
    private breadcrumbsService: BreadcrumbsService,
    private alertService: AlertService) {
  }

  ngOnInit() {
    this.breadcrumbsService.store([
      {label: 'Dashboard' , url: '/dashboard', params: []},
      {label: 'Users' , url: '/users', params: []}
    ]);
    this.currentUser = this.authenticationService.currentUserValue;
    this.returnUrl = this.route.snapshot.queryParams.returnUrl || '/';
    // this.getUsers();
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      serverSide: true,
      processing: true,
      ajax: (dataTablesParameters: any, callback) => {
        console.log('Datatable parameters: ', dataTablesParameters)
        this.getUsers(dataTablesParameters.length, dataTablesParameters.start, dataTablesParameters.search.value, callback);
      },
      columns: [
        {
          data: 'id',
          createdCell: (cell: Node, cellData: any, rowData: any, row: number, col: number) => {
            const btn = document.createElement('a');
            cellData = null;
            btn.setAttribute('data-id', rowData.id);
            btn.setAttribute('title', 'Edit User');
            btn.setAttribute('routerLink', '/edit-user/' + rowData.id);
            btn.className = 'btn waves-effect waves-light btn-outline-info';
            btn.innerHTML = '<i class="ti-pencil"></i>';
            cell.appendChild(btn);
          }
        },
        {data: 'firstname'},
        {data: 'lastname'},
        {data: 'email'},
        {data: 'role'},
        {
          data: 'id',
          createdCell: (cell: Node, cellData: any, rowData: any, row: number, col: number) => {
            const btn = document.createElement('a');
            cellData = null;
            btn.setAttribute('data-id', rowData.id);
            btn.setAttribute('title', 'Edit User');
            btn.setAttribute('routerLink', '/edit-user/' + rowData.id);
            btn.className = 'btn waves-effect waves-light btn-outline-info';
            btn.innerHTML = '<i class="ti-pencil"></i>';
            cell.appendChild(btn);
          }
        }
      ],
      language: {
        processing: '<div class="spinning"><div class="spinner"><div class="bounce1"></div><div class="bounce2"></div><div class="bounce3"></div></div></div>'
      }
    };
  }

  /**
   * Get the list of existent users.
   */
  getUsers(max: number = 20, offset: number = 0, searchVal = '', callBackData): void {
    this.userService.getUsers(max, offset, searchVal)
      .subscribe(response => {
        this.users = response.response.users;
        for (const user in this.users) {
          if (typeof this.checkList[this.users[user].identifier] === 'undefined')
            this.checkList[this.users[user].identifier] = this.allFlag;
        }
        console.log('this.checkList:', this.checkList);
        callBackData({
          recordsTotal: response.response.max,
          recordsFiltered: response.response.count,
          data: []// response.response.users
        });
      });
  }

  /**
   * Impersonte another user.
   */
  ghostUser(id: number) {
    this.ngLoading = true;
    this.authenticationService.impersonate(id)
      .pipe(first())
      .subscribe(
        data => {
          console.log('login comp data in');
          console.log('this.returnUrl', this.returnUrl);
          // this.router.navigate([this.returnUrl]);
          location.href = this.returnUrl;
          this.ngLoading = true;
        },
        error => {
          this.ngLoading = true;
          console.log('login comp data out');
          this.alertService.error(error);
        }
      );
  }

  /**
   * Delete a user.
   */
  deleteUser(id: string) {
    this.userService.deleteUser(id).subscribe(response => {
      this.alertService.alert('success', 'User deleted successfully.');
      this.rerender();
    });
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }

  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Destroy the table first
      dtInstance.destroy();
      // Call the dtTrigger to rerender again
      this.dtTrigger.next();
    });
  }

  selectUser(event: Event) {
    // @ts-ignore
    if (event.target.checked) {
      // @ts-ignore
      this.usersSelected.push(event.target.value);
    } else {
      // @ts-ignore
      const index = this.usersSelected.indexOf(event.target.value, 0);
      if (index > -1) {
        this.usersSelected.splice(index, 1);
      }
    }
    console.log(this.usersSelected);
    console.log(this.checkList);
  }

  exportData() {
    if (this.usersSelected.length > 0) {
      this.userService.getUsersToExport((this.allFlag) ? ['all'] : this.usersSelected)
        .subscribe(response => {
          const users = response.response.users;
          const options = {
            headers: ['First Name', 'Last Name', 'Email', 'Role', 'Phone', 'Created At']
          };
          let formatedUsers = [];
          for (const user of users) {
            formatedUsers.push({
              firstName: user.firstname,
              lastName: user.lastname,
              email: user.email,
              role: user.role,
              phone: user.phone,
              created_at: user.created_at,
            });
          }
          const result = new ngxCsv(formatedUsers, 'Users List', options);
        });
    }
  }

  selectAllUsers(event: Event) {
    for (const check in this.checkList) {
        this.checkList[check] = true;
        if (typeof this.usersSelected[check] === 'undefined')
          this.usersSelected.push(check);
    }
    this.allFlag = true;
    console.log(this.usersSelected);
  }

  unselectUsers(event: MouseEvent) {
    for (const check in this.checkList) {
      this.checkList[check] = false;
      const index = this.usersSelected.indexOf(check, 0);
      if (index > -1) {
        this.usersSelected.splice(index, 1);
      }
    }
    this.allFlag = false;
    console.log(this.usersSelected);
  }
}
