import { Component, OnInit, ComponentFactoryResolver, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ManageOperationHoursDirective } from '../_directives/manage-operation-hours.directive';
import { Observable } from 'rxjs';
import { Select2OptionData } from 'ng-select2';

@Component({
  selector: 'app-manage-operation-hours',
  templateUrl: './manage-operation-hours.component.html',
  styleUrls: ['./manage-operation-hours.component.scss']
})
export class ManageOperationHoursComponent implements OnInit {

  days: Array<Select2OptionData>;
  public options: any;
  @Input() locForm: FormGroup;
  @Input() position: number;
  existingOperHours: any;
  @Output() newOperHoursClick: EventEmitter<{type: string, position: number}> = new EventEmitter<{type: string, position: number}>();
  @Input() daysInitValues: any[];

  constructor(private componentFactoryResolver: ComponentFactoryResolver) { }

  ngOnInit() {
    this.existingOperHours = [];
    this.days = [
      {id: '1', text: 'Monday'},
      {id: '2', text: 'Tuesday'},
      {id: '3', text: 'Wednesday'},
      {id: '4', text: 'Thursday'},
      {id: '5', text: 'Friday'},
      {id: '6', text: 'Saturday'},
      {id: '7', text: 'Sunday'}
    ];
    this.options = {
      multiple: true,
      closeOnSelect: false,
      width: '100%',
    };
  }

  operHoursEvent(type: string, position = -1) {
    this.newOperHoursClick.emit({type: type, position: position});
  }

}
