import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {Observable} from 'rxjs';
import {
  BusinessesResponse,
  BusinessResponse,
  UserResponse, UsersResponse,
  WhiteLabelsResponse
} from '../../_models/datatable-response';
import {catchError, tap} from 'rxjs/operators';
import {Errors} from '../../_helpers/errors';
import {Business} from '../../_models/business';

@Injectable({
  providedIn: 'root'
})
export class BusinessService {

  private businessUrl = 'businesses';
  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      // privateKey: environment.privateKey
    })
  };
  private httpStatesTokenOptions = {
    headers: new HttpHeaders({
      Accept: 'application/json',
      'api-token': 'NWJM2WJkvS2MErXVEVJR-i1e1N0MA8EWLCuuU2JYa351EH0jCHwAVnLC8JfmCTCHniE',
      'user-email': 'reinier@galeforcedigital.com'
    })
  };

  constructor(private http: HttpClient) { }

  getBusiness(max: number = 20, offset: number = 0, searchVal = ''): Observable<BusinessesResponse> {
    return this.http.get<BusinessesResponse>(
      `${environment.apiUrl}` + this.businessUrl + '?max=' + max + '&offset=' + offset + '&query=' + searchVal
    ).pipe(
      tap(_ => console.log('fetched Business')),
      catchError(Errors.handleError<BusinessesResponse>('getBusiness'))
    );
  }

  getById(id: any) {
    return this.http.get<BusinessResponse>(`${environment.apiUrl}` + this.businessUrl + '/' + id).pipe(
      tap(_ => console.log('fetched business')),
      catchError(Errors.handleError<BusinessResponse>('getBusinessById'))
    );
  }

  createBusiness(business: Business) {
    return this.http.post<Business>(`${environment.apiUrl}` + this.businessUrl, business, this.httpOptions)
      .pipe(
        catchError(Errors.handleError('addBusiness', business))
      );
  }

  updateBusiness(business: Business, id: string) {
    return this.http.put<Business>(`${environment.apiUrl}` + this.businessUrl + '/' + id, business, this.httpOptions)
      .pipe(
        catchError(Errors.handleError('updateBusiness', business))
      );
  }

  deleteBusiness(id: string) {
    return this.http.delete<BusinessResponse>(`${environment.apiUrl}` + this.businessUrl + '/' + id).pipe(
      tap(_ => console.log('deleted business')),
      catchError(Errors.handleError<BusinessResponse>('deleteBusiness'))
    );
  }

  getBusinessesToExport(businessIds: any[]): Observable<BusinessesResponse> {
    let businessString = '';

    if (businessIds.length > 0) {
      businessString = 'businessIds[]=' + businessIds.join('&businessIds[]=') + '&';
    }
    return this.http.get<BusinessesResponse>(
      `${environment.apiUrl}` + this.businessUrl + '/export/data/' + ((businessString !== '') ? ('?' + businessString) : ''
      )).pipe(
      tap(_ => console.log('fetched BusinessExport')),
      catchError(Errors.handleError<BusinessesResponse>('getBusinessExport'))
    );
  }

  getStatesApiToken(): Observable<{auth_token: string}> {
    return this.http.get<{auth_token: string}>(
      'https://www.universal-tutorial.com/api/getaccesstoken', this.httpStatesTokenOptions
    ).pipe(
      tap(_ => console.log('fetched getaccesstoken')),
      catchError(Errors.handleError('getaccesstoken'))
    ) as Observable<{auth_token: string}>;
  }

  getStatesByCountryName(countryName: string, token: string): Observable<Array<{state_name: string}>> {
    const httpStatesOptions = {
      headers: new HttpHeaders({
        Accept: 'application/json',
        // @ts-ignore
        Authorization: `Bearer ${token}`,
      })
    };
    console.log(httpStatesOptions)
    return this.http.get<Observable<Array<{state_name: string}>>>(
      'https://www.universal-tutorial.com/api/states/' + countryName, httpStatesOptions
    ).pipe(
      tap(_ => console.log('fetched getStatesByCountryName')),
      catchError(Errors.handleError('getStatesByCountryName'))
    ) as Observable<Array<{state_name: string}>>;
  }
}
