export class FeedTypesData {
  private typesData = {
    SEARCH: {iconHtml: '<i class="ti-search"></i>', text: 'searches', directory: true},
    VIEW: {iconHtml: '<i class="fas fa-eye"></i>', text: 'views', directory: true},
    CLICK: {iconHtml: '<i class="fas fa-desktop"></i><i class="far fa-hand-point-up"></i>', text: 'clicks', directory: true},
    QUERIES_DIRECT: {iconHtml: '<i class="ti-shift-right"></i>', text: 'direct queries', directory: true},
    QUERIES_INDIRECT: {iconHtml: '<i class="ti-control-shuffle"></i>', text: 'indirect queries', directory: true},
    VIEWS_SEARCH: {iconHtml: '<i class="fas fa-eye"></i><i class="fab fa-google"></i>', text: 'views on Google Search.', directory: false},
    // tslint:disable-next-line:max-line-length
    ACTIONS_DRIVING_DIRECTIONS: {iconHtml: '<i class="fas fa-car"></i>', text: 'click on driving directions on Google Maps.', directory: false},
    VIEWS_MAPS: {iconHtml: '<i class="ti-map-alt"></i>', text: 'views on Google Maps.', directory: false},
    // tslint:disable-next-line:max-line-length
    QUERIES_CHAIN: {iconHtml: '<i class="ti-tag"></i><i class="far fa-hand-point-up"></i>', text: 'branded query on Google Search.', directory: false},
    // tslint:disable-next-line:max-line-length
    ACTIONS_WEBSITE: {iconHtml: '<i class="fas fa-desktop"></i><i class="far fa-hand-point-up"></i>', text: 'clicks on Website on Google Search.', directory: false},
    PHOTOS_VIEWS_MERCHANT: {iconHtml: '<i class="far fa-image"></i>', text: 'views on photo on Google Search.', directory: false},
    ACTIONS_PHONE: {iconHtml: '<i class="fas fa-phone-volume"></i>', text: 'clicks on phone on Google Search.', directory: false},
    // tslint:disable-next-line:max-line-length
    PHOTOS_VIEWS_CUSTOMERS: {iconHtml: '<i class="far fa-image"></i>', text: 'views on user-uploaded photos on Google Search.', directory: false},
    LOCAL_POST_VIEWS_SEARCH: {iconHtml: '<i class="fas fa-eye"></i><i class="fab fa-google"></i>', text: 'local views on Google Search.', directory: false},
  };
  constructor() {}

  getIconHtml(property: string) {
    return (typeof this.typesData[property] !== 'undefined') ? this.typesData[property].iconHtml : '<i class="fas fa-eye"></i>';
  }

  getText(property: string, locationsCount: number, totalActions: number, directories: Array<string>) {
    let text = (locationsCount > 1)
      ? ' and ' + (locationsCount - 1) + ' locations have ' + totalActions + ' new ' + ((typeof this.typesData[property] !== 'undefined') ? this.typesData[property].text : '')
      : ' have ' + totalActions + ' new ' + ((typeof this.typesData[property] !== 'undefined') ? this.typesData[property].text : '');
    if (this.typesData[property].directory) {
      // tslint:disable-next-line:max-line-length
      text += (directories.length > 1) ? ' on ' + directories[0] : ' on ' + directories[0] + ' and ' + (directories.length - 1) + ' other directories.';
      return text;
    } else {
      return text;
    }
  }
}
