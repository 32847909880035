import { Injectable } from '@angular/core';
import * as AWS from 'aws-sdk/global';
import * as S3 from 'aws-sdk/clients/s3';
import { environment } from 'src/environments/environment';
import { FormArray, Validators, FormBuilder } from '@angular/forms';
import {AuthenticationService} from "../authentication.service";

@Injectable({
  providedIn: 'root'
})
export class SaveImageService {

  private bucket = new S3(
    {
        accessKeyId: 'AKIAJTLOWJR72TD4F7NQ',
        secretAccessKey: '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',
        region: 'us-east-1'
    }
  );
  constructor(private fb: FormBuilder, private authService: AuthenticationService ) { }

  uploadFile(fieldName, file, metadata, load, error, progress, abort, serverId, imgType, locForm) {
    console.log(serverId);
    const contentType = file.type;
    const onlyName = file.name.split('.')[0];
    const onlytype = file.type.split('/')[1];
    const params = {
      Bucket: 'sweetiq-images',
      Key: `${environment.s3FolderName}/` + this.authService.currentUserValue.identifier + '/' + 'locationPhotos/' + onlyName + '-' + serverId + '.' + onlytype,
      Body: file,
      ACL: 'public-read',
      ContentType: contentType
    };

    this.bucket.upload(params, (err, data) => {
      if (err) {
          console.log('There was an error uploading your file: ', err);
          error('There was an error uploading your file: ', err);
          // return false;
      }
      const urlsValue = locForm.get('photos').controls.url.value;
      const typesValue = locForm.get('photos').controls.type.value;
      if (urlsValue !== '') {
        locForm.get('photos').controls.url.setValue(urlsValue + ',' + data.Location);
      } else {
        locForm.get('photos').controls.url.setValue(data.Location);
      }
      if (typesValue !== '') {
        locForm.get('photos').controls.type.setValue(typesValue + ',' + imgType);
      } else {
        locForm.get('photos').controls.type.setValue(imgType);
      }

      console.log('Successfully uploaded file.', data);
      load(data);
      // return true;
    });

    // Should call the progress method to update the progress to 100% before calling load
    // Setting computable to false switches the loading indicator to infinite mode
    // request.upload.onprogress = (e) => {
    //     progress(e.lengthComputable, e.loaded, e.total);
    // };

    // Should expose an abort method so the request can be cancelled
    return {
        abort: () => {
            // This function is entered if the user has tapped the cancel button
            // request.abort();

            // Let FilePond know the request has been cancelled
            abort();
        }
    };
  }

  deleteFile(file, imgType, locForm) {
    const onlyName = file.filename.split('.')[0];
    const onlytype = file.fileType.split('/')[1];
    const params = {
      Bucket: 'sweetiq-images',
      Key: onlyName + '-' + file.id + '.' + onlytype
    };
    this.bucket.deleteObject(params, (err, data) => {
      if (err) {
        console.log(err, err.stack);
      } else {
        console.log(data);
        const urlsValue = locForm.get('photos').controls.url.value.split(',');
        const typesValue = locForm.get('photos').controls.type.value.split(',');

        const indexDel = urlsValue.indexOf('https://sweetiq-images.s3.amazonaws.com/' + `${environment.s3FolderName}/` + this.authService.currentUserValue.identifier + '/' + 'locationPhotos/' + onlyName + '-' + file.id + '.' + onlytype);
        if (indexDel !== -1) {
          urlsValue.splice(indexDel, 1);
          typesValue.splice(indexDel, 1);
          locForm.get('photos').controls.url.setValue(urlsValue.join(','));
          locForm.get('photos').controls.type.setValue(typesValue.join(','));
        }
      }
    });
  }
}
