import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { AttributesResponse } from 'src/app/_models/datatable-response';
import { tap, catchError } from 'rxjs/operators';
import { Errors } from 'src/app/_helpers/errors';

@Injectable({
  providedIn: 'root'
})
export class AttributesService {

  private attributesUrl = 'categories';
  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      // privateKey: environment.privateKey
    })
  };
  constructor(private http: HttpClient) { }

  getAttributes(attID: number, country: string = 'US'): Observable<AttributesResponse> {
    return this.http.get<AttributesResponse>(
      `${environment.apiUrl}` + this.attributesUrl + '/' + attID + '/attributes?country=' + country
    ).pipe(
      tap(_ => console.log('fetched Attributes')),
      catchError(Errors.handleError<AttributesResponse>('getAttributes'))
    );
  }
}
