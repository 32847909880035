import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {AuthenticationService} from '../authentication.service';
import {Observable} from 'rxjs';
import {
  LocationsReportActive,
  LocationsReportBillableResponse,
  LocationsReportCCResponse,
  LocationsReportCreatedResponse
} from '../../_models/datatable-response';
import {environment} from '../../../environments/environment';
import {catchError, map, tap} from 'rxjs/operators';
import {Errors} from '../../_helpers/errors';

@Injectable({
  providedIn: 'root'
})
export class ReportsService {
  private reportsUrl = 'reports';
  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/octet-stream',
      // privateKey: environment.privateKey
    })
  };
  constructor(private http: HttpClient, private authService: AuthenticationService) { }

  getLocationsReportCreated(
    businessIds: string[],
    max: number = 20,
    offset: number = 0,
    searchVal = '',
    status = [],
    dateMin = '',
    dateMax = ''
  ): Observable<LocationsReportCreatedResponse> {
    let businessString = '';

    if (businessIds.length > 0) {
      businessString = 'businessId[]=' + businessIds.join('&businessId[]=') + '&';
    }
    let statusUrl = '';
    if (status.length > 0) {
      statusUrl = status.join('&status[]=');
    }
    return this.http.get<LocationsReportCreatedResponse>(
      `${environment.apiUrl}` + this.reportsUrl + '/locationsCreated' + (((businessString !== '') ? ('?' + businessString) : ('?'))
        + 'max=' + max + '&offset=' + offset + '&query=' + searchVal
        + ((statusUrl !== '') ? '&status[]=' + statusUrl : '')
        + '&report=1'
        + ((dateMin !== '') ? ('&dateCreatedMin=' + dateMin + ' 00:00:00' + '&dateCreatedMax=' + dateMax + ' 23:59:59') : '')
      )).pipe(
      tap(_ => console.log('fetched Locations')),
      catchError(Errors.handleError<LocationsReportCreatedResponse>('getLocations'))
    );
  }

  getLocationsReportCC(
    businessIds: string[],
    max: number = 20,
    offset: number = 0,
    searchVal = '',
    status = [],
    dateMin = '',
    dateMax = ''
  ): Observable<LocationsReportCCResponse> {
    let businessString = '';

    if (businessIds.length > 0) {
      businessString = 'businessId[]=' + businessIds.join('&businessId[]=') + '&';
    }
    let statusUrl = '';
    if (status.length > 0) {
      statusUrl = status.join('&status[]=');
    }
    return this.http.get<LocationsReportCCResponse>(
      `${environment.apiUrl}` + this.reportsUrl + '/locationsCC' + (((businessString !== '') ? ('?' + businessString) : ('?'))
        + 'max=' + max + '&offset=' + offset + '&query=' + searchVal
        + ((statusUrl !== '') ? '&status[]=' + statusUrl : '')
        + '&report=2'
        + ((dateMin !== '') ?  ('&closedDateMin=' + dateMin + ' 00:00:00' + '&closedDateMax=' + dateMax + ' 23:59:59') : '')
      )).pipe(
      tap(_ => console.log('fetched Locations')),
      catchError(Errors.handleError<LocationsReportCCResponse>('getLocations'))
    );
  }

  getLocationsReportBilled(
    businessIds: string[],
    max: number = 20,
    offset: number = 0,
    searchVal = '',
    status = [],
    dateMin = '',
    dateMax = ''
  ): Observable<LocationsReportBillableResponse> {
    let businessString = '';

    if (businessIds.length > 0) {
      businessString = 'businessId[]=' + businessIds.join('&businessId[]=') + '&';
    }
    let statusUrl = '';
    if (status.length > 0) {
      statusUrl = status.join('&status[]=');
    }
    return this.http.get<LocationsReportBillableResponse>(
      `${environment.apiUrl}` + this.reportsUrl + '/locationsBilled' + (((businessString !== '') ? ('?' + businessString) : ('?'))
        + 'max=' + max + '&offset=' + offset + '&query=' + searchVal
        + ((statusUrl !== '') ? '&status[]=' + statusUrl : '')
        + '&report=1'
        + ((dateMin !== '') ? ('&billDateMin=' + dateMin + ' 00:00:00' + '&billDateMax=' + dateMax + ' 23:59:59') : '')
      )).pipe(
      tap(_ => console.log('fetched Locations')),
      catchError(Errors.handleError<LocationsReportBillableResponse>('getLocations'))
    );
  }

  exportReport(params) {
    const httpOptionsCustom = {
      responseType: 'blob'
    };
    return this.http.post(
      // @ts-ignore
      `${environment.apiUrl}` + this.reportsUrl + '/export-report', params, httpOptionsCustom
      ).pipe(
        tap(_ => console.log('fetched Locations')),
        catchError(Errors.handleError('getLocations'))
    );
  }

  getActiveLocations(
    businessIds: string[],
    max: number = 20,
    offset: number = 0,
    searchVal = '',
    status = [],
    dateMin = '',
    dateMax = ''
  ): Observable<LocationsReportActive> {
    let businessString = '';

    if (businessIds.length > 0) {
      businessString = 'businessId[]=' + businessIds.join('&businessId[]=') + '&';
    }
    let statusUrl = '';
    if (status.length > 0) {
      statusUrl = status.join('&status[]=');
    }
    return this.http.get<LocationsReportActive>(
      `${environment.apiUrl}` + this.reportsUrl + '/locationsActive' + (((businessString !== '') ? ('?' + businessString) : ('?'))
        + 'max=' + max + '&offset=' + offset + '&query=' + searchVal
        + ((statusUrl !== '') ? '&status[]=' + statusUrl : '')
        + '&report=1'
        + ((dateMin !== '') ? ('&activeDateMin=' + dateMin + ' 00:00:00' + '&activeDateMax=' + dateMax + ' 23:59:59') : '')
      )).pipe(
      tap(_ => console.log('fetched Active Locations')),
      catchError(Errors.handleError<LocationsReportActive>('getLocations'))
    );
  }

  getInvoices(
    businessIds: string[],
    max: number = 20,
    offset: number = 0,
    searchVal = '',
    status = [],
    dateMin = '',
    dateMax = ''
  ) {
    let businessString = '';
    if (businessIds.length > 0) {
      businessString = 'businessId[]=' + businessIds.join('&businessId[]=') + '&';
    }
    let statusUrl = '';
    if (status.length > 0) {
      statusUrl = status.join('&status[]=');
    }
    return this.http.get<any>(
      `${environment.apiUrl}` + this.reportsUrl + '/invoices' + (((businessString !== '') ? ('?' + businessString) : ('?'))
        + ((dateMin !== '') ? ('&from=' + dateMin + ' 00:00:00' + '&to=' + dateMax + ' 23:59:59') : '')
      )).pipe(
      tap(_ => console.log('fetched Invoice')),
      catchError(Errors.handleError<any>('getInvoice'))
    );
  }

  createInvoice(
    businessId: string[],
    dateMin = '',
    dateMax = ''
  ) {
    return this.http.post<any>(
      `${environment.apiUrl}` + this.reportsUrl + '/invoice', {businessId, dateMin: dateMin + ' 00:00:00', dateMax: dateMax + ' 23:59:59'}).pipe(
      tap(_ => console.log('fetched Invoice')),
      catchError(Errors.handleError<any>('getInvoice'))
    );
  }
}
