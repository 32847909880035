import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { CategoriesResponse } from 'src/app/_models/datatable-response';
import { tap, catchError } from 'rxjs/operators';
import { Errors } from 'src/app/_helpers/errors';

@Injectable({
  providedIn: 'root'
})
export class CategoriesService {

  private categoriesUrl = 'categories';
  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      // privateKey: environment.privateKey
    }),
    params: new HttpParams()
  };
  constructor(private http: HttpClient) { }

  getCategories(max: number = 20, offset: number = 0, searchVal = ''): Observable<CategoriesResponse> {
    return this.http.get<CategoriesResponse>(
      `${environment.apiUrl}` + this.categoriesUrl + '?language=en' + 'max=' + max + '&offset=' + offset + '&q=' + searchVal
    , this.httpOptions).pipe(
      tap(_ => console.log('fetched Categories')),
      catchError(Errors.handleError<CategoriesResponse>('getCategories'))
    );
  }

  getCategoriesById(categoriesIds: any[]): Observable<CategoriesResponse> {
    let params = '';
    for (let id of categoriesIds) {
      params += '&categories=' + String(id);
    }
    return this.http.get<CategoriesResponse>(
      `${environment.apiUrl}` + this.categoriesUrl + '?language=en' + params
    ).pipe(
      tap(_ => console.log('fetched Categories')),
      catchError(Errors.handleError<CategoriesResponse>('getCategories'))
    );
  }
}
