import {Component, ComponentFactoryResolver, Input, OnInit} from '@angular/core';
import {Select2OptionData} from 'ng-select2';
import {Observable} from 'rxjs';
import {LocationService} from '../_services/location/location.service';
import {FormBuilder, FormGroup} from '@angular/forms';
import {environment} from '../../environments/environment';
import {Role, User} from '../_models';
import {AuthenticationService} from '../_services';
import {Location} from '../_models/location';

@Component({
  selector: 'app-manage-account',
  templateUrl: './manage-account.component.html',
  styleUrls: ['./manage-account.component.scss']
})
export class ManageAccountComponent implements OnInit {
  @Input() accountId: number;
  @Input() accountName: string;
  @Input() role: Role;
  @Input() newUserForm: FormGroup;
  @Input() currentLocations: Array<Location>;
  locations: Observable<Array<Select2OptionData>>;
  public options: any;
  user: User;
  locationsText: Array<string>;
  locationsData: Array<Select2OptionData>;

  constructor(
    private locationService: LocationService,
    private componentFactoryResolver: ComponentFactoryResolver,
    private authService: AuthenticationService,
  ) { }

  ngOnInit() {
    this.user = this.authService.currentUserValue;

    const locTexts: Array<string> = [];
    const locDatas: Array<Select2OptionData> = [];
    this.locationsData = [];
    this.locationsText = [];
    if (this.currentLocations !== null) {
      for (const loc in this.currentLocations) {
        if (String(this.currentLocations[loc].businessId) === String(this.accountId)) {
          this.locationsData.push({
            id: String(this.currentLocations[loc].identifier),
            // tslint:disable-next-line:max-line-length
            text: this.currentLocations[loc].name + ' - ' + this.currentLocations[loc].streetNo + ' ' + this.currentLocations[loc].street + ', ' + this.currentLocations[loc].city + ', ' + this.currentLocations[loc].country
          });
          locTexts.push(String(this.currentLocations[loc].identifier));
        }
      }
    }
    setTimeout(() => { this.locationsText = locTexts; }, 100);
    // this.locationsData = Observable.create((obs) => {
    //   obs.next(locDatas);
    //   obs.complete();
    // });
    // this.locationsText = Observable.create((obs) => {
    //   obs.next(locTexts);
    //   obs.complete();
    // });

    this.options = {
      multiple: true,
      closeOnSelect: true,
      width: '100%',
      ajax: {
        headers: {
          Authorization : 'Bearer ' + this.user.token,
          'Content-Type' : 'application/json',
        },
        url: (params: any) => {
          if (typeof params.term !== 'undefined') {
            return `${environment.apiUrl}` + 'locations?businessId=' + this.accountId + '&max=' + 20 + '&offset=' + 0 + '&query=' + params.term;
          } else {
            return `${environment.apiUrl}` + 'locations?businessId=' + this.accountId + '&max=' + 20 + '&offset=' + 0;
          }
        },
        processResults: (locations: any, params: any) => {
          console.log('Process Result: ', locations, params);
          const allLocations = locations.response.locations;
          const list: Array<Select2OptionData> = [];
          for (let loc of allLocations) {
              list.push({
                id: String(loc.identifier),
                text: loc.name + ' - ' + loc.streetNo + ' ' + loc.street + ', ' + loc.city + ', ' + loc.country
              });
          }
          return {
            results: list
          };
        }
      }
    };
    // this.getLocations();
  }

  valueLocationsChanged(event: any) {
    console.log('REAL LOC VALUES: ', this.newUserForm.get('realLocations').value);
    let locations = [];
    if ( this.newUserForm.get('realLocations').value !== null ) {
      locations = this.newUserForm.get('realLocations').value;
    }
    if (typeof event.data !== 'undefined' && event.data.length > 0) {
      for (let location of event.data) {
        if (locations.indexOf(location.id) === -1) {
          locations.push(location.id);
        }
      }
    }
    this.newUserForm.get('realLocations').setValue(locations);
  }

  // getLocations(): void {
  //   this.locationService.getLocations(this.accountId).subscribe(locations => {
  //     const allLocations = locations.response.locations;
  //     const list: Array<Select2OptionData> = [];
  //     for (let loc of allLocations) {
  //       list.push({id: String(loc.id), text: loc.name + ' - ' + loc.streetNo + ' ' + loc.street + ', ' + loc.city + ', ' + loc.country});
  //     }
  //     this.locations =  Observable.create((obs) => {
  //       obs.next(list);
  //       obs.complete();
  //     });
  //   });
  // }

  // valueChanged(event: any) {
  //   console.log(event, this.locationsValue);
  //   if (typeof this.locationsValue !== 'undefined') {
  //     for (let location of event.data) {
  //       const componentFactory = this.componentFactoryResolver.resolveComponentFactory(ManageAccountComponent);
  //       const viewContainerRef = this.accMan.viewContainerRef;
  //       // viewContainerRef.clear();
  //       const componentRef = viewContainerRef.createComponent(componentFactory);
  //       componentRef.instance.accountId = account.id;
  //       componentRef.instance.accountName = account.text;
  //     }
  //   }
  // }
}
