import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AuthGuard } from './_helpers';
import {LoginComponent} from './login/login.component';
import {UsersComponent} from './users/users.component';
import {CreateUserComponent} from './create-user/create-user.component';
import {BusinessesComponent} from './businesses/businesses.component';
import {CreateBusinessComponent} from './create-business/create-business.component';
import {LocationsComponent} from './locations/locations.component';
import {CreateLocationComponent} from './create-location/create-location.component';
import {PasswordComponent} from './password/password.component';
import {PasswordNewComponent} from './password-new/password-new.component';
import {Role} from './_models';
import {ReportsComponent} from './reports/reports.component';
import {BilledReportComponent} from "./billed-report/billed-report.component";
import {ManagementReportComponent} from "./management-report/management-report.component";


const routes: Routes = [
  { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard], data: { breadcrumb: 'Dashboard' } },
  { path: 'login', component: LoginComponent },
  { path: 'password/reset', component: PasswordComponent },
  { path: 'password/find/:token', component: PasswordNewComponent },
  {
    path: 'users',
    component: UsersComponent,
    canActivate: [AuthGuard],
    data: {
      roles: [Role.Admin, Role.AccountManager, Role.BusinessManager],
      breadcrumb: 'Users'
    }
  },
  {
    path: 'create-user',
    component: CreateUserComponent,
    canActivate: [AuthGuard],
    data: {
      roles: [Role.Admin, Role.AccountManager, Role.BusinessManager],
      breadcrumb: 'Create User'
    }
  },
  {
    path: 'edit-user/:id',
    component: CreateUserComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.Admin, Role.AccountManager, Role.BusinessManager],
      breadcrumb: 'Edit user'
    }
  },
  {
    path: 'accounts',
    component: BusinessesComponent,
    canActivate: [AuthGuard],
    data: {
      roles: [Role.Admin, Role.AccountManager, Role.BusinessManager],
      breadcrumb: 'Businesses'
    }
  },
  {
    path: 'create-account',
    component: CreateBusinessComponent,
    canActivate: [AuthGuard],
    data: {
      roles: [Role.Admin, Role.AccountManager],
      breadcrumb: 'Create Business'
    }
  },
  {
    path: 'edit-account/:id',
    component: CreateBusinessComponent,
    canActivate: [AuthGuard],
    data: {
      roles: [Role.Admin, Role.AccountManager, Role.BusinessManager],
      breadcrumb: 'Edit Business'
    }
  },
  {
    path: 'locations',
    component: LocationsComponent,
    canActivate: [AuthGuard],
    data: {
      /*roles: [Role.Admin, Role.AccountManager, Role.BusinessManager],*/
      breadcrumb: 'Locations'
    }
  },
  {
    path: 'locations/:businessId',
    component: LocationsComponent,
    canActivate: [AuthGuard],
    data: {
      /*roles: [Role.Admin, Role.AccountManager, Role.BusinessManager],*/
      breadcrumb: 'Locations'
    }
  },
  {
    path: 'create-location/:businessId',
    component: CreateLocationComponent,
    canActivate: [AuthGuard],
    data: {
      roles: [Role.Admin, Role.AccountManager, Role.BusinessManager],
      breadcrumb: 'Create Location'
    }
  },
  {
    path: 'create-location',
    component: CreateLocationComponent,
    canActivate: [AuthGuard],
    data: {
      roles: [Role.Admin, Role.AccountManager, Role.BusinessManager],
      breadcrumb: 'Create Location'
    }
  },
  {
    path: 'edit-location/:id',
    component: CreateLocationComponent,
    canActivate: [AuthGuard],
    data: {
      /*roles: [Role.Admin, Role.AccountManager],*/
      breadcrumb: 'Edit Location'
    }
  },
  { path: '', redirectTo: '/login', pathMatch: 'full' },
  { path: 'reports/general', component: ReportsComponent, canActivate: [AuthGuard], data: { breadcrumb: 'Reports' } },
  { path: 'reports/billable', component: BilledReportComponent, canActivate: [AuthGuard], data: { breadcrumb: 'Reports' } },
  { path: 'reports/billable', component: BilledReportComponent, canActivate: [AuthGuard], data: { breadcrumb: 'Reports' } },
  { path: 'reports/local-search-management-report', component: ManagementReportComponent, canActivate: [AuthGuard], data: { breadcrumb: 'Reports' } },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
