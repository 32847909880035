import {
  AfterViewInit,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren
} from '@angular/core';
import {BreadcrumbsService} from 'ng6-breadcrumbs';
import {DataTableDirective} from 'angular-datatables';
import {Observable, Subject} from 'rxjs';
import {Location} from '../_models/location';
import * as moment from 'moment';
import {Select2OptionData} from 'ng-select2';
import {LocationService} from '../_services/location/location.service';
import {AlertService} from '../_services/alert.service';
import {ActivatedRoute, Router} from '@angular/router';
import {BusinessService} from '../_services/business/business.service';
import {ngxCsv} from 'ngx-csv';
import {ReportsService} from '../_services/reports/reports.service';
import {BaseChartDirective, Label, MultiDataSet, ThemeService} from 'ng2-charts';
import {ChartDataSets} from 'chart.js';
import {ModalDismissReasons, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {TrackingDataNew, TrackingDataOld} from '../_models/datatable-response';
import {Business} from '../_models/business';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.sass']
})
export class ReportsComponent implements AfterViewInit, OnDestroy, OnInit {

  dtOptionsCreated: DataTables.Settings = {};
  dtOptionsCC: DataTables.Settings = {};
  dtOptionsActive: DataTables.Settings = {};
  // @ts-ignore
  @ViewChildren(DataTableDirective) dtElements: QueryList;
  // @ts-ignore
  dtTriggerCreated: Subject = new Subject();
  // @ts-ignore
  dtTriggerClosed: Subject = new Subject();
  // @ts-ignore
  dtTriggerActive: Subject = new Subject();
  locationsCreated: Location[];
  locationsCC: Location[];
  checkList: Array<any> = [];
  allFlag = false;
  public currentBusiness = [];
  public daterange: any = {start: moment().subtract(1, 'year'), end: moment()};
  public daterangeCreated: any = {start: moment().subtract(1, 'year'), end: moment()};
  status = '';
  selectedAccount = '';
  businesses: Observable<Array<Select2OptionData>>;
  public optionsBusinesses: any;
  public optionsDateRange: any = {
    autoUpdateInput: false,
    locale: { format: 'MM/DD/YYYY' },
    alwaysShowCalendars: false,
    maxDate: moment().toDate(),
    ranges: {
      'This Year': [moment().startOf('year'), moment().endOf('year')],
      'Last Year': [moment().subtract(1, 'year'), moment()],
      'This Quarter': [moment().startOf('quarter'), moment().endOf('quarter')],
      'Last Quarter': [moment().subtract(1, 'quarter').startOf('quarter'), moment().subtract(1, 'quarter').endOf('quarter')],
      'This Month': [moment().startOf('month'), moment().endOf('month')],
      'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
      'This Week': [moment().startOf('week'), moment().endOf('week')],
      'Last Week': [moment().subtract(1, 'week').startOf('week'), moment().subtract(1, 'week').endOf('week')],
      Today: [moment(), moment()],
    }
  };
  dateValue = '';
  dateValueCreated = '';
  statuses = [
    {id: '', text: ''},
    {id: 'ACTIVE', text: 'Active'},
    {id: 'INACTIVE', text: 'Inactive'},
    {id: 'CLOSED', text: 'Closed'},
    {id: 'CANCELLED', text: 'Cancelled'}
  ];
  createdClosedData: MultiDataSet = [];
  createdClosedLabels: Label[] = ['Created Locations', 'Deactivated Locations'];
  loading = false;
  optionsData = {
    onResize: (chart, size) => { chart.options.legend.display = (size.width >= 250); },
    legend: {display: true, align: 'center', position: 'right', labels: {boxWidth: 10, usePointStyle: true, fontSize: 11, }},
    tooltips: {
      callbacks: {
        label: (tooltipItem, data) => {
          const label = data.labels[tooltipItem.index] + ': ' + data.datasets[0].data.reduce((a, b) => a + b) || '';
          return label;
        }
      }
    }
  };
  optionsBlank = {tooltips: {enabled: false}, legend: {display: true, align: 'center', position: 'bottom'}};
  timeRangeChartData: ChartDataSets[] = [{data: [], label: 'Created Locations'}, {data: [], label: 'Deactivated Locations'}];
  timeRangeChartLabels: Label[] = [];
  monthlyDataCreated = null;
  monthlyDataClosed = null;
  createdAccountData: MultiDataSet = [];
  createdAccountLabels: Label[] = [];
  closedAccountData: MultiDataSet = [];
  closedAccountLabels: Label[] = [];
  businessesNames = {};
  public colors: { backgroundColor: string[] }[];
  // @ts-ignore
  @ViewChild(BaseChartDirective) private chart;
  // @ts-ignore
  @ViewChild('createdClosedChart') createdClosedChart: ElementRef;
  // @ts-ignore
  @ViewChild('closedAccountChart') closedAccountChart: ElementRef;
  // @ts-ignore
  @ViewChild('createdAccountChart') createdAccountChart: ElementRef;
  // @ts-ignore
  @ViewChild('timeRangeChart') timeRangeChart: ElementRef;
  reportGenerating = false;
  public optionsStatus: any;
  closeResult: string;
  trackData: Observable<Array<TrackingDataNew>>;
  public locationsActive: Array<Location> = [];
  public allBusinesses: Business[] = [];

  constructor(
    public locationService: LocationService,
    public breadcrumbsService: BreadcrumbsService,
    public alertService: AlertService,
    public elementRef: ElementRef,
    public router: Router,
    public route: ActivatedRoute,
    public businessService: BusinessService,
    public reportsService: ReportsService,
    public modalService: NgbModal
  ) { }

  ngOnInit() {
    this.colors = [{backgroundColor: [
        '#fb9678',
        '#03a9f3',
        '#01c0c8',
        '#ab8ce4',
        '#e46a76',
        '#fec107'
      ]}];
    this.optionsBusinesses = {
      multiple: true,
      closeOnSelect: false,
      width: '100%'
    };
    this.optionsStatus = {
      multiple: false,
      closeOnSelect: true,
      width: '100%'
    };
    this.breadcrumbsService.store([
      {label: 'Dashboard' , url: '/dashboard', params: []},
      {label: 'General Reports' , url: '/reports/general', params: []}
    ]);
    this.dtOptionsCreated = {
      pagingType: 'full_numbers',
      pageLength: 10,
      serverSide: true,
      processing: true,
      ajax: (dataTablesParameters: any, callback) => {
        if (Object.values(this.businessesNames).length > 0) {
          this.getLocationsCreated(dataTablesParameters.length, dataTablesParameters.start, dataTablesParameters.search.value, callback);
        } else {
          setTimeout(() => {
            this.getLocationsCreated(dataTablesParameters.length, dataTablesParameters.start, dataTablesParameters.search.value, callback);
          }, 1000);
        }
      },
      columns: [
        { data: 'name' },
        { data: 'streetAndNumber' },
        { data: 'endDate' },
        { data: 'publishedListingsCount' },
        { data: 'profileCompleteness' },
        { data: 'lastSyncStarted' },
        { data: 'dateCreated' }
      ],
      language: {
        // tslint:disable-next-line:max-line-length
        processing: '<div class="spinning"><div class="spinner"><div class="bounce1"></div><div class="bounce2"></div><div class="bounce3"></div></div></div>'
      }
    };
    this.dtOptionsCC = {
      pagingType: 'full_numbers',
      pageLength: 10,
      serverSide: true,
      processing: true,
      ajax: (dataTablesParameters: any, callback) => {
        if (Object.values(this.businessesNames).length > 0) {
          this.getLocationsCC(dataTablesParameters.length, dataTablesParameters.start, dataTablesParameters.search.value, callback);
        } else {
          setTimeout(() => {
            this.getLocationsCC(dataTablesParameters.length, dataTablesParameters.start, dataTablesParameters.search.value, callback);
          }, 1000);
        }
      },
      columns: [
        { data: 'name' },
        { data: 'streetAndNumber' },
        { data: 'endDate' },
        { data: 'publishedListingsCount' },
        { data: 'profileCompleteness' },
        { data: 'lastSyncStarted' },
        { data: 'endDate' },
        {data: 'identifier'}
      ],
      language: {
        // tslint:disable-next-line:max-line-length
        processing: '<div class="spinning"><div class="spinner"><div class="bounce1"></div><div class="bounce2"></div><div class="bounce3"></div></div></div>'
      }
    };
    this.dtOptionsActive = {
      pagingType: 'full_numbers',
      pageLength: 10,
      serverSide: true,
      processing: true,
      ajax: (dataTablesParameters: any, callback) => {
        if (Object.values(this.businessesNames).length > 0) {
          console.log('EXECUTED');
          this.getActiveLocations(dataTablesParameters.length, dataTablesParameters.start, dataTablesParameters.search.value, callback);
        } else {
          setTimeout(() => {
            console.log('EXECUTED');
            this.getActiveLocations(dataTablesParameters.length, dataTablesParameters.start, dataTablesParameters.search.value, callback);
          }, 1000);
        }
      },
      columns: [
        { data: 'name' },
        { data: 'streetAndNumber' },
        { data: 'endDate' },
        { data: 'publishedListingsCount' },
        { data: 'profileCompleteness' },
        { data: 'lastSyncStarted' },
        { data: 'dateCreated' }
      ],
      language: {
        // tslint:disable-next-line:max-line-length
        processing: '<div class="spinning"><div class="spinner"><div class="bounce1"></div><div class="bounce2"></div><div class="bounce3"></div></div></div>'
      }
    };
    this.trackData =  Observable.create((obs) => {
      obs.next([]);
      obs.complete();
    });
    this.getBusinesses();
  }

  getBusinesses(): void {
    this.businessService.getBusiness().subscribe(
      businesses => {
        this.allBusinesses = businesses.response.businesses;
        const list: Array<Select2OptionData> = [];
        for (const bus of this.allBusinesses) {
          list.push({id: String(bus.identifier), text: bus.name});
          this.businessesNames[bus.identifier] = bus.name;
        }
        this.businesses =  Observable.create((obs) => {
          obs.next(list);
          obs.complete();
        });
      }
    );
  }

  /**
   * Get the list of existent users.
   */
  getLocationsCreated(max: number = 20, offset: number = 0, searchVal = '', callBackData): void {
    if (!this.loading) {
      this.loading = true;
    }
    this.reportsService.getLocationsReportCreated(
      this.currentBusiness, max, offset, searchVal,
      [this.status], (this.dateValue !== '') ? this.daterange.start.format('YYYY-MM-DD') : '',
      (this.dateValue !== '') ? this.daterange.end.format('YYYY-MM-DD') : ''
    )
      .subscribe(response => {
        this.locationsCreated = response.response.locationsCreated;
        this.monthlyDataCreated = response.response.monthlyData;
        this.combineLineData('created');
        this.createdAccountData = [];
        this.createdAccountLabels.length = 0;
        const labels = [];
        for (const data of Object.keys(response.response.accountData)) {
          this.createdAccountData.push(response.response.accountData[data]);
          labels.push(this.businessesNames[data] + ': ' + response.response.accountData[data]);
        }
        this.createdAccountLabels = labels;
        callBackData({
          recordsTotal: response.response.max,
          recordsFiltered: response.response.count,
          data: []// response.response.users
        });
        // this.loading = false;
      }, (error) => { console.log(error); this.loading = false; });
  }

  getLocationsCC(max: number = 20, offset: number = 0, searchVal = '', callBackData): void {
    if (!this.loading) {
      this.loading = true;
    }
    this.reportsService.getLocationsReportCC(
      this.currentBusiness, max, offset, searchVal,
      [this.status], (this.dateValue !== '') ? this.daterange.start.format('YYYY-MM-DD') : '',
      (this.dateValue !== '') ? this.daterange.end.format('YYYY-MM-DD') : ''
    )
      .subscribe(response => {
        this.locationsCC = response.response.locationsCC;
        this.monthlyDataClosed = response.response.monthlyData;
        this.combineLineData('closed');
        this.closedAccountData = [];
        this.closedAccountLabels.length = 0;
        const labels = [];
        for (const data of Object.keys(response.response.accountData)) {
          this.closedAccountData.push(response.response.accountData[data]);
          labels.push(this.businessesNames[data] + ': ' + response.response.accountData[data]);
        }
        this.closedAccountLabels = labels;
        callBackData({
          recordsTotal: response.response.max,
          recordsFiltered: response.response.count,
          data: []// response.response.users
        });
        // this.loading = false;
      }, (error) => { console.log(error); this.loading = false; });
  }

  getActiveLocations(max: number = 20, offset: number = 0, searchVal = '', callBackData): void {
    if (!this.loading) {
      this.loading = true;
    }
    console.log(this.currentBusiness);
    this.reportsService.getActiveLocations(
      this.currentBusiness, max, offset, searchVal,
      [this.status], (this.dateValue !== '') ? this.daterange.start.format('YYYY-MM-DD') : '',
      (this.dateValue !== '') ? this.daterange.end.format('YYYY-MM-DD') : ''
    )
      .subscribe(response => {
        this.locationsActive = response.response.locationsActive;
        this.createdClosedData = [];
        this.createdClosedLabels.length = 0;
        const labels = [];
        for (const data of Object.keys(response.response.accountData)) {
          this.createdClosedData.push(response.response.accountData[data]);
          labels.push(this.businessesNames[data] + ': ' + response.response.accountData[data]);
        }
        this.createdClosedLabels = labels;
        callBackData({
          recordsTotal: response.response.max,
          recordsFiltered: response.response.count,
          data: []// response.response.users
        });
        // this.loading = false;
      }, (error) => { console.log(error); this.loading = false; });
  }

  public combineLineData(type) {
    if ((type === 'created' && this.monthlyDataClosed !== null) || (type === 'closed' && this.monthlyDataCreated !== null)) {
      let labels = Object.keys(this.monthlyDataClosed).concat(Object.keys(this.monthlyDataCreated)).sort((a, b) => {
        return (moment(a).isBefore(moment(b)) ? -1 : 1);
      });
      let createdTotal = 0;
      let closedTotal = 0;
      labels = [... new Set(labels)];
      const totalLabels = labels.length;
      this.timeRangeChartLabels.length = 0;
      this.timeRangeChartData = [{data: [], label: 'Created Locations'}, {data: [], label: 'Deactivated Locations'}];
      if (totalLabels === 1) {
        this.timeRangeChartData = [{data: [0], label: 'Created Locations'}, {data: [0], label: 'Deactivated Locations'}];
      }
      for (const label of labels) {
        createdTotal += (this.monthlyDataCreated.hasOwnProperty(label)) ? this.monthlyDataCreated[label] : 0;
        closedTotal += (this.monthlyDataClosed.hasOwnProperty(label)) ? this.monthlyDataClosed[label] : 0;
        this.timeRangeChartData[0].data.push((this.monthlyDataCreated.hasOwnProperty(label)) ? this.monthlyDataCreated[label] : 0);
        this.timeRangeChartData[1].data.push((this.monthlyDataClosed.hasOwnProperty(label)) ? this.monthlyDataClosed[label] : 0);
      }
      if (totalLabels === 1) {
        this.timeRangeChartData[0].data.push(0);
        this.timeRangeChartData[1].data.push(0);
        labels = ['', ... new Set(labels), ''];
      }
      this.timeRangeChartLabels = labels;
      // @ts-ignore
      // this.createdClosedData = [createdTotal, closedTotal];
      // this.createdClosedLabels = ['Created Locations: ' + createdTotal, 'Deactivated Locations: ' + closedTotal];
      this.loading = false;
    }
  }

  /**
   * Format date fields
   */
  formatDate(date) {
    return (date !== '' && date !== null) ? new Date(date).toLocaleDateString() : '';
  }

  ngAfterViewInit(): void {
    this.dtTriggerCreated.next();
    this.dtTriggerClosed.next();
    this.dtTriggerActive.next();
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTriggerCreated.unsubscribe();
    this.dtTriggerClosed.unsubscribe();
    this.dtTriggerActive.unsubscribe();
  }

  rerender(): void {
    console.log('CALLING RERENDERER');
    this.dtElements.forEach((dtElement: DataTableDirective, index: number) => {
      dtElement.dtInstance.then((dtInstance: any) => {
        dtInstance.destroy();
      });
    });
    this.dtTriggerCreated.next();
    this.dtTriggerClosed.next();
    this.dtTriggerActive.next();
    // this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
    //   console.log('TABLES SUB: ', dtInstance.tables());
    //   // Destroy the table first
    //   dtInstance.destroy();
    //   // Call the dtTrigger to rerender again
    //   this.dtTriggerCreated.next();
    //   this.dtTriggerClosed.next();
    // });
  }

  exportData() {
    this.reportGenerating = true;
    // console.log(this.createdAccountChart.nativeElement.getContext('2d').canvas.toDataURL());
    const newClosedAccountChartImg = this.closedAccountChart.nativeElement.getContext('2d').canvas.toDataURL('image/png');
    const newCreatedAccountChart = this.createdAccountChart.nativeElement.getContext('2d').canvas.toDataURL('image/png');
    const newCreatedClosedChart = this.createdClosedChart.nativeElement.getContext('2d').canvas.toDataURL('image/png');
    const newTimeRangeChart = this.timeRangeChart.nativeElement.getContext('2d').canvas.toDataURL('image/png');

    const exportParams = {
      newClosedAccountChartImg,
      newCreatedAccountChart,
      newCreatedClosedChart,
      newTimeRangeChart,
      businessId: this.currentBusiness,
      status: this.status,
      dateMin: (this.dateValue !== '') ? this.daterange.start.format('YYYY-MM-DD')  + ' 00:00:00' : '',
      dateMax: (this.dateValue !== '') ? this.daterange.end.format('YYYY-MM-DD')  + ' 23:59:59' : ''
    };

    this.reportsService.exportReport(exportParams).subscribe(
      (response) => {
        if (response) {
          const url = window.URL.createObjectURL(new Blob([response]));

          // Debe haber una manera mejor de hacer esto...
          const a = document.createElement('a');
          document.body.appendChild(a);
          a.setAttribute('style', 'display: none');
          a.href = url;
          a.download = (this.dateValue !== '') ?
            'LocationsReport - (' + this.daterange.start.format('MM/DD/YYYY') + ' - ' + this.daterange.end.format('MM/DD/YYYY') + ').xls' :
            'LocationsReport - (ALL DATES).xls';
          a.click();
          window.URL.revokeObjectURL(url);
          a.remove(); // remove the element
          // saveAs(myBlob, 'SampleExcel.xlsx');
          this.reportGenerating = false;
        }
      },
      (error) => { console.log(error, 'exportReport'); this.reportGenerating = false; }
    );
  }

  percentCalc(total, minor) {
    return parseFloat('' + ((minor * 100) / total)).toFixed(0);
  }

  changeBusiness(event) {
    console.log(event);
    if (event.value.length > 0) {
      this.currentBusiness = event.value;
    } else {
      this.currentBusiness = [];
    }
    this.rerender();
    // this.getLocations(event.value);
  }

  changeStatus(event) {
    if (event.value.length > 0) {
      this.status = event.value;
    }
    this.rerender();
  }

  selectedDate(value: any, datepicker?: any) {
    // this is the date the iser selected
    console.log(value, datepicker);

    // any object can be passed to the selected event and it will be passed back here
    datepicker.start = value.start;
    datepicker.end = value.end;

    // or manupulat your own internal property
    this.daterange.start = value.start;
    this.daterange.end = value.end;
    this.daterange.label = value.label;
    this.dateValue = this.daterange.start.format('MM/DD/YYYY') + ' - ' + this.daterange.end.format('MM/DD/YYYY');
    this.rerender();
  }

  cancelEndDates() {
    this.dateValue = '';
    this.rerender();
  }

  selectedCreatedDate(value: any, datepicker?: any) {
    // this is the date the iser selected
    console.log(value, datepicker);

    // any object can be passed to the selected event and it will be passed back here
    datepicker.start = value.start;
    datepicker.end = value.end;

    // or manupulat your own internal property
    this.daterangeCreated.start = value.start;
    this.daterangeCreated.end = value.end;
    this.daterangeCreated.label = value.label;
    this.dateValueCreated = this.daterangeCreated.start.format('MM/DD/YYYY') + ' - ' + this.daterangeCreated.end.format('MM/DD/YYYY');
    this.rerender();
  }

  cancelCreatedDates() {
    this.dateValueCreated = '';
    this.rerender();
  }

  open(content, identifier) {
    const trackDataFound = this.locationsCC.find((item) => item.identifier === identifier);
    this.trackData =  Observable.create((obs) => {
      // @ts-ignore
      obs.next(trackDataFound.events);
      obs.complete();
    });
    this.modalService.open(content, { centered: true, size: 'lg' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    });
  }

  getBusiness(identifier: string) {
    return this.allBusinesses.find(item => item.identifier === String(identifier));
  }
}
