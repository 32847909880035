import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { Errors } from 'src/app/_helpers/errors';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {
  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      // privateKey: environment.privateKey
    })
  };
  constructor(private http: HttpClient) { }

  getListingHealth(businessIds: any[] = [], locationIds: any[] = [], directory: string = ''): Observable<any> {
    if (locationIds.length > 0) {
      businessIds = [];
    }
    const queryString = '?' + ((businessIds.length > 0) ? 'businessIds[]=' + businessIds.join('&businessIds[]=') + '&' : '')
                      + ((locationIds.length > 0) ? 'locationIds[]=' + locationIds.join('&locationIds[]=') : '');
    return this.http.get<any>(
      `${environment.apiUrl}` + 'dashboard/listingHealth' + queryString
      ).pipe(
          tap(_ => console.log('fetched listingHealth')),
          catchError(Errors.handleError<any>('getListingHealth'))
        );
  }

  getProfileCompleteness(businessIds: any[] = [], locationIds: any[] = []): Observable<any> {
    if (locationIds.length > 0) {
      businessIds = [];
    }
    const queryString = '?' + ((businessIds.length > 0) ? 'businessIds[]=' + businessIds.join('&businessIds[]=') + '&' : '')
                      + ((locationIds.length > 0) ? 'locationIds[]=' + locationIds.join('&locationIds[]=') : '');
    return this.http.get<any>(
      `${environment.apiUrl}` + 'dashboard/profileCompleteness' + queryString
      ).pipe(
          tap(_ => console.log('fetched ProfileCompleteness')),
          catchError(Errors.handleError<any>('getProfileCompleteness'))
        );
  }
  getLocationStatistics(businessIds: any[] = [], locationIds: any[] = []): Observable<any> {
    if (locationIds.length > 0) {
      businessIds = [];
    }
    const queryString = '?' + ((businessIds.length > 0) ? 'businessIds[]=' + businessIds.join('&businessIds[]=') + '&' : '')
                      + ((locationIds.length > 0) ? 'locationIds[]=' + locationIds.join('&locationIds[]=') : '');
    return this.http.get<any>(
      `${environment.apiUrl}` + 'dashboard/profileCompleteness' + queryString
      ).pipe(
          tap(_ => console.log('fetched ProfileCompleteness')),
          catchError(Errors.handleError<any>('getProfileCompleteness'))
        );
  }
  getInsightsDataWarnings(businessIds: any[] = [], locationIds: any[] = [], directory: string = ''): Observable<any> {
    if (locationIds.length > 0) {
      businessIds = [];
    }
    const queryString = '?' + ((businessIds.length > 0) ? 'businessIds[]=' + businessIds.join('&businessIds[]=') + '&' : '')
                      + ((locationIds.length > 0) ? 'locationIds[]=' + locationIds.join('&locationIds[]=') : '') +
                      ((directory !== '') ? '&type=' + directory : '');
    return this.http.get<any>(
      `${environment.apiUrl}` + 'dashboard/insightsData/warnings' + queryString
      ).pipe(
          tap(_ => console.log('fetched InsightsDataWarnings')),
          catchError(Errors.handleError<any>('getInsightsDataWarnings'))
        );
  }

  getSuppressedDuplicates(businessIds: any[] = [], locationIds: any[] = []): Observable<any> {
    if (locationIds.length > 0) {
      businessIds = [];
    }
    const queryString = '?' + ((businessIds.length > 0) ? 'businessIds[]=' + businessIds.join('&businessIds[]=') + '&' : '')
      + ((locationIds.length > 0) ? 'locationIds[]=' + locationIds.join('&locationIds[]=') : '');
    return this.http.get<any>(
      `${environment.apiUrl}` + 'dashboard/suppressedDuplicates' + queryString
    ).pipe(
      tap(_ => console.log('fetched SuppressedDuplicates')),
      catchError(Errors.handleError<any>('getSuppressedDuplicates'))
    );
  }

  getInsightsData(startDate, endDate, group, type, metrics, businessIds: any[] = [], locationIds: any[] = []): Observable<any> {
    if (locationIds.length > 0) {
      businessIds = [];
    }
    const queryString = '?' + ((businessIds.length > 0) ? 'businessIds[]=' + businessIds.join('&businessIds[]=') + '&' : '')
      + ((locationIds.length > 0) ? 'locationIds[]=' + locationIds.join('&locationIds[]=') + '&' : '')
      + 'startDate=' + startDate
      + '&endDate=' + endDate
      + '&group=' + group
      + '&type=' + type
      + '&metrics[]=' + metrics.join('&metrics[]=');
    return this.http.get<any>(
      `${environment.apiUrl}` + 'dashboard/insightsData' + queryString
    ).pipe(
      tap(_ => console.log('fetched SuppressedDuplicates')),
      catchError(Errors.handleError<any>('getSuppressedDuplicates'))
    );
  }
}
