import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {BusinessesResponse} from '../_models/datatable-response';
import {Business} from '../_models/business';
import {BusinessService} from '../_services/business/business.service';
import {DataTableDirective} from 'angular-datatables';
import {Subject} from 'rxjs';
import {ngxCsv} from 'ngx-csv';
import {BreadcrumbsService} from 'ng6-breadcrumbs';
import {AlertService} from '../_services/alert.service';

@Component({
  selector: 'app-businesses',
  templateUrl: './businesses.component.html',
  styleUrls: ['./businesses.component.scss']
})
export class BusinessesComponent implements AfterViewInit, OnDestroy, OnInit {

  businesses: Business[];
  dtOptions: DataTables.Settings = {};
  @ViewChild(DataTableDirective, {static: false})
  dtElement: DataTableDirective;
  response: BusinessesResponse;
  // @ts-ignore
  dtTrigger: Subject = new Subject();
  businessSelected: Array<any> = [];
  checkList: Array<any> = [];
  allFlag = false;

  constructor(private businessService: BusinessService, private breadcrumbsService: BreadcrumbsService, private alertService: AlertService) { }

  ngOnInit() {
    this.breadcrumbsService.store([
      {label: 'Dashboard' , url: '/dashboard', params: []},
      {label: 'Accounts' , url: '/businesses', params: []}
    ]);
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      serverSide: true,
      processing: true,
      ajax: (dataTablesParameters: any, callback) => {
        console.log('Datatable parameters: ', dataTablesParameters)
        this.getBusinesses(dataTablesParameters.length, dataTablesParameters.start, dataTablesParameters.search.value, callback);
      },
      columns: [
        { data: 'id' },
        { data: 'name' },
        { data: 'dateCreated' },
        { data: 'effectiveDate' },
        { data: 'numOfLocations' },
        { data: 'productPlan.name' },
        {
          data: 'id',
          createdCell: (cell: Node, cellData: any, rowData: any, row: number, col: number) => {
            const btn = document.createElement('a');
            cellData = null;
            btn.setAttribute('data-id', rowData.id);
            btn.setAttribute('title', 'Edit User');
            btn.setAttribute('routerLink', '/edit-user/' + rowData.id);
            btn.className = 'btn waves-effect waves-light btn-outline-info';
            btn.innerHTML = '<i class="ti-pencil"></i>';
            cell.appendChild(btn);
          }
        }
      ],
      language: {
        processing: '<div class="spinning"><div class="spinner"><div class="bounce1"></div><div class="bounce2"></div><div class="bounce3"></div></div></div>'
      }
    };
  }

  /**
   * Get the list of existent users.
   */
  getBusinesses(max: number = 20, offset: number = 0, searchVal = '', callBackData): void {
    this.businessService.getBusiness(max, offset, searchVal)
      .subscribe(response => {
        this.businesses = response.response.businesses;
        for (const business in this.businesses) {
          if (typeof this.checkList[this.businesses[business].identifier] === 'undefined')
            this.checkList[this.businesses[business].identifier] = this.allFlag;
        }
        callBackData({
          recordsTotal: response.response.max,
          recordsFiltered: response.response.count,
          data: []// response.response.users
        });
      });
  }

  /**
   * Delete a business.
   */
  deleteBusiness(id: string) {
    this.alertService.alert('success', 'Account deleted successfully.');
    this.businessService.deleteBusiness(id).subscribe(response => { this.rerender(); });
  }

  /**
   * Format date fields
   */
  formatDate(date: string) {
    return (date !== '' && date !== null) ? new Date(date).toUTCString() : '';
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }

  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Destroy the table first
      dtInstance.destroy();
      // Call the dtTrigger to rerender again
      this.dtTrigger.next();
    });
  }

  selectBusiness(event: Event) {
    // @ts-ignore
    if (event.target.checked) {
      // @ts-ignore
      this.businessSelected.push(event.target.value);
    } else {
      // @ts-ignore
      const index = this.businessSelected.indexOf(event.target.value, 0);
      if (index > -1) {
        this.businessSelected.splice(index, 1);
      }
    }
    console.log(this.businessSelected);
    console.log(this.checkList);
  }

  exportData() {
    if (this.businessSelected.length > 0) {
      this.businessService.getBusinessesToExport((this.allFlag) ? ['all'] : this.businessSelected)
        .subscribe(response => {
          const businesses = response.response.businesses;
          const options = {
            headers: ['Name', 'Plan', 'Address', 'City', 'Country', 'Zip', 'Phone', 'Locations (Active)', 'Created At']
          };
          let formatedBusinesses = [];
          for (const bus of businesses) {
            formatedBusinesses.push({
              name: bus.name,
              plan: bus.productPlan.description,
              address: bus.streetAndNo,
              city: bus.city,
              country: bus.country,
              zip: bus.zip,
              phone: bus.phone,
              locations: bus.numOfLocations,
              created_at: bus.dateCreated
            });
          }
          const result = new ngxCsv(formatedBusinesses, 'Accounts List', options);
        });
    }
  }

  selectAllBusinesses(event: Event) {
    for (const check in this.checkList) {
      this.checkList[check] = true;
      if (typeof this.businessSelected[check] === 'undefined')
        this.businessSelected.push(check);
    }
    this.allFlag = true;
    console.log(this.businessSelected);
  }

  unselectBusinesses(event: MouseEvent) {
    for (const check in this.checkList) {
      this.checkList[check] = false;
      const index = this.businessSelected.indexOf(check, 0);
      if (index > -1) {
        this.businessSelected.splice(index, 1);
      }
    }
    this.allFlag = false;
    console.log(this.businessSelected);
  }
}
