import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import {LoginComponent} from './login/login.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import {JwtInterceptor, ErrorInterceptor, fakeBackendProvider} from './_helpers';
import {AlertComponent} from './alert/alert.component';
import { UsersComponent } from './users/users.component';
import { DataTablesModule } from 'angular-datatables';
import { CreateUserComponent } from './create-user/create-user.component';
import {UiSwitchModule} from 'ngx-ui-switch';
import {NgSelect2Module} from 'ng-select2';
import { ManageAccountComponent } from './manage-account/manage-account.component';
import { ManageAccountDirective } from './_directives/manage-account.directive';
// @ts-ignore
import {NgxMaskModule} from 'ngx-mask';
import { BusinessesComponent } from './businesses/businesses.component';
import { CreateBusinessComponent } from './create-business/create-business.component';
import {SweetAlert2Module} from '@sweetalert2/ngx-sweetalert2';
import { LocationsComponent } from './locations/locations.component';
import { CreateLocationComponent } from './create-location/create-location.component';
import { ManageOperationHoursComponent } from './manage-operation-hours/manage-operation-hours.component';
import { ManageOperationHoursDirective } from './_directives/manage-operation-hours.directive';
import { ManageSpecialOperationHoursComponent } from './manage-special-operation-hours/manage-special-operation-hours.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { FilePondModule, registerPlugin } from 'ngx-filepond';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
registerPlugin(FilePondPluginFileValidateType);
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
registerPlugin(FilePondPluginImagePreview);
import FilePondPluginImageValidateSize from 'filepond-plugin-image-validate-size';
registerPlugin(FilePondPluginImageValidateSize);
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
registerPlugin(FilePondPluginFileValidateSize);
import FilepondPluginImageSizeMetadata from 'filepond-plugin-image-size-metadata';
import { ManageLocationVideosComponent } from './manage-location-videos/manage-location-videos.component';
registerPlugin(FilepondPluginImageSizeMetadata);
import { NgxLoadingModule } from 'ngx-loading';
import {Daterangepicker} from 'ng2-daterangepicker';
import { KnobModule } from '@xmlking/ngx-knob';
import {ChartsModule} from 'ng2-charts';
import {PasswordComponent} from './password/password.component';
import {PasswordNewComponent} from './password-new/password-new.component';
import {BreadcrumbsModule} from 'ng6-breadcrumbs';
import { FeedComponent } from './feed/feed.component';
import { FilterFeedsPipe } from './_pipes/filter-feeds.pipe';
import { ReportsComponent } from './reports/reports.component';
import { BilledReportComponent } from './billed-report/billed-report.component';
import {ManagementReportComponent} from "./management-report/management-report.component";

@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    LoginComponent,
    PasswordComponent,
    PasswordNewComponent,
    AlertComponent,
    UsersComponent,
    CreateUserComponent,
    ManageAccountComponent,
    ManageAccountDirective,
    BusinessesComponent,
    CreateBusinessComponent,
    LocationsComponent,
    CreateLocationComponent,
    ManageOperationHoursComponent,
    ManageOperationHoursDirective,
    ManageSpecialOperationHoursComponent,
    ManageLocationVideosComponent,
    FeedComponent,
    FilterFeedsPipe,
    ReportsComponent,
    BilledReportComponent,
    ManagementReportComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    DataTablesModule,
    UiSwitchModule,
    NgSelect2Module,
    SweetAlert2Module.forRoot({
      buttonsStyling: false,
      customClass: 'modal-content',
      confirmButtonClass: 'btn btn-primary',
      cancelButtonClass: 'btn'
    }),
    NgxMaskModule.forRoot({}),
    NgbModule,
    FilePondModule,
    NgxLoadingModule.forRoot({}),
    Daterangepicker,
    KnobModule,
    ChartsModule,
    BreadcrumbsModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    fakeBackendProvider
  ],
  bootstrap: [AppComponent],
  exports: [
    DashboardComponent,
    LoginComponent,
    UsersComponent,
    CreateUserComponent,
    ManageAccountComponent,
    BusinessesComponent,
    CreateBusinessComponent,
    LocationsComponent,
    CreateLocationComponent,
    ManageOperationHoursComponent,
    ManageSpecialOperationHoursComponent,
    ManageLocationVideosComponent,
    FeedComponent
  ],
  entryComponents: [ManageAccountComponent, ManageOperationHoursComponent]
})
export class AppModule { }
