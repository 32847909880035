import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {Observable} from 'rxjs';
import {
  BusinessResponse,
  LocationResponse,
  LocationsResponse,
  UsersResponse
} from '../../_models/datatable-response';
import {catchError, tap} from 'rxjs/operators';
import {Errors} from '../../_helpers/errors';
import {Location} from '../../_models/location';
import {AuthenticationService} from '../authentication.service';

@Injectable({
  providedIn: 'root'
})
export class LocationService {
  private locationUrl = 'locations';
  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      // privateKey: environment.privateKey
    })
  };

  constructor(private http: HttpClient, private authService: AuthenticationService) { }

  getLocations(
    businessIds: string[],
    max: number = 20,
    offset: number = 0,
    searchVal = '',
    status = [],
    endDateMin = '',
    endDateMax = '',
    dateCreatedMin = '',
    dateCreatedMax = ''
  ): Observable<LocationsResponse> {
    let businessString = '';

    if (businessIds.length > 0) {
      businessString = 'businessId[]=' + businessIds.join('&businessId[]=') + '&';
    }
    let statusUrl = '';
    if (status.length > 0) {
      statusUrl = status.join('&status[]=');
    }
    return this.http.get<LocationsResponse>(
      `${environment.apiUrl}` + this.locationUrl + (((businessString !== '') ? ('?' + businessString) : ('?'))
        + 'max=' + max + '&offset=' + offset + '&query=' + searchVal
        + ((statusUrl !== '') ? '&status[]=' + statusUrl : '')
        + ((endDateMin !== '') ? '&endDateMin=' + endDateMin + ' 00:00:00' + '&endDateMax=' + endDateMax + ' 23:59:59' : '')
        + ((dateCreatedMin !== '') ? '&dateCreatedMin=' + dateCreatedMin + ' 00:00:00' + '&dateCreatedMax=' + dateCreatedMax + ' 23:59:59' : '')
    )).pipe(
      tap(_ => console.log('fetched Locations')),
      catchError(Errors.handleError<LocationsResponse>('getLocations'))
    );
  }

  getById(id: any) {
    return this.http.get<LocationResponse>(`${environment.apiUrl}` + this.locationUrl + '/' + id).pipe(
      tap(_ => console.log('fetched location')),
      catchError(Errors.handleError<LocationResponse>('getLocationById'))
    );
  }

  createLocation(locationValues: any) {
    console.log('locationValues', locationValues)
    return this.http.post<Location>(`${environment.apiUrl}` + this.locationUrl, this.buildLocationPostParam(locationValues), this.httpOptions)
      .pipe(
        catchError(Errors.handleError('addLocation', location))
      );
  }

  updateLocation(locationValues: any, id: string) {
    return this.http.put<Location>(`${environment.apiUrl}` + this.locationUrl + '/' + id, this.buildLocationPostParam(locationValues), this.httpOptions)
      .pipe(
        catchError(Errors.handleError('updateLocation', location))
      );
  }

  deactivateAndActivateLocation(locationValues: any, id: string) {
    return this.http.put<Location>(`${environment.apiUrl}` + this.locationUrl + '/' + id, locationValues, this.httpOptions)
      .pipe(
        catchError(Errors.handleError('updateLocation', location))
      );
  }

  deleteLocation(id: string) {
    return this.http.delete<LocationResponse>(`${environment.apiUrl}` + this.locationUrl + '/' + id).pipe(
      tap(_ => console.log('deleted business')),
      catchError(Errors.handleError<LocationResponse>('deleteLocation'))
    );
  }

  buildLocationPostParam(locationValues: any) {
    const formValues = locationValues;
    const openingHours = [];
    for (const openHour of formValues.allOpeningHours) {
      for (const dayWeek of openHour.openingHours) {
        const obj = {};
        obj['closed'] = false;
        obj['dayOfWeek'] = dayWeek;
        // obj['from' + dayWeek] = openHour.from;
        // obj['to' + dayWeek] = openHour.to;
        obj['from1'] = openHour.from;
        obj['to1'] = openHour.to;
        openingHours.push(obj);
      }
    }
    const photos = [];
    if (formValues.photos.type !== '' && formValues.photos.url !== '') {
      const photosType = formValues.photos.type.split(',');
      const photosUrl = formValues.photos.url.split(',');
      for (const i in photosType) {
        photos.push({type: photosType[i], url: photosUrl[i]});
      }
    }
    const specialOpeningHours = [];
    for (const openHour of formValues.allSpecialOpenHours) {
      specialOpeningHours.push({
        closed: (openHour.openClose === 'closed') ? true : false,
        from1: openHour.from,
        to1: openHour.to,
        date: openHour.dp.year + '-' + openHour.dp.month + '-' + openHour.dp.day
      });
    }
    const videos = [];
    for (const video of formValues.videos) {
      // tslint:disable-next-line:max-line-length
      videos.push({description: video.videoDescription, type: (video.videoUrl.indexOf('vimeo') !== -1) ? 'VIMEO' : 'YOUTUBE', url: video.videoUrl});
    }
    const socialProfiles = [];
    for (const social in formValues.socialProfiles) {
      if (formValues.socialProfiles[social] !== '') {
        socialProfiles.push({url: formValues.socialProfiles[social], type: social});
      }
    }
    const categories = [];
    for (const categ of formValues.categories) {
      const categValues = categ.split('-');
      categories.push({id: parseInt(categValues[0]), name: categValues[1]});
    }
    // const attributes = [];
    // for (const attr of formValues.attributes) {
    //   const attrValues = attr.split('-*-');
    //   console.log('Attributes', attrValues);
    //   attributes.push({externalId: attrValues[0], displayName: attrValues[1], valueType: attrValues[2], value: attrValues[3]});
    // }
    const location = new Location(
      (formValues.addressDisplay === true) ? true : false,
      formValues.addressExtra,
      formValues.attributes,
      formValues.brands,
      formValues.businessId,
      // categories,
      formValues.categories,
      formValues.city,
      formValues.country,
      formValues.descriptionLong,
      formValues.descriptionShort,
      formValues.email,
      formValues.fax,
      formValues.identifier,
      formValues.imprint,
      formValues.keywords,
      formValues.labels,
      formValues.languages,
      formValues.lat,
      formValues.lng,
      formValues.name,
      openingHours,
      formValues.openingHoursNotes,
      formValues.paymentOptions,
      formValues.phone,
      photos,
      formValues.services,
      socialProfiles,
      specialOpeningHours,
      formValues.street,
      formValues.streetNo,
      videos,
      formValues.website,
      formValues.zip
    );
    return location;
  }

  getLocationsToExport(locationIds: any[],
                       businessIds: string[],
                       status = [],
                       endDateMin = '',
                       endDateMax = '',
                       dateCreatedMin = '',
                       dateCreatedMax = ''): Observable<LocationsResponse> {
    let locationsString = '';

    if (locationIds.length > 0) {
      locationsString = 'locationIds[]=' + locationIds.join('&locationIds[]=') + '&';
    }
    let businessString = '';

    if (businessIds.length > 0) {
      businessString = 'businessId[]=' + businessIds.join('&businessId[]=') + '&';
    }
    let statusUrl = '';
    if (status.length > 0) {
      statusUrl = status.join('&status[]=');
    }
    return this.http.get<LocationsResponse>(
      `${environment.apiUrl}` + this.locationUrl + '/export/data/' + (
        ((businessString !== '') ? ('?' + businessString) : ('?'))
        + ((locationsString !== '') ? ('&' + locationsString) : '')
        + ((statusUrl !== '') ? '&status[]=' + statusUrl : '')
        + ((endDateMin !== '') ? '&endDateMin=' + endDateMin + ' 00:00:00' + '&endDateMax=' + endDateMax + ' 23:59:59' : '')
        + ((dateCreatedMin !== '') ? '&dateCreatedMin=' + dateCreatedMin + ' 00:00:00' + '&dateCreatedMax=' + dateCreatedMax + ' 23:59:59' : '')
      )).pipe(
      tap(_ => console.log('fetched LocationsExport')),
      catchError(Errors.handleError<LocationsResponse>('getlocationsExport'))
    );
  }

  uploadImage(fieldName, file, metadata, load, error, progress, abort) {
    const data = new FormData();
    data.append('file', file);
    return this.http.post(`${environment.apiUrl}` + 'upload/location/image', data)
      .pipe(
        tap(_ => console.log('fetched LocationsExport')),
        catchError(Errors.handleError('addLocation', location))
      );
  }

  getImage(source) {
    const httpOptionsCustom = {
      responseType: 'blob'
    };
    const data = new FormData();
    data.append('url', source);
    // @ts-ignore
    return this.http.post(`${environment.apiUrl}` + 'get/location/image', data, httpOptionsCustom)
      .pipe(
        tap(_ => console.log('fetched LocationsExport')),
        catchError(Errors.handleError('addLocation', location))
      );
  }

  deleteImage(file) {
    const onlyName = file.filename.split('.')[0];
    const onlytype = file.fileType.split('/')[1];
    const source = 'https://sweetiq-images.s3.amazonaws.com/' + `${environment.s3FolderName}/` + this.authService.currentUserValue.identifier + '/' + 'locationPhotos/' + onlyName + '-' + file.id + '.' + onlytype;
    const data = new FormData();
    data.append('url', source);
    return this.http.post(`${environment.apiUrl}` + 'delete/location/image', data)
      .pipe(
        tap(_ => console.log('fetched LocationsExport')),
        catchError(Errors.handleError('addLocation', location))
      );
  }
}
