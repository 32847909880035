import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';

import { environment } from '../../environments/environment';
import { User } from '../_models';
import {Observable, of} from 'rxjs';
import {catchError, tap} from 'rxjs/operators';
import {LocationsResponse, UserResponse, UsersResponse} from '../_models/datatable-response';
import {Errors} from '../_helpers/errors';

@Injectable({ providedIn: 'root' })
export class UserService {

    private usersUrl = 'users';
    private httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        // privateKey: environment.privateKey
      })
    };

    constructor(private http: HttpClient) { }

    /** GET users from Uberall server */
    getUsers(max: number = 20, offset: number = 0, searchVal = ''): Observable<UsersResponse> {
      return this.http.get<UsersResponse>(
        `${environment.apiUrl}` + this.usersUrl + '?max=' + max + '&offset=' + offset + '&query=' + searchVal
        ).pipe(
            tap(_ => console.log('fetched users')),
            catchError(Errors.handleError<UsersResponse>('getUsers'))
          );
    }

    getAll() {
        return this.http.get<User[]>(`${environment.apiUrl}/users`);
    }

    getById(id: any) {
        return this.http.get<UserResponse>(`${environment.apiUrl}` + this.usersUrl + '/' + id).pipe(
          tap(_ => console.log('fetched user')),
          catchError(Errors.handleError<UserResponse>('getUserById'))
        );
    }

    createUser(user: User) {
      return this.http.post<User>(`${environment.apiUrl}` + this.usersUrl, user, this.httpOptions)
        .pipe(
          catchError(Errors.handleError('addUser', user))
        );
    }

    updateUser(user: User, id: string) {
      return this.http.put<User>(`${environment.apiUrl}` + this.usersUrl + '/' + id, user, this.httpOptions)
        .pipe(
          catchError(Errors.handleError('updateUser', user))
        );
    }

    deleteUser(id: string) {
      return this.http.delete<UserResponse>(`${environment.apiUrl}` + this.usersUrl + '/' + id).pipe(
        tap(_ => console.log('deleted user')),
        catchError(Errors.handleError<UserResponse>('deleteUser'))
      );
    }

  getUsersToExport(usersIds: any[]): Observable<UsersResponse> {
    let usersString = '';

    if (usersIds.length > 0) {
      usersString = 'usersIds[]=' + usersIds.join('&usersIds[]=') + '&';
    }
    return this.http.get<UsersResponse>(
      `${environment.apiUrl}` + this.usersUrl + '/export/data/' + ((usersString !== '') ? ('?' + usersString) : ''
      )).pipe(
      tap(_ => console.log('fetched UsersExport')),
      catchError(Errors.handleError<UsersResponse>('getUsersExport'))
    );
  }
}
