import {LocationAttribute} from './location-attribute';
import {LocationCategory} from './location-category';
import {LocationContentList} from './location-content-list';
import {LocationListing} from './location-listing';
import {LocationPhoto} from './location-photo';
import {OpeningHour} from './opening-hour';
import {LocationSocialProfile} from './location-social-profile';
import {LocationVideo} from './location-video';

export class Location {
  activeDirectoriesCount: number;
  activeListingsCount: number;
  addressDisplay: boolean;
  addressExtra: string;
  attributes: LocationAttribute[];
  autoSync: boolean;
  brands: string[];
  businessId: string;
  categories: LocationCategory[];
  cellphone: string;
  city: string;
  contentLists: LocationContentList[];
  country: string;
  dataPoints: number;
  dateCreated: string;
  descriptionLong: string;
  descriptionShort: string;
  email: string;
  emailVerification: string;
  fax: string;
  features: string[];
  id: bigint;
  identifier: string;
  identifierEdit: string;
  imprint: string;
  keywords: string[];
  labels: string[];
  languages: string[];
  lastSyncStarted: string;
  lastUpdated: string;
  lat: number;
  legalIdent: string;
  listings: LocationListing[];
  listingsBeingUpdated: number;
  listingsInSync: number;
  lng: number;
  mainPhoto: LocationPhoto;
  missingMandatoryFields: string[];
  name: string;
  openingHours: OpeningHour[];
  openingHoursNotes: string;
  paymentOptions: string[];
  phone: string;
  photos: LocationPhoto[];
  profileCompleteness: number;
  province: string;
  publishedListingsCount: number;
  serviceAreas: string[];
  socialProfiles: LocationSocialProfile[];
  specialOpeningHours: OpeningHour[];
  status: string;
  street: string;
  streetNo: string;
  streetType: string;
  taxNumber: string;
  updateHistory: string;
  videos: LocationVideo[];
  visibilityIndex: number;
  website: string;
  websiteExtra: string;
  zip: string;
  streetAndNumber: string;
  endDate: string;
  allLogs: any;

  constructor(
    addressDisplay: boolean,
    addressExtra: string,
    attributes: LocationAttribute[],
    brands: string[],
    businessId: string,
    categories: LocationCategory[],
    city: string,
    country: string,
    descriptionLong: string,
    descriptionShort: string,
    email: string,
    fax: string,
    // features: string[],
    identifier: string,
    imprint: string,
    keywords: string[],
    labels: string[],
    languages: string[],
    lat: number,
    lng: number,
    name: string,
    openingHours: OpeningHour[],
    openingHoursNotes: string,
    paymentOptions: string[],
    phone: string,
    photos: LocationPhoto[],
    serviceAreas: string[],
    socialProfiles: LocationSocialProfile[],
    specialOpeningHours: OpeningHour[],
    street: string,
    streetNo: string,
    videos: LocationVideo[],
    website: string,
    zip: string
  ) {
      this.addressDisplay = addressDisplay;
      this.addressExtra = addressExtra;
      this.attributes = attributes;
      this.brands = brands;
      this.businessId = businessId;
      this.categories = categories;
      this.city = city;
      this.country = country;
      this.descriptionLong = descriptionLong;
      this.descriptionShort = descriptionShort;
      this.email = email;
      this.fax = fax;
      // this.features = features;
      this.identifier = identifier;
      this.imprint = imprint;
      this.keywords = keywords;
      this.labels = labels;
      this.languages = languages;
      this.lat = lat;
      this.lng = lng;
      this.name = name;
      this.openingHours = openingHours;
      this.openingHoursNotes = openingHoursNotes;
      this.paymentOptions = paymentOptions;
      this.phone = phone;
      this.photos = photos;
      this.serviceAreas = serviceAreas;
      this.socialProfiles = socialProfiles;
      this.specialOpeningHours = specialOpeningHours;
      this.street = street;
      this.streetNo = streetNo;
      this.videos = videos;
      this.website = website;
      this.zip = zip;
  }
}
