import { Component, OnInit } from '@angular/core';
import {Feed} from '../_models/feed';
import {interval} from 'rxjs';
import {FeedService} from '../_services/service/feed.service';
import {FeedTypesData} from '../_models/feed-types';
import * as moment from 'moment';

@Component({
  selector: 'app-feed',
  templateUrl: './feed.component.html',
  styleUrls: ['./feed.component.scss']
})
export class FeedComponent implements OnInit {

  feeds: Array<Feed> = [];
  feedTypes = new FeedTypesData();
  ngLoading = false;

  constructor(private feedService: FeedService) { }

  ngOnInit() {
    this.getFeeds();
  }

  getFeeds() {
    this.ngLoading = true;
    const secondsCounter = interval(300000);
    this.feedService.getFeeds().subscribe(response => {
      this.feeds = response.response;
      // console.log('FEEDS: ', this.feeds);
      this.ngLoading = false;
    });
    secondsCounter.subscribe(n => {
      this.ngLoading = true;
      console.log(`It's been ${n} seconds since subscribing!`)
      this.feedService.getFeeds().subscribe(response => {
        this.feeds = response.response;
        // console.log('FEEDS: ', this.feeds);
        this.ngLoading = false;
      });
    });
  }

  convertDate(date: string) {
    let time = moment().diff(moment(date), 'minutes');
    if (time <= 59) {
      return time + ' minutes ago';
    }
    time = moment().diff(moment(date), 'hours');
    if (time <= 23) {
      return time + ' hours ago';
    }
    return moment().diff(moment(date), 'days') + ' days ago';
  }

  feedValid(feed: any) {
    return typeof feed.properties.locations !== 'undefined';
  }
}
