import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthenticationService} from '../_services';
import {AlertService} from '../_services/alert.service';
import * as $ from 'jquery';
import {first} from 'rxjs/operators';

@Component({
  selector: 'app-login',
  templateUrl: './password-new.component.html',
  styleUrls: ['./password-new.component.css']
})

export class PasswordNewComponent implements OnInit {

  resetForm: FormGroup;
  loading =  false;
  submitted =  false;
  token = '';

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private alertService: AlertService,
  ) { }

  ngOnInit() {
    this.resetForm = this.formBuilder.group({
      password: ['', [Validators.required]],
      confirmPassword: ['', [Validators.required]]
    });
    this.route.paramMap.subscribe(params => {
      if (params.get('token') !== null) {
        this.token = params.get('token');
      }
    });
  }

  get f() { return this.resetForm.controls; }

  onSubmit() {
    this.submitted = true;
    if (this.resetForm.invalid) {
      return;
    }
    console.log('login comp in');
    this.loading = true;
    this.authenticationService.newPassword(this.f.password.value, this.f.confirmPassword.value, this.token)
      .pipe(first())
      .subscribe(
        data => {
          console.log(data);
          this.alertService.alert('success', data.response, 1000);
          this.loading = false;
          this.router.navigate(['/login']);
        },
        error => {
          console.log('login comp data out');
          this.alertService.error(error);
          this.loading = false;
        }
      );
  }
}
