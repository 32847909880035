import { Component, OnInit } from '@angular/core';
import {BusinessService} from '../_services/business/business.service';
import {ActivatedRoute} from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Business} from '../_models/business';
import {ProductPlan} from '../_models/product-plan';
import {ProductPlanService} from '../_services/product-plan/product-plan.service';
import {CountryList} from '../_models/country-list';
import {BreadcrumbsService} from 'ng6-breadcrumbs';
import {AlertService} from '../_services/alert.service';
import {Observable} from 'rxjs';
import {Select2OptionData} from 'ng-select2';

@Component({
  selector: 'app-create-business',
  templateUrl: './create-business.component.html',
  styleUrls: ['./create-business.component.scss']
})
export class CreateBusinessComponent implements OnInit {

  createOrEdit = 'Create';
  newBusinessForm: FormGroup;
  business: Business;
  loading = false;
  productPlans: ProductPlan[];
  countries: {short: string, name: string}[];
  planDirectories: string[];
  iconsUberallUrl = 'https://static-prod.uberall.com/assets/directory_logo/png/';
  countryList = new CountryList();
  ngLoading = false;
  states: Observable<Array<Select2OptionData>> = null;
  statesToken: string;
  options: any;
  statesInitValue: Observable<number>;
  showStatesInput = false;

  constructor(
    private businessService: BusinessService,
    private productPlanService: ProductPlanService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private breadcrumbsService: BreadcrumbsService,
    private alertService: AlertService
  ) { }

  ngOnInit() {
    this.options = {
      multiple: false,
      closeOnSelect: true,
      width: '100%',
    };
    this.countries = [];
    this.productPlans = [];
    this.getProductPlans();
    this.newBusinessForm = this.fb.group({
      name: ['', Validators.required],
      phone: [''],
      // identifier: ['', Validators.required],
      streetAndNo: ['', Validators.required],
      addressLine2: [''],
      province: [''],
      zip: ['', Validators.required],
      city: ['', Validators.required],
      country: ['', Validators.required],
      productPlan: ['', Validators.required]
    });
    this.route.paramMap.subscribe(params => {
      if (typeof params.get('id') !== 'undefined' && params.get('id') !== null) {
        this.ngLoading = true;
        this.businessService.getById(params.get('id')).subscribe(response => {
          this.business = response.response.business;
          if (typeof this.business !== 'undefined' && typeof this.business.id !== 'undefined') {
            this.createOrEdit = 'Edit';
            this.formControls.name.setValue(this.business.name);
            this.formControls.phone.setValue(this.business.phone);
            // this.formControls.identifier.setValue(this.business.identifier);
            this.formControls.streetAndNo.setValue(this.business.streetAndNo);
            this.formControls.addressLine2.setValue(this.business.addressLine2);
            this.formControls.province.setValue(this.business.province);
            this.formControls.zip.setValue(this.business.zip);
            this.formControls.city.setValue(this.business.city);
            this.formControls.country.setValue(this.business.country);
            this.formControls.productPlan.setValue(this.business.productPlan.id);
            this.statesInitValue =  Observable.create((obs) => {
              obs.next(this.business.province);
              obs.complete();
            });
            this.getStates(this.business.country);
            // this.changePlan();
          }
          console.log('BUSINESS: ', this.business);
          this.ngLoading = false;
        });
        this.breadcrumbsService.store([
          {label: 'Dashboard' , url: '/dashboard', params: []},
          {label: 'Accounts' , url: '/accounts', params: []},
          {label: 'Edit Account' , url: '/edit-account/:id', params: []}
        ]);
      } else {
        this.breadcrumbsService.store([
          {label: 'Dashboard' , url: '/dashboard', params: []},
          {label: 'Accounts' , url: '/accounts', params: []},
          {label: 'Create Account' , url: '/create-account', params: []}
        ]);
      }
    });
  }

  get formControls() {
    return this.newBusinessForm.controls;
  }

  onSubmit(event) {
    // TODO: Use EventEmitter with form value
    this.loading = true;
    this.ngLoading = true;
    console.warn(this.newBusinessForm.value, event);
    if (this.createOrEdit === 'Create') {
      this.businessService.createBusiness(this.newBusinessForm.value as Business)
        .subscribe(response => {
          console.log('RESPONSE: ', response);
          this.loading = false;
          this.ngLoading = false;
          this.newBusinessForm.reset();
          if ( String(response.status) === 'SUCCESS' ) {
            this.alertService.alert('success', 'Account created successfully.');
          }
        }, (error) => { this.loading = false; this.ngLoading = false; });
    } else {
      this.businessService.updateBusiness(this.newBusinessForm.value as Business, this.business.identifier)
        .subscribe(response => {
          console.log('RESPONSE: ', response);
          this.loading = false;
          this.ngLoading = false;
          if ( String(response.status) === 'SUCCESS' ) {
            this.alertService.alert('success', 'Account updated successfully.');
          }
        }, (error) => { this.loading = false; this.ngLoading = false; });
    }
  }

  getProductPlans() {
    this.productPlanService.getProductPlans().subscribe(response => {
      for (const prod of response.response.plans) {
        if (prod.status === 'ACTIVE') {
          this.productPlans.push(prod);
          if (prod.defaultPlan) {
            this.planDirectories = prod.updatedDirectories.sort();
            this.formControls.productPlan.setValue(prod.id);
          }
          for (const country of prod.countryPrice) {
            // tslint:disable-next-line:max-line-length
            if (this.countries.findIndex(value => value.short === country.country) === -1 && typeof this.countryList[country.country] !== 'undefined' && this.countryList[country.country] !== null) {
              this.countries.push({short: country.country, name: this.countryList[country.country]});
            }
          }
        }
      }
      this.changePlan();
    });
  }

  changePlan() {
    const actualPlan = this.formControls.productPlan.value;
    this.planDirectories = this.productPlans.find(value => String(value.id) === String(actualPlan)).updatedDirectories;
  }

  getStates(country: string) {
    this.businessService.getStatesApiToken().subscribe(resp => {
      this.statesToken = resp.auth_token;
      const place = (typeof this.countryList[country] !== 'undefined') ? this.countryList[country] : 'United States';
      // @ts-ignore
      this.businessService.getStatesByCountryName(place, this.statesToken).subscribe(
        (response) => {
          const selectStates = response.map((value, index, array) => ({id: value.state_name, text: value.state_name}));
          this.states = Observable.create((obj) => {
            obj.next(selectStates);
            obj.complete();
          });
        },
        (error) => { console.log(error, 'getStates'); this.showStatesInput = true; }
      );
    }, error => { this.showStatesInput = true; })
  }
}
