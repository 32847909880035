import {AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {DataTableDirective} from 'angular-datatables';
import {Observable, Subject} from 'rxjs';
import {LocationService} from '../_services/location/location.service';
import {Location} from '../_models/location';
import {ngxCsv} from 'ngx-csv';
import {BreadcrumbsService} from 'ng6-breadcrumbs';
import {AlertService} from '../_services/alert.service';
import * as L from 'leaflet';
import {ActivatedRoute, Router} from '@angular/router';
import {Select2OptionData} from 'ng-select2';
import * as moment from 'moment';
import {BusinessService} from '../_services/business/business.service';

@Component({
  selector: 'app-locations',
  templateUrl: './locations.component.html',
  styleUrls: ['./locations.component.scss']
})
export class LocationsComponent implements AfterViewInit, OnDestroy, OnInit {

  dtOptions: DataTables.Settings = {};
  @ViewChild(DataTableDirective, {static: false})
  dtElement: DataTableDirective;
  // @ts-ignore
  dtTrigger: Subject = new Subject();
  locations: Location[];
  locationsSelected: Array<any> = [];
  checkList: Array<any> = [];
  allFlag = false;
  mapList = false;
  map: {};
  markers: Array<any> = [];
  private currentBusiness = [];
  public daterange: any = {start: moment().subtract(1, 'year'), end: moment()};
  public daterangeCreated: any = {start: moment().subtract(1, 'year'), end: moment()};
  status = '';
  selectedAccount = '';
  businesses: Observable<Array<Select2OptionData>>;
  public optionsBusinesses: any;
  public optionsDateRange: any = {
    autoUpdateInput: false,
    locale: { format: 'MM/DD/YYYY' },
    alwaysShowCalendars: false,
    ranges: {
      'This Year': [moment().startOf('year'), moment().endOf('year')],
      // 'Last Year': [moment().subtract(1, 'year').startOf('year'), moment().subtract(1, 'year').endOf('year')],
      'Last Year': [moment().subtract(1, 'year'), moment()],
      'This Quarter': [moment().startOf('quarter'), moment().endOf('quarter')],
      'Last Quarter': [moment().subtract(1, 'quarter').startOf('quarter'), moment().subtract(1, 'quarter').endOf('quarter')],
      'This Month': [moment().startOf('month'), moment().endOf('month')],
      'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
      'This Week': [moment().startOf('week'), moment().endOf('week')],
      'Last Week': [moment().subtract(1, 'week').startOf('week'), moment().subtract(1, 'week').endOf('week')],
      Today: [moment(), moment()],
    }
  };
  dateValue = '';
  dateValueCreated = '';
  businessInitValue: any;

  constructor(
    private locationService: LocationService,
    private breadcrumbsService: BreadcrumbsService,
    private alertService: AlertService,
    private elementRef: ElementRef,
    private router: Router,
    private route: ActivatedRoute,
    private businessService: BusinessService
  ) { }

  ngOnInit() {
    this.optionsBusinesses = {
      multiple: true,
      closeOnSelect: true,
      width: '100%',
    };
    this.breadcrumbsService.store([
      {label: 'Dashboard' , url: '/dashboard', params: []},
      {label: 'Locations' , url: '/locations', params: []}
    ]);
    this.route.paramMap.subscribe(params => {
      if (typeof params.get('businessId') !== 'undefined' && params.get('businessId') !== null) {
        console.log('THE BUSINESS ID: ', params.get('businessId'));
        this.currentBusiness.push(String(params.get('businessId')));
        this.businessInitValue =  Observable.create((obs) => {
          obs.next(this.currentBusiness);
          obs.complete();
        });
      }
    });
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      serverSide: true,
      processing: true,
      ajax: (dataTablesParameters: any, callback) => {
        this.getLocations(dataTablesParameters.length, dataTablesParameters.start, dataTablesParameters.search.value, callback);
      },
      columns: [
        { data: 'id' },
        { data: 'name' },
        { data: 'streetAndNumber' },
        { data: 'endDate' },
        { data: 'publishedListingsCount' },
        { data: 'profileCompleteness' },
        { data: 'labels' },
        { data: 'lastSyncStarted' },
        { data: 'dateCreated' },
        { data: 'endDate' },
        { data: 'id' }
      ],
      language: {
        processing: '<div class="spinning"><div class="spinner"><div class="bounce1"></div><div class="bounce2"></div><div class="bounce3"></div></div></div>'
      }
    };
    this.getBusinesses();
  }

  getBusinesses(): void {
    this.businessService.getBusiness().subscribe(
      businesses => {
        const allBusinesses = businesses.response.businesses;
        const list: Array<Select2OptionData> = [];
        for (const bus of allBusinesses) {
          list.push({id: String(bus.identifier), text: bus.name});
        }
        this.businesses =  Observable.create((obs) => {
          obs.next(list);
          obs.complete();
        });
      }
    );
  }

  /**
   * Get the list of existent users.
   */
  getLocations(max: number = 20, offset: number = 0, searchVal = '', callBackData): void {
    this.markers = [];
    this.locationService.getLocations(
      ((this.currentBusiness.length === 0) ? [] : this.currentBusiness), max, offset, searchVal,
      (this.status !== 'all') ? [this.status] : [], (this.dateValue !== '') ? this.daterange.start.format('YYYY-MM-DD') : '',
      (this.dateValue !== '') ? this.daterange.end.format('YYYY-MM-DD') : '',
      (this.dateValueCreated !== '') ? this.daterangeCreated.start.format('YYYY-MM-DD') : '',
      (this.dateValueCreated !== '') ? this.daterangeCreated.end.format('YYYY-MM-DD') : ''
    )
      .subscribe(response => {
        this.locations = response.response.locations;
        const mapIcon = L.icon({
          iconUrl: '/assets/images/map-marker.png',
          iconSize:     [50, 50],
        });
        for (const location in this.locations) {
          if (typeof this.checkList[this.locations[location].identifier] === 'undefined') {
            this.checkList[this.locations[location].identifier] = this.allFlag;
          }
          const content = '<div>' +
            '<h5><a target="_blank" href="/edit-location/' + this.locations[location].identifier + '" class="popup-link" data-locid="' + this.locations[location].identifier + '">' + this.locations[location].name + '</a></h5>' +
            '<div class="markerLocationName">' + this.locations[location].streetAndNumber + ', ' + this.locations[location].zip + ' ' + this.locations[location].city + '</div>' +
            '</div>';
          this.markers.push(L.marker([this.locations[location].lat, this.locations[location].lng], {icon: mapIcon})
            .bindTooltip(this.locations[location].name).openTooltip()
            .bindPopup(content).openPopup());
        }
        if (typeof this.map !== 'undefined' && this.map !== null) {
          // @ts-ignore
          this.map.remove();
          this.map = null;
        }
        callBackData({
          recordsTotal: response.response.max,
          recordsFiltered: response.response.count,
          data: []// response.response.users
        });
      });
  }

  /**
   * Delete a business.
   */
  deleteLocation(id: string) {
    this.alertService.alert('success', 'Location deleted successfully.');
    this.locationService.deleteLocation(id).subscribe(response => { this.rerender(); });
  }

  /**
   * Format date fields
   */
  formatDate(date: string) {
    return (date !== '' && date !== null) ? new Date(date).toLocaleDateString() : '';
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }

  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Destroy the table first
      dtInstance.destroy();
      // Call the dtTrigger to rerender again
      this.dtTrigger.next();
    });
  }

  selectLocation(event: Event) {
    // @ts-ignore
    if (event.target.checked) {
      // @ts-ignore
      this.locationsSelected.push(event.target.value);
    } else {
      // @ts-ignore
      const index = this.locationsSelected.indexOf(event.target.value, 0);
      if (index > -1) {
        this.locationsSelected.splice(index, 1);
      }
    }
    console.log(this.locationsSelected);
    console.log(this.checkList);
  }

  exportData() {
    if (this.locationsSelected.length > 0) {
      this.locationService.getLocationsToExport(
        (this.allFlag) ? ['all'] : this.locationsSelected,
        ((this.currentBusiness.length === 0) ? [] : this.currentBusiness),
        [this.status], (this.dateValue !== '') ? this.daterange.start.format('YYYY-MM-DD') : '',
        (this.dateValue !== '') ? this.daterange.end.format('YYYY-MM-DD') : '',
        (this.dateValueCreated !== '') ? this.daterangeCreated.start.format('YYYY-MM-DD') : '',
        (this.dateValueCreated !== '') ? this.daterangeCreated.end.format('YYYY-MM-DD') : ''
        )
        .subscribe(response => {
          const locations = response.response.locations;
          const options = {
            headers: [
              'Identifier',
              'Name',
              'Address',
              'State',
              'Country',
              'Status',
              'Phone',
              'Email',
              'Directories',
              'Profile Completeness',
              'Labels',
              'Latitude-Longitude',
              'Last Sync Date',
              'Created Date',
              'End Date'
            ]
          };
          const formatedLocations = [];
          for (const loc of locations) {
            formatedLocations.push({
              // tslint:disable-next-line:max-line-length
              identifier: (typeof loc.identifierEdit !== 'undefined' && loc.identifierEdit !== null && loc.identifierEdit !== '') ? loc.identifierEdit : loc.identifier,
              name: loc.name,
              address: loc.streetAndNumber + ' ' + loc.zip + ' ' + loc.city,
              state: loc.province,
              country: loc.country,
              status: loc.status,
              phone: loc.phone,
              email: loc.email,
              // tslint:disable-next-line:max-line-length
              directories: loc.publishedListingsCount + '/' + loc.activeDirectoriesCount + '(' + this.percentCalc(loc.activeDirectoriesCount, loc.publishedListingsCount) + '%)',
              profileComp: loc.profileCompleteness + '%',
              labels: loc.labels.join(', '),
              latLong: loc.lat + ' - ' + loc.lng,
              sync: loc.lastSyncStarted,
              created: loc.dateCreated,
              endDate: loc.endDate
            });
          }
          const result = new ngxCsv(formatedLocations, 'Locations List', options);
        });
    }
  }

  selectAllLocations(event: Event) {
    // tslint:disable-next-line:forin
    for (const check in this.checkList) {
      this.checkList[check] = true;
      if (typeof this.locationsSelected[check] === 'undefined') {
        this.locationsSelected.push(check);
      }
    }
    this.allFlag = true;
    console.log(this.locationsSelected);
  }

  unselectLocations(event: MouseEvent) {
    // tslint:disable-next-line:forin
    for (const check in this.checkList) {
      this.checkList[check] = false;
      const index = this.locationsSelected.indexOf(check, 0);
      if (index > -1) {
        this.locationsSelected.splice(index, 1);
      }
    }
    this.allFlag = false;
    console.log(this.locationsSelected);
  }

  initMap(): void {
    this.mapList = true;
    setTimeout(() => {
      if (typeof this.map === 'undefined' || this.map === null) {
        this.map = L.map('map');
        const tiles = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
          maxZoom: 5,
          // attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
        }).addTo(this.map);
        const group = L.featureGroup(this.markers)
          .addTo(this.map);
        // @ts-ignore
        this.map.fitBounds(group.getBounds());
      }
    }, 10);
  }

  percentCalc(total, minor) {
    return parseFloat('' + ((minor * 100) / total)).toFixed(0);
  }

  changeBusiness(event) {
    console.log(event);
    console.log(event.value);
    this.locationsSelected = [];
    this.currentBusiness = event.value;
    this.rerender();
    // this.getLocations(event.value);
  }

  changeStatus($event) {
    // @ts-ignore
    this.status = event.target.value;
    this.locationsSelected = [];
    this.rerender();
  }

  selectedDate(value: any, datepicker?: any) {
    this.locationsSelected = [];
    // this is the date the iser selected
    console.log(value, datepicker);

    // any object can be passed to the selected event and it will be passed back here
    datepicker.start = value.start;
    datepicker.end = value.end;

    // or manupulat your own internal property
    this.daterange.start = value.start;
    this.daterange.end = value.end;
    this.daterange.label = value.label;
    this.dateValue = this.daterange.start.format('MM/DD/YYYY') + ' - ' + this.daterange.end.format('MM/DD/YYYY');
    this.rerender();
  }

  cancelEndDates() {
    this.dateValue = '';
    this.rerender();
  }

  selectedCreatedDate(value: any, datepicker?: any) {
    this.locationsSelected = [];
    // this is the date the iser selected
    console.log(value, datepicker);

    // any object can be passed to the selected event and it will be passed back here
    datepicker.start = value.start;
    datepicker.end = value.end;

    // or manupulat your own internal property
    this.daterangeCreated.start = value.start;
    this.daterangeCreated.end = value.end;
    this.daterangeCreated.label = value.label;
    this.dateValueCreated = this.daterangeCreated.start.format('MM/DD/YYYY') + ' - ' + this.daterangeCreated.end.format('MM/DD/YYYY');
    this.rerender();
  }

  cancelCreatedDates() {
    this.dateValueCreated = '';
    this.rerender();
  }
}
