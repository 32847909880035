import { Component, OnInit, ComponentFactoryResolver, ViewChild } from '@angular/core';
import {FormBuilder, FormGroup, Validators, FormArray, FormControl} from '@angular/forms';
import {ActivatedRoute} from '@angular/router';
import {Location} from '../_models/location';
import {LocationService} from '../_services/location/location.service';
import { Observable } from 'rxjs';
import { Select2OptionData } from 'ng-select2';
import { BusinessService } from '../_services/business/business.service';
import { CountryList } from '../_models/country-list';
import { CategoriesService } from '../_services/categories/categories.service';
import { environment } from 'src/environments/environment';
import { ManageOperationHoursDirective } from '../_directives/manage-operation-hours.directive';
import { AttributesService } from '../_services/attributes/attributes.service';
import {languages} from '../_models/languages-list';
import { SaveImageService } from '../_services/save-image/save-image.service';
import { AlertService } from '../_services/alert.service';
import {User} from '../_models';
import {AuthenticationService} from '../_services';
import {BreadcrumbsService} from 'ng6-breadcrumbs';

@Component({
  selector: 'app-create-location',
  templateUrl: './create-location.component.html',
  styleUrls: ['./create-location.component.scss']
})
export class CreateLocationComponent implements OnInit {

  createOrEdit = 'Create';
  newLocationForm: FormGroup;
  location: Location;
  loading = false;
  businesses: Observable<Array<Select2OptionData>>;
  businessInitValue: Observable<number>;
  categInitValues: Observable<Array<string>>;
  categInitTexts: Observable<Array<Select2OptionData>>;
  daysInitValues: any[] = [];
  specialDaysInitValues: any[] = [];
  keyWordsInit: Observable<Array<string>>;
  keyWordsInitTexts: Observable<Array<Select2OptionData>>;
  attributesInit: Observable<Array<string>>;
  labelsInit: Observable<Array<string>>;
  labelsInitTexts: Observable<Array<Select2OptionData>>;
  langInit: Observable<Array<string>>;
  brandsInit: Observable<Array<string>>;
  brandsInitTexts: Observable<Array<Select2OptionData>>;
  servicesInit: Observable<Array<string>>;
  servicesInitTexts: Observable<Array<Select2OptionData>>;
  public options: any;
  public optionsCateg: any;
  public optionsKeywords: any;
  public optionsLabels: any;
  public optionsAttributes: any;
  public optionsPaymentOptions: any;
  public optionsBrands: any;
  public optionsLang: any;
  public optionsServ: any;
  countryList = [];
  categories: Observable<Array<Select2OptionData>>;
  existingOperHours: any = [];
  existingSpecOperHours: any;
  existingVideos: any;
  @ViewChild(ManageOperationHoursDirective, {static: true}) opHourManag: ManageOperationHoursDirective;
  attributesList: Observable<Array<Select2OptionData>>;
  hasAttributes: boolean;
  paymentOptionsList: {id: string, text: string, url: string}[];
  paymentImagesUrl = 'https://static-prod.uberall.com/assets/webapp/profile/payment_options/';
  languagesList = languages;
  @ViewChild('myPond', {static: true}) myPond: any;
  pondOptions: any;
  pondLandscapeOptions: any;
  pondPhotosOptions: any;
  pondFileLogo: Observable<Array<any>>;
  pondFileSquared: Observable<Array<any>>;
  pondFileLandscape: Observable<Array<any>>;
  pondFilePhotos: Observable<Array<any>>;
  actualServerId = '';
  actualImgType = '';
  paymentInit: Observable<Array<string>>;
  ngxLoading: boolean;
  private currentUser: User;
  currentBusiness = '';
  locationStatus = 'ACTIVE';
  autoSync: boolean = true;
  urlReg = '(https?://)([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?';

  constructor(
    private locationService: LocationService,
    private fb: FormBuilder,
    private businessService: BusinessService,
    private categService: CategoriesService,
    private route: ActivatedRoute,
    private componentFactoryResolver: ComponentFactoryResolver,
    private attributesService: AttributesService,
    private imgService: SaveImageService,
    private alertService: AlertService,
    private authService: AuthenticationService,
    private breadcrumbsService: BreadcrumbsService
  ) { }

  ngOnInit() {
    this.ngxLoading = false;
    this.pondOptions = {
      // class: 'my-filepond',
      multiple: false,
      labelIdle: 'Drop files or click here for browsing...',
      acceptedFileTypes: 'image/jpeg, image/png, image/jpg',
      allowImagePreview: true,
      imagePreviewTransparencyIndicator: 'grid',
      imagePreviewMarkupShow: false,
      maxFileSize: '5MB',
      allowImageSizeMetadata: true,
      server: {
        process: (fieldName, file, metadata, load, error, progress, abort) => {
          // console.log(file)
          //return this.imgService.uploadFile(fieldName, file, metadata, load, error, progress, abort, this.actualServerId, this.actualImgType, this.newLocationForm);
          this.locationService.uploadImage(fieldName, file, metadata, load, error, progress, abort)
            .subscribe(response => {
              console.log('RESPONSE: ', response);
              load(response)
              return response;
            });
        },
        load: (source, load, error, progress, abort, headers) => {
          const theRequest = new Request(source);
          // fetch(theRequest).then((response) => {
          //   response.blob().then((theBlob) => {
          //     load(theBlob);
          //   });
          // });
          this.locationService.getImage(source)
            .subscribe(response => {
              console.log(response);
              load(response);
            });
        },
        // fetch: (url, load, error, progress, abort, headers) => {
        //   console.log("fetch", url, load, error, progress, abort, headers)
        // },
        revert: (uniqueFileId, load, error) => {
          error('oh my goodness');
          load();
        }
      }
    };
    this.pondLandscapeOptions = {
      // class: 'my-filepond',
      multiple: false,
      labelIdle: 'Drop files or click here for browsing...',
      acceptedFileTypes: 'image/jpeg, image/png, image/jpg',
      allowImagePreview: true,
      imagePreviewTransparencyIndicator: 'grid',
      imagePreviewMarkupShow: false,
      maxFileSize: '5MB',
      allowImageSizeMetadata: true,
      imageValidateSizeMinHeight: 480,
      imageValidateSizeMinWidth: 480,
      imagePreviewHeight: 200,
      server: {
        process: (fieldName, file, metadata, load, error, progress, abort) => {
          // console.log(file, this)
          this.locationService.uploadImage(fieldName, file, metadata, load, error, progress, abort)
            .subscribe(response => {
              console.log('RESPONSE: ', response);
              load(response)
              return response;
            });
          //return this.imgService.uploadFile(fieldName, file, metadata, load, error, progress, abort, this.actualServerId, this.actualImgType, this.newLocationForm);
        },
        load: (source, load, error, progress, abort, headers) => {
          const theRequest = new Request(source);
          // fetch(theRequest).then((response) => {
          //   response.blob().then((theBlob) => {
          //     load(theBlob);
          //   });
          // });
          this.locationService.getImage(source)
            .subscribe(response => {
              console.log(response);
              load(response);
            });
        },
        // fetch: (url, load, error, progress, abort, headers) => {
        //   console.log("fetch", url, load, error, progress, abort, headers)
        // },
        revert: (uniqueFileId, load, error) => {
          error('oh my goodness');
          load();
        }
      }
    };
    this.pondPhotosOptions = {
      // class: 'my-filepond',
      multiple: true,
      labelIdle: 'Drop files or click here for browsing...',
      acceptedFileTypes: 'image/jpeg, image/png, image/jpg',
      allowImagePreview: true,
      imagePreviewTransparencyIndicator: 'grid',
      imagePreviewMarkupShow: false,
      maxFileSize: '5MB',
      allowImageSizeMetadata: true,
      imageValidateSizeMinHeight: 480,
      imageValidateSizeMinWidth: 480,
      imagePreviewHeight: 200,
      server: {
        process: (fieldName, file, metadata, load, error, progress, abort) => {
          // console.log(load, this)
          this.locationService.uploadImage(fieldName, file, metadata, load, error, progress, abort)
            .subscribe(response => {
              console.log('RESPONSE: ', response);
              load(response)
              return response;
            });
        },
        load: (source, load, error, progress, abort, headers) => {
          const theRequest = new Request(source);
          // fetch(theRequest).then((response) => {
          //   console.log(typeof response)
          //   response.blob().then((theBlob) => {
          //     console.log(typeof theBlob)
          //     load(theBlob);
          //   });
          // });
          this.locationService.getImage(source)
            .subscribe(response => {
              console.log(response);
              load(response);
            });
        },
        // fetch: (url, load, error, progress, abort, headers) => {
        //   console.log("fetch", url, load, error, progress, abort, headers)
        // },
        revert: (uniqueFileId, load, error) => {
          error('oh my goodness');
          load();
        }
      }
    };
    this.paymentOptionsList = [
      {id: 'AMAZON', text: 'AMAZON', url: 'AMAZON.png'},
      {id: 'AMEX', text: 'AMEX', url: 'AMEX.png'},
      {id: 'AMEXDIRECTDEBIT', text: 'AMEXDIRECTDEBIT', url: 'AMEXDIRECTDEBIT.png'},
      {id: 'BITCOIN', text: 'BITCOIN', url: 'BITCOIN.png'},
      {id: 'CASH', text: 'CASH', url: 'CASH.png'},
      {id: 'DISCOVER', text: 'DISCOVER', url: 'DISCOVER.png'},
      {id: 'EBAY', text: 'EBAY', url: 'EBAY.png'},
      {id: 'EC', text: 'EC', url: 'EC.png'},
      {id: 'GIROPAY', text: 'GIROPAY', url: 'GIROPAY.png'},
      {id: 'INVOICE', text: 'INVOICE', url: 'INVOICE.png'},
      {id: 'MAESTRO', text: 'MAESTRO', url: 'MAESTRO.png'},
      {id: 'MASTERCARD', text: 'MASTERCARD', url: 'MASTERCARD.svg'},
      {id: 'PAYPAL', text: 'PAYPAL', url: 'PAYPAL.png'},
      {id: 'SOFORT', text: 'SOFORT', url: 'SOFORT.png'},
      {id: 'VISA', text: 'VISA', url: 'VISA.png'},
      {id: 'VISAELECTRON', text: 'VISAELECTRON', url: 'VISAELECTRON.svg'},
      {id: 'WESTERNUNION', text: 'WESTERNUNION', url: 'WESTERNUNION.png'},
      {id: 'DBSPAYLAH', text: 'DBSPAYLAH', url: 'DBSPAYLAH.svg'},
      {id: 'ALIPAY', text: 'ALIPAY', url: 'ALIPAY.svg'},
      {id: 'APPLEPAY', text: 'APPLEPAY', url: 'APPLEPAY.svg'},
      {id: 'FAVEPAY', text: 'FAVEPAY', url: 'FAVEPAY.svg'},
      {id: 'GOPAY', text: 'GOPAY', url: 'GOPAY.svg'},
      {id: 'GRABPAY', text: 'GRABPAY', url: 'GRABPAY.svg'},
      {id: 'RABBITLINEPAY', text: 'RABBITLINEPAY', url: 'RABBITLINEPAY.svg'},
      {id: 'TRUEMONEY', text: 'TRUEMONEY', url: 'TRUEMONEY.svg'},
      {id: 'WECHATPAY', text: 'WECHATPAY', url: 'WECHATPAY.svg'},
      {id: 'GOOGLEPAY', text: 'GOOGLEPAY', url: 'GOOGLEPAY.svg'},
    ];
    this.hasAttributes = false;
    const objCountry = new CountryList();
    this.countryList = Object.keys(objCountry).map((key) => {
      return [key, objCountry[key]];
    });
    this.newLocationForm = this.fb.group({
      phone: ['', Validators.required],
      name: ['', Validators.required],
      street: ['', Validators.required],
      addressExtra: [''],
      autoSync: [''],
      province: [''],
      zip: ['', Validators.required],
      city: ['', Validators.required],
      country: ['', Validators.required],
      businessId: ['', Validators.required],
      categories: ['', Validators.required],
      addressDisplay: [''],
      cellphone: [''],
      fax: [''],
      website: ['', [Validators.required, Validators.pattern(this.urlReg)]],
      email: ['', [Validators.required, Validators.email]],
      openingHoursNotes: [''/*, Validators.pattern('[0-9\p{L}\(\)\[\] \?:;\/!\\,\.\-%\\&\r\n\t_\*§²`´·"\'\+¡¿@]')*/],
      openingHours: [''],
      allOpeningHours: this.fb.array([
        this.fb.group({
          openingHours: ['', Validators.required],
          from: ['08:00', Validators.required],
          to: ['17:00', Validators.required]
        })
      ]),
      allSpecialOpenHours: this.fb.array([]),
      keywords: [''],
      descriptionShort: [''/*, Validators.pattern('[0-9\p{L}\(\)\[\] \?:;\/!\\,\.\-%\\&=\r\n\t_\*§²`´·"\'\+¡¿@°€£$]')*/],
      descriptionLong: [''/*, Validators.pattern('[0-9\p{L}\(\)\[\] \?:;\/!\\,\.\-%\\&=\r\n\t_\*§²`´·"\'\+¡¿@°€£$]')*/],
      imprint: [''],
      labels: [''],
      socialProfiles: this.fb.group({
        FACEBOOK: ['', Validators.pattern(this.urlReg)],
        INSTAGRAM: ['', Validators.pattern(this.urlReg)],
        LINKEDIN: ['', Validators.pattern(this.urlReg)],
        YOUTUBE: ['', Validators.pattern(this.urlReg)],
        VINE: ['', Validators.pattern(this.urlReg)],
        VIMEO: ['', Validators.pattern(this.urlReg)],
        GOOGLEPLUS: ['', Validators.pattern(this.urlReg)],
        TWITTER: ['', Validators.pattern(this.urlReg)],
        FOURSQUARE: ['', Validators.pattern(this.urlReg)],
        XING: ['', Validators.pattern(this.urlReg)],
        PINTEREST: ['', Validators.pattern(this.urlReg)]
      }),
      attributes: this.fb.array([]),
      paymentOptions: [''],
      brands: [''],
      languages: [''],
      services: [''],
      videos: this.fb.array([]),
      photos: this.fb.group({
        url: [''],
        type: ['']
      }),
      photosFiles: this.fb.array([]),
    });
    this.route.paramMap.subscribe(params => {
      if (typeof params.get('businessId') !== 'undefined' && params.get('businessId') !== null) {
        console.log('THE BUSINESS ID: ', params.get('businessId'));
        this.currentBusiness = String(params.get('businessId'));
      }
      if (typeof params.get('id') !== 'undefined' && params.get('id') !== null) {
        this.ngxLoading = true;
        this.existingOperHours = [];
        this.hasAttributes = true;
        this.locationService.getById(params.get('id')).subscribe(
          response => {
            // console.log('LOC RESPONSE: ', response);
            this.location = response.response.location;
            if (typeof this.location !== 'undefined' && typeof this.location.id !== 'undefined') {
              this.createOrEdit = 'Edit';
              this.formControls.name.setValue(this.location.name);
              this.formControls.phone.setValue((this.location.phone !== null && this.location.phone !== '') ? this.location.phone.split(' ')[1] : '');
              // this.formControls.identifier.setValue((this.location.identifier !== null && this.location.identifier !== '') ? this.location.identifier : '');
              this.formControls.province.setValue(this.location.province);
              this.formControls.zip.setValue(this.location.zip);
              this.formControls.city.setValue(this.location.city);
              this.formControls.country.setValue(this.location.country);
              this.formControls.addressDisplay.setValue(this.location.addressDisplay);
              this.formControls.street.setValue(this.location.streetAndNumber);
              this.formControls.cellphone.setValue((this.location.cellphone !== null && this.location.cellphone !== '') ? this.location.cellphone.split(' ')[1] : '');
              this.formControls.fax.setValue((this.location.fax !== null && this.location.fax !== '') ? this.location.fax.split(' ')[1] : '');
              this.formControls.website.setValue(this.location.website);
              this.formControls.descriptionShort.setValue((this.location.descriptionShort !== null && this.location.descriptionShort !== '') ? this.location.descriptionShort : '');
              this.formControls.descriptionLong.setValue((this.location.descriptionLong !== null && this.location.descriptionLong !== '') ? this.location.descriptionLong : '');
              this.formControls.imprint.setValue((this.location.imprint !== null && this.location.imprint !== '') ? this.location.imprint : '');
              this.formControls.paymentOptions.setValue(this.location.paymentOptions);
              this.formControls.email.setValue(this.location.email);
              this.getCategoriesInit(this.location.categories);
              this.formControls.autoSync.setValue(this.location.autoSync);
              this.autoSync = this.location.autoSync;
              this.currentBusiness = String(this.location.businessId);
              this.businessInitValue =  Observable.create((obs) => {
                obs.next(this.location.businessId);
                obs.complete();
              });
              this.categInitValues =  Observable.create((obs) => {
                obs.next(this.location.categories.map(elem => String(elem)));
                obs.complete();
              });
              const openHoursOrganiced = this.getOpeningHoursFromLocation(this.location.openingHours);
              for (const eachHour in openHoursOrganiced) {
                    if (parseInt(eachHour) > -1) {
                      this.daysInitValues.push(openHoursOrganiced[eachHour].days);
                      this.addOperationHours(openHoursOrganiced[eachHour]);
                    }
              }
              this.specialDaysInitValues = this.getSpecialOpeningHoursFromLocation(this.location.specialOpeningHours);
              for (const eachHour of this.specialDaysInitValues) {
                this.addSpecOperationHours(eachHour);
              }
              const keyWordsSel = [];
              for (const keyWord of this.location.keywords) {
                keyWordsSel.push({id: keyWord, text: keyWord});
              }
              this.keyWordsInitTexts = Observable.create((obs) => {
                obs.next(keyWordsSel);
                obs.complete();
              });
              this.keyWordsInit = Observable.create((obs) => {
                obs.next(this.location.keywords);
                obs.complete();
              });

              const labelsSel = [];
              if (this.location.labels !== null) {
                for (const keyWord of this.location.labels) {
                  labelsSel.push({id: keyWord, text: keyWord});
                }
              }
              this.labelsInitTexts = Observable.create((obs) => {
                obs.next(labelsSel);
                obs.complete();
              });
              this.labelsInit = Observable.create((obs) => {
                obs.next(this.location.labels);
                obs.complete();
              });
              if (this.location.socialProfiles !== null) {
                for (const socialProf of this.location.socialProfiles) {
                  this.socialProfilesControls[socialProf.type].setValue(socialProf.url);
                }
              }
              const brandsSel = [];
              if (this.location.brands !== null) {
                for (const keyWord of this.location.brands) {
                  brandsSel.push({id: keyWord, text: keyWord});
                }
              }
              this.brandsInitTexts = Observable.create((obs) => {
                obs.next(brandsSel);
                obs.complete();
              });
              this.brandsInit = Observable.create((obs) => {
                obs.next(this.location.brands);
                obs.complete();
              });
              const servicesSel = [];
              if (this.location.serviceAreas !== null) {
                for (const keyWord of this.location.serviceAreas) {
                  servicesSel.push({id: keyWord, text: keyWord});
                }
              }
              this.servicesInitTexts = Observable.create((obs) => {
                obs.next(servicesSel);
                obs.complete();
              });
              this.servicesInit = Observable.create((obs) => {
                obs.next(this.location.serviceAreas);
                obs.complete();
              });
              this.langInit = Observable.create((obs) => {
                obs.next(this.location.languages);
                obs.complete();
              });
              if (typeof this.location.videos !== 'undefined') {
                for (const video of this.location.videos) {
                  this.addVideo(video);
                }
              }

              const attrInitUx = [];
              if (this.location.attributes !== null) {
                for (const bus of this.location.attributes) {
                  if (bus.valueMetadata !== null && bus.valueMetadata.length > 0) {
                    // tslint:disable-next-line:max-line-length
                    attrInitUx.push(String(bus.externalId) + '-*-' + bus.valueType + '-*-' + bus.valueMetadata[0].value);
                  } else {
                    attrInitUx.push(String(bus.externalId) + '-*-' + bus.valueType + '-*-null');
                  }
                  (this.newLocationForm.get('attributes') as FormArray).push(
                    this.fb.group({
                      displayName: [bus.displayName],
                      externalId: [bus.externalId],
                      valueType: [bus.valueType],
                      value: [bus.value, (bus.valueType === 'URL') ? [Validators.required, Validators.pattern(this.urlReg)] : [Validators.required]]
                    })
                  );
                }
              }
              this.attributesInit = Observable.create((obs) => {
                obs.next(attrInitUx);
                obs.complete();
              });

              const auxPhotos = [];
              let photosUrl = '';
              let photosType = '';
              if (this.location.photos !== null) {
                for (const img of this.location.photos) {
                  photosUrl = photosUrl + ((photosUrl !== '') ? ',' : '') + img.publicUrl;
                  photosType = photosType + ((photosType !== '') ? ',' : '') + img.type;
                  if (img.type === 'LOGO') {
                    this.pondFileLogo = Observable.create((obs) => {
                      obs.next([{source: img.publicUrl, options: {type: 'local'}}]);
                      obs.complete();
                    });
                  } else if (img.type === 'SQUARED_LOGO') {
                    this.pondFileSquared = Observable.create((obs) => {
                      obs.next([{source: img.publicUrl, options: {type: 'local'}}]);
                      obs.complete();
                    });
                  } else if (img.type === 'LANDSCAPE') {
                    this.pondFileLandscape = Observable.create((obs) => {
                      obs.next([{source: img.publicUrl, options: {type: 'local'}}]);
                      obs.complete();
                    });
                  } else {
                    auxPhotos.push({source: img.publicUrl, options: {type: 'local'}});
                  }
                }
              }
              this.formControls.photos.get('url').setValue(photosUrl);
              this.formControls.photos.get('type').setValue(photosType);
              this.pondFilePhotos = Observable.create((obs) => {
                obs.next(auxPhotos);
                obs.complete();
              });
              const paymentsValues = [];
              if (this.location.paymentOptions !== null) {
                for (const paymentOp of this.location.paymentOptions) {
                  const currentValue = paymentOp.toUpperCase();
                  if (currentValue === 'AMERICANEXPRESS') {
                    paymentsValues.push('AMEX');
                  } else {
                    paymentsValues.push(currentValue);
                  }
                }
              }
              this.paymentInit = Observable.create((obs) => {
                obs.next(paymentsValues);
                obs.complete();
              });
              this.locationStatus = this.location.status;
              console.log('LOCATION: ', this.location);
              this.ngxLoading = false;
            } else {
              this.ngxLoading = false;
            }
          },
          error => {
            // console.log('RESPONSE ERROR: ', error)
            this.ngxLoading = false;
          },
        );
        this.breadcrumbsService.store([
          {label: 'Dashboard' , url: '/dashboard', params: []},
          {label: 'Locations' , url: '/locations', params: []},
          {label: 'Edit Location' , url: '/edit-location/:id', params: []}
        ]);
      } else {
        this.existingOperHours = [0];
        this.breadcrumbsService.store([
          {label: 'Dashboard' , url: '/dashboard', params: []},
          {label: 'Locations' , url: '/locations', params: []},
          {label: 'Create Location' , url: '/create-location', params: []}
        ]);
      }
    });
    this.options = {
      multiple: false,
      closeOnSelect: true,
      width: '100%',
    };
    this.optionsAttributes = {
      multiple: true,
      closeOnSelect: true,
      width: '100%',
    };
    this.optionsLang = {
      multiple: true,
      closeOnSelect: true,
      width: '100%',
    };
    this.optionsPaymentOptions = {
      multiple: true,
      closeOnSelect: false,
      width: '100%',
      templateResult: (state) => {
        const $state = $(
          '<span><img width="60" style="box-shadow: 0 0 4px #908d8d;border-radius: 4px;margin-right: 15px;" src="' + this.paymentImagesUrl + state.url + '" class="img-flag select-image" /> ' + state.text + '</span>'
        );
        return $state;
      },
      templateSelection: (state) => {
        const $state = $(
          '<span><img width="40" style="border-radius: 4px;margin-right: 5px;" src="' + this.paymentImagesUrl + state.url + '" class="img-flag select-image" /> ' + state.text + '</span>'
        );
        return $state;
      }
    };
    this.optionsKeywords = {
      multiple: true,
      closeOnSelect: true,
      tags: true,
      width: '100%',
      maximumSelectionSize: 10
    };
    this.optionsBrands = {
      multiple: true,
      closeOnSelect: true,
      tags: true,
      width: '100%'
    };
    this.optionsServ = {
      multiple: true,
      closeOnSelect: true,
      tags: true,
      width: '100%'
    };
    this.optionsLabels = {
      multiple: true,
      closeOnSelect: true,
      tags: true,
      width: '100%',
      openOnEnter: false
    };
    this.currentUser = this.authService.currentUserValue;
    this.optionsCateg = {
      multiple: true,
      closeOnSelect: true,
      width: '100%',
      maximumSelectionSize: 5,
      minimumInputLength: 4,
      ajax: {
        headers: {
          Authorization : 'Bearer ' + this.currentUser.token,
          'Content-Type' : 'application/json',
        },
        url: (params) => {
          return `${environment.apiUrl}` + 'categories?language=en&max=' + 10 + '&offset=' + params.page + '&q=' + params.term;
        },
        processResults: (data, params) => {
          params.page = params.page || 1;
          // tslint:disable-next-line:max-line-length
          const response = (data.response.results.resultTotalCount > 0) ? data.response.results.results : data.response.suggestionResults.suggestions;
          const modResults = [];
          for (const i of response) {
            modResults.push({id: i.id, text: i.fullName});
          }
          return {
            results: modResults,
            pagination: {
                more: (params.page * 10) < data.count
            }
        };
        }
      }
    };
    this.getBusinesses();
    this.getAttributes();
    this.existingSpecOperHours = [];
    this.existingVideos = [];
    // this.addOperationHours();
  }

  get getCategoriesAmount() {
    return (this.formControls.categories.value !== null) ? this.formControls.categories.value.length : 0;
  }

  get getOpeningHoursNotesAmount() {
    return (this.formControls.openingHoursNotes.value !== null) ? this.formControls.openingHoursNotes.value.length : 0;
  }

  get getKeywordsAmount() {
    return (this.formControls.keywords.value !== null) ? this.formControls.keywords.value.length : 0;
  }

  get getDescriptionShortAmount() {
    return (this.formControls.descriptionShort.value !== null) ? this.formControls.descriptionShort.value.length : 0;
  }

  get getDescriptionLongAmount() {
    return (this.formControls.descriptionLong.value !== null) ? this.formControls.descriptionLong.value.length : 0;
  }

  get getImprintAmount() {
    return (this.formControls.imprint.value !== null) ? this.formControls.imprint.value.length : 0;
  }

  getBusinesses(): void {
    this.businessService.getBusiness().subscribe(
      businesses => {
        const allBusinesses = businesses.response.businesses;
        const list: Array<Select2OptionData> = [];
        for (const bus of allBusinesses) {
          list.push({id: String(bus.identifier), text: bus.name});
        }
        this.businesses =  Observable.create((obs) => {
          obs.next(list);
          obs.complete();
        });
        this.businessInitValue =  Observable.create((obs) => {
          obs.next(this.currentBusiness);
          obs.complete();
        });
      }
    );
  }

  getCategories(): void {
    this.categService.getCategories().subscribe(
      categs => {
        const allCategories = categs.response.results;
        const list: Array<Select2OptionData> = [];
        for (const bus of allCategories) {
          list.push({id: String(bus.id), text: bus.name});
        }
        this.categories =  Observable.create((obs) => {
          obs.next(list);
          obs.complete();
        });
      }
    );
  }

  getCategoriesInit(categoriesIds: any[]): void {
    this.categService.getCategoriesById(categoriesIds).subscribe(categs => {
      const allCategories = categs.response.results;
      const list: Array<Select2OptionData> = [];
      for (const bus of allCategories) {
        list.push({id: String(bus.id), text: bus.name});
      }
      this.categInitTexts =  Observable.create((obs) => {
        obs.next(list);
        obs.complete();
      });
    });
  }

  getAttributes(event = null): void {
    if (typeof event !== 'undefined' && event !== null) {
      this.attributesService.getAttributes(parseInt(event.value[0])).subscribe(attributes => {
        const allAttributes = attributes.response.attributes;
        const list: Array<Select2OptionData> = [];
        for (const bus of allAttributes) {
          if (bus.valueMetadata !==  null && bus.valueMetadata.length > 0) {
            list.push({id: String(bus.externalId) + '-*-' + bus.valueType + '-*-' + bus.valueMetadata[0].value, text: bus.displayName});
          } else {
            list.push({id: String(bus.externalId) + '-*-' + bus.valueType + '-*-null', text: bus.displayName});
          }
        }
        this.hasAttributes = (list.length > 0) ? true : false;
        this.attributesList =  Observable.create((obs) => {
          obs.next(list);
          obs.complete();
        });
      });
    }
  }

  get formControls() {
    return this.newLocationForm.controls;
  }

  onSubmit(event) {
    // TODO: Use EventEmitter with form value
    this.loading = true;
    this.ngxLoading = true;
    // console.warn(this.newLocationForm.value, event);
    if (this.createOrEdit === 'Create') {
      this.locationService.createLocation(this.newLocationForm.value as Location)
        .subscribe(response => {
          console.log('RESPONSE: ', response);
          this.loading = false;
          // @ts-ignore
          if (response.status === 'SUCCESS') {
            this.alertService.alert('success', 'The location was created succesfully.');
          }
          this.ngxLoading = false;
          this.ngOnInit();
          this.newLocationForm.reset();
        }, (error) => { this.ngxLoading = false; this.loading = false;});
    } else {
      this.locationService.updateLocation(this.newLocationForm.value as Location, this.location.identifier)
        .subscribe(response => {
          console.log('RESPONSE: ', response);
          this.loading = false;
          // @ts-ignore
          if (response.status === 'SUCCESS') {
            this.alertService.alert('success', 'The location was updated succesfully.');
          }
          this.ngxLoading = false;
        }, (error) => { this.ngxLoading = false; this.loading = false;});
    }
  }

  addOperationHours(values: any = {}) {
    (this.newLocationForm.get('allOpeningHours') as FormArray).push(
      this.fb.group({
        openingHours: [(typeof values.days !== 'undefined') ? values.days : '', Validators.required],
        from: [(typeof values.from !== 'undefined') ? values.from : '08:00', Validators.required],
        to: [(typeof values.to !== 'undefined') ? values.to : '17:00', Validators.required]
      })
    );
    this.existingOperHours.push(this.existingOperHours.length);
  }

  deleteOperationHours(pos: number) {
    console.log(pos, this.existingOperHours);
    // this.existingOperHours[pos].destroy();
    // delete this.existingOperHours[pos];
    (this.newLocationForm.get('allOpeningHours') as FormArray).removeAt(pos);
    this.existingOperHours.splice(pos, 1);
  }

  addSpecOperationHours(values: any = {}) {
    (this.newLocationForm.get('allSpecialOpenHours') as FormArray).push(
      this.fb.group({
        dp: [(typeof values.date !== 'undefined') ? values.date : '', Validators.required],
        openClose: [(typeof values.closed !== 'undefined' && !values.closed) ? 'open' : 'closed'],
        from: [(typeof values.from !== 'undefined') ? values.from : '08:00'],
        to: [(typeof values.to !== 'undefined') ? values.to : '17:00']
      })
    );
    this.existingSpecOperHours.push(this.existingSpecOperHours.length);
  }

  deleteSpecOperationHours(pos: number) {
    // console.log(pos, this.existingOperHours);
    // this.existingOperHours[pos].destroy();
    // delete this.existingOperHours[pos];
    (this.newLocationForm.get('allSpecialOpenHours') as FormArray).removeAt(pos);
    this.existingSpecOperHours.splice(pos, 1);
  }

  operationHoursEvent(event) {
    console.log('EVENTING');
    if (event.type === 'add') {
      this.addOperationHours();
    } else {
      this.deleteOperationHours(event.position);
    }
  }

  specOperationHoursEvent(event) {
    console.log('EVENTING');
    if (event.type === 'add') {
      this.addSpecOperationHours();
    } else {
      this.deleteSpecOperationHours(event.position);
    }
  }

  pondHandleAddFile(event: any) {
     if (event.error === null) {
       // console.log('A file was added', event, event.file.id, event.pond.className.get());
       this.actualServerId = event.file.id;
       this.actualImgType = event.pond.className.get();
     }
  }

  pondHandleBeforeAddFile(event) {
    // console.log('A file before was added', event, event.file.getMetadata('size'));
    const sizes = event.file.getMetadata('size');
    if (typeof sizes !== 'undefined') {
      if (sizes.width !== sizes.height) {
        event.file.abortLoad();
      }
    }
  }

  pondHandleFileRevert(event) {
    // this.imgService.deleteFile(event.file, event.pond.className.get(), this.newLocationForm);
    // @ts-ignore
    const urlsValue = this.newLocationForm.get('photos').controls.url.value.split(',');
    // @ts-ignore
    const typesValue = this.newLocationForm.get('photos').controls.type.value.split(',');
    this.locationService.deleteImage(event.file).subscribe(response => {
      console.log('RESPONSE: ', response);
      // @ts-ignore
      const indexDel = urlsValue.indexOf(response.url);
      urlsValue.splice(indexDel, 1);
      typesValue.splice(indexDel, 1);
      // @ts-ignore
      this.newLocationForm.get('photos').controls.url.setValue(urlsValue.join(','));
      // @ts-ignore
      this.newLocationForm.get('photos').controls.type.setValue(typesValue.join(','));
    });
  }

  addVideo(values: any = {}) {
    const urlReg = '(https?://)([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?';
    (this.newLocationForm.get('videos') as FormArray).push(
      // this.fb.control('', Validators.pattern(urlReg))
      this.fb.group({
        videoUrl: [(typeof values.url !== 'undefined') ? values.url : '', Validators.pattern(urlReg)],
        videoDescription: [(typeof values.description !== 'undefined') ? values.description : '']
      }),
    );
    this.existingVideos.push(this.existingVideos.length);
  }

  videosEvent(event) {
    if (event.type === 'delete') {
      this.existingVideos.splice(event.position, 1);
    }
  }

  get socialProfilesControls() {
    // console.log((this.newLocationForm.get('social') as FormArray).controls[this.position]);
    return (this.newLocationForm.get('socialProfiles') as FormArray).controls;
  }

  getOpeningHoursFromLocation(openHours: any[]) {
    const openPerTimes = [];
    for (const openHour of openHours) {
      const times = Object.values(openHour);
      const currentTime = times[1] + '-' + times[2];
      if (typeof openPerTimes[currentTime] === 'undefined' || openPerTimes[currentTime] === null) {
        openPerTimes[currentTime] = {days: [], from: '', to: ''};
      }
      openPerTimes[currentTime].days.push(String(times[0]));
      openPerTimes[currentTime].from = times[1];
      openPerTimes[currentTime].to = times[2];
    }
    return Object.values(openPerTimes);
  }

  getSpecialOpeningHoursFromLocation(openSpecHours: any[]) {
    const openPerTimes = [];
    for (const openHour of openSpecHours) {
      const acutalSpec = Object.values(openHour);
      const auxObj = {
        date: openHour.date,
        closed: (typeof openHour.closed !== 'undefined') ? openHour.closed : false,
        from: (typeof openHour.closed === 'undefined') ? acutalSpec[1] : '',
        to: (typeof openHour.closed === 'undefined') ? acutalSpec[2] : ''
      };
      openPerTimes.push(auxObj);
    }
    return openPerTimes;
  }

  reactivateLocation(type) {
    this.ngxLoading = true;
    const locationToReactivate = {
      status: type,
      city: this.location.city,
      country: this.location.country,
      name: this.location.name,
      street: this.location.street,
      zip: this.location.zip,
      onlyClose: true
    };

    this.locationService.deactivateAndActivateLocation(locationToReactivate, this.location.identifier)
      .subscribe(response => {
        console.log('RESPONSE: ', response);
        this.loading = false;
        // @ts-ignore
        if (response.status === 'SUCCESS') {
          this.locationStatus = type;
          this.location.status = type;
          this.alertService.alert('success', 'The location was updated succesfully.');
        }
        this.ngxLoading = false;
      }, error => { this.ngxLoading = false; });
  }

  autoSyncEvent(event: boolean) {
    this.ngxLoading = true;
    const locationToReactivate = {
      autoSync: event,
      city: this.location.city,
      country: this.location.country,
      name: this.location.name,
      street: this.location.street,
      zip: this.location.zip
    };
    this.locationService.deactivateAndActivateLocation(locationToReactivate as Location, this.location.identifier)
      .subscribe(response => {
        console.log('RESPONSE: ', response);
        this.loading = false;
        this.autoSync = this.location.autoSync = event;
        this.formControls.autoSync.setValue(event);
        // @ts-ignore
        if (response.status === 'SUCCESS') {
          this.alertService.alert('success', 'Auto-sync changed succesfully.');
        }
        this.ngxLoading = false;
      }, error => { this.ngxLoading = false; });
  }

  attributesChanged(event: {}) {
    // @ts-ignore
    if ((event.data.length > 0 && event.value.length > 0) || (event.data.length === 0 && event.value.length === 0)) {
      const oldAttributes = this.formControls.attributes.value;
      (this.formControls.attributes as FormArray).clear();
      // @ts-ignore
      for (const attr of event.data) {
        const newAttr = new FormGroup({});
        const data = attr.id.split('-*-');
        let val: any = '';
        if (data[1] !== 'BOOL') {
          const found = (oldAttributes as Array<any>).findIndex(value => value.externalId === data[0]);
          val = (found !== -1) ? oldAttributes[found].value : '';
        } else {
          val = true;
        }
        newAttr.addControl('displayName', new FormControl(attr.text, [Validators.required]));
        newAttr.addControl('valueType', new FormControl(data[1], [Validators.required]));
        newAttr.addControl('externalId', new FormControl(data[0], [Validators.required]));
        // tslint:disable-next-line:max-line-length
        newAttr.addControl('value', new FormControl(val, (data[1] === 'URL') ? [Validators.required, Validators.pattern(this.urlReg)] : [Validators.required]));
        (this.formControls.attributes as FormArray).push(newAttr);
      }
    }
  }
}
