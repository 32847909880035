import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {Observable} from 'rxjs';
import {ProductPlansResponse} from '../../_models/datatable-response';
import {catchError, tap} from 'rxjs/operators';
import {Errors} from '../../_helpers/errors';

@Injectable({
  providedIn: 'root'
})
export class ProductPlanService {

  private prodPlanUrl = 'productplans';
  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      // privateKey: environment.privateKey
    })
  };
  constructor(private http: HttpClient) { }

  getProductPlans(): Observable<ProductPlansResponse> {
    return this.http.get<ProductPlansResponse>(
      `${environment.apiUrl}` + this.prodPlanUrl
    ).pipe(
      tap(_ => console.log('fetched Product Plans')),
      catchError(Errors.handleError<ProductPlansResponse>('getProductPlans'))
    );
  }
}
