export const languages = [
  {
    "id": "(Eastern) Punjabi",
    "text": "(Eastern) Punjabi"
  },
  {
    "id": "Afrikaans",
    "text": "Afrikaans"
  },
  {
    "id": "Albanian",
    "text": "Albanian"
  },
  {
    "id": "Amharic",
    "text": "Amharic"
  },
  {
    "id": "Arabic",
    "text": "Arabic"
  },
  {
    "id": "Armenian",
    "text": "Armenian"
  },
  {
    "id": "Aymara",
    "text": "Aymara"
  },
  {
    "id": "Azerbaijani",
    "text": "Azerbaijani"
  },
  {
    "id": "Belarusian",
    "text": "Belarusian"
  },
  {
    "id": "Bengali",
    "text": "Bengali"
  },
  {
    "id": "Bislama",
    "text": "Bislama"
  },
  {
    "id": "Bosnian",
    "text": "Bosnian"
  },
  {
    "id": "Bulgarian",
    "text": "Bulgarian"
  },
  {
    "id": "Burmese",
    "text": "Burmese"
  },
  {
    "id": "Catalan",
    "text": "Catalan"
  },
  {
    "id": "Chamorro",
    "text": "Chamorro"
  },
  {
    "id": "Chichewa",
    "text": "Chichewa"
  },
  {
    "id": "Chinese",
    "text": "Chinese"
  },
  {
    "id": "Croatian",
    "text": "Croatian"
  },
  {
    "id": "Czech",
    "text": "Czech"
  },
  {
    "id": "Danish",
    "text": "Danish"
  },
  {
    "id": "Divehi",
    "text": "Divehi"
  },
  {
    "id": "Dutch",
    "text": "Dutch"
  },
  {
    "id": "Dzongkha",
    "text": "Dzongkha"
  },
  {
    "id": "English",
    "text": "English"
  },
  {
    "id": "Estonian",
    "text": "Estonian"
  },
  {
    "id": "Faroese",
    "text": "Faroese"
  },
  {
    "id": "Fijian",
    "text": "Fijian"
  },
  {
    "id": "Finnish",
    "text": "Finnish"
  },
  {
    "id": "French",
    "text": "French"
  },
  {
    "id": "Fula",
    "text": "Fula"
  },
  {
    "id": "Georgian",
    "text": "Georgian"
  },
  {
    "id": "German",
    "text": "German"
  },
  {
    "id": "Greek (modern)",
    "text": "Greek (modern)"
  },
  {
    "id": "Guaraní",
    "text": "Guaraní"
  },
  {
    "id": "Haitian",
    "text": "Haitian"
  },
  {
    "id": "Hebrew (modern)",
    "text": "Hebrew (modern)"
  },
  {
    "id": "Hindi",
    "text": "Hindi"
  },
  {
    "id": "Hungarian",
    "text": "Hungarian"
  },
  {
    "id": "Icelandic",
    "text": "Icelandic"
  },
  {
    "id": "Indonesian",
    "text": "Indonesian"
  },
  {
    "id": "Irish",
    "text": "Irish"
  },
  {
    "id": "Italian",
    "text": "Italian"
  },
  {
    "id": "Japanese",
    "text": "Japanese"
  },
  {
    "id": "Kalaallisut",
    "text": "Kalaallisut"
  },
  {
    "id": "Kazakh",
    "text": "Kazakh"
  },
  {
    "id": "Khmer",
    "text": "Khmer"
  },
  {
    "id": "Kinyarwanda",
    "text": "Kinyarwanda"
  },
  {
    "id": "Kirundi",
    "text": "Kirundi"
  },
  {
    "id": "Kongo",
    "text": "Kongo"
  },
  {
    "id": "Korean",
    "text": "Korean"
  },
  {
    "id": "Kurdish",
    "text": "Kurdish"
  },
  {
    "id": "Kyrgyz",
    "text": "Kyrgyz"
  },
  {
    "id": "Lao",
    "text": "Lao"
  },
  {
    "id": "Latin",
    "text": "Latin"
  },
  {
    "id": "Latvian",
    "text": "Latvian"
  },
  {
    "id": "Lingala",
    "text": "Lingala"
  },
  {
    "id": "Lithuanian",
    "text": "Lithuanian"
  },
  {
    "id": "Luba-Katanga",
    "text": "Luba-Katanga"
  },
  {
    "id": "Luxembourgish",
    "text": "Luxembourgish"
  },
  {
    "id": "Macedonian",
    "text": "Macedonian"
  },
  {
    "id": "Malagasy",
    "text": "Malagasy"
  },
  {
    "id": "Malay",
    "text": "Malay"
  },
  {
    "id": "Malaysian",
    "text": "Malaysian"
  },
  {
    "id": "Maltese",
    "text": "Maltese"
  },
  {
    "id": "Manx",
    "text": "Manx"
  },
  {
    "id": "Marshallese",
    "text": "Marshallese"
  },
  {
    "id": "Mongolian",
    "text": "Mongolian"
  },
  {
    "id": "Māori",
    "text": "Māori"
  },
  {
    "id": "Nauruan",
    "text": "Nauruan"
  },
  {
    "id": "Nepali",
    "text": "Nepali"
  },
  {
    "id": "Northern Ndebele",
    "text": "Northern Ndebele"
  },
  {
    "id": "Norwegian",
    "text": "Norwegian"
  },
  {
    "id": "Norwegian Bokmål",
    "text": "Norwegian Bokmål"
  },
  {
    "id": "Norwegian Nynorsk",
    "text": "Norwegian Nynorsk"
  },
  {
    "id": "Pashto",
    "text": "Pashto"
  },
  {
    "id": "Persian (Farsi)",
    "text": "Persian (Farsi)"
  },
  {
    "id": "Polish",
    "text": "Polish"
  },
  {
    "id": "Portuguese",
    "text": "Portuguese"
  },
  {
    "id": "Quechua",
    "text": "Quechua"
  },
  {
    "id": "Romanian",
    "text": "Romanian"
  },
  {
    "id": "Russian",
    "text": "Russian"
  },
  {
    "id": "Samoan",
    "text": "Samoan"
  },
  {
    "id": "Sango",
    "text": "Sango"
  },
  {
    "id": "Serbian",
    "text": "Serbian"
  },
  {
    "id": "Shona",
    "text": "Shona"
  },
  {
    "id": "Sinhalese",
    "text": "Sinhalese"
  },
  {
    "id": "Slovak",
    "text": "Slovak"
  },
  {
    "id": "Slovene",
    "text": "Slovene"
  },
  {
    "id": "Somali",
    "text": "Somali"
  },
  {
    "id": "Southern Ndebele",
    "text": "Southern Ndebele"
  },
  {
    "id": "Southern Sotho",
    "text": "Southern Sotho"
  },
  {
    "id": "Spanish",
    "text": "Spanish"
  },
  {
    "id": "Swahili",
    "text": "Swahili"
  },
  {
    "id": "Swati",
    "text": "Swati"
  },
  {
    "id": "Swedish",
    "text": "Swedish"
  },
  {
    "id": "Tajik",
    "text": "Tajik"
  },
  {
    "id": "Tamil",
    "text": "Tamil"
  },
  {
    "id": "Thai",
    "text": "Thai"
  },
  {
    "id": "Tigrinya",
    "text": "Tigrinya"
  },
  {
    "id": "Tonga (Tonga Islands)",
    "text": "Tonga (Tonga Islands)"
  },
  {
    "id": "Tsonga",
    "text": "Tsonga"
  },
  {
    "id": "Tswana",
    "text": "Tswana"
  },
  {
    "id": "Turkish",
    "text": "Turkish"
  },
  {
    "id": "Turkmen",
    "text": "Turkmen"
  },
  {
    "id": "Ukrainian",
    "text": "Ukrainian"
  },
  {
    "id": "Urdu",
    "text": "Urdu"
  },
  {
    "id": "Uzbek",
    "text": "Uzbek"
  },
  {
    "id": "Venda",
    "text": "Venda"
  },
  {
    "id": "Vietnamese",
    "text": "Vietnamese"
  },
  {
    "id": "Xhosa",
    "text": "Xhosa"
  },
  {
    "id": "Zulu",
    "text": "Zulu"
  }
];
